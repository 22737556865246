import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { useProps } from 'libraries/components';
import { expandedRelativeProfile, updateRelativeInfo } from 'models/relatives';

const stateProps = {
    expandedRelativeProfile,
    updateRelativeInfo,
};

const withForm = (id = '') => (Component) => (props) => {
    const {
        expandedRelativeProfile,
        updateRelativeInfo,
    } = useProps(stateProps);

    const formMethods = useForm({
        defaultValues: cloneDeep(expandedRelativeProfile),
    });

    useEffect(() => {
        formMethods.reset(cloneDeep(expandedRelativeProfile));
    }, [expandedRelativeProfile]);

    return (
        <FormProvider {...formMethods}>
            <form
                id={id}
                onSubmit={formMethods.handleSubmit(updateRelativeInfo)}
                noValidate
            >
                <Component {...props} />
            </form>
        </FormProvider>
    );
};

export default withForm;
