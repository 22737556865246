import { Typography } from '@mui/material';

import { routesList } from 'routes';
import { withRouteFromPath } from 'utils';
import { FormattedMessage } from 'react-intl';
import { Breadcrumbs } from './components';

const Header = ({ route }) => (
    <>
        <Typography variant="h4">
            <FormattedMessage
                defaultMessage={route.title}
                id={route.title}
            />
        </Typography>
        <Breadcrumbs />
    </>
);

export default withRouteFromPath(routesList)(Header);
