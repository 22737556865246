import { Switch as MaterialSwitch } from '@mui/material';

import { withStyles } from './styles';

const Switch = ({
    checked,
    onChange,
    classes,
}) => (
    <MaterialSwitch
        color="primary"
        classes={{ root: classes.root }}
        checked={checked}
        onChange={onChange}
    />

);

export default withStyles(Switch);
