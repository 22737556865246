import { compose } from 'redux';
import { DataGrid } from '@mui/x-data-grid';
import withStyles from '@mui/styles/withStyles';
import { generatePageSelectorPayload } from 'utils';

import { LoadingBar, ToolBar } from './components';
import styles from './styles';

const Table = ({
    classes,
    rows,
    rowCount,
    columns,
    pagination,
    loading,
    totalCaregivers,
    loadingCaregivers,
    setItemsPerPage,
    setCurrentPage,
    ...props
}) => (
    <div className={classes.root}>
        <DataGrid
            rows={rows}
            rowCount={rowCount}
            columns={columns}
            pageSizeOptions={[9, 25, 50, 100]}
            loading={loading}
            onPageSizeChange={({ pageSize }) => setItemsPerPage(pageSize)}
            onPaginationModelChange={({ page, pageSize }) => setCurrentPage(
                generatePageSelectorPayload(pagination.currentPage, page, pageSize),
            )}
            components={{
                Toolbar: ToolBar,
                LoadingOverlay: LoadingBar,
            }}
            paginationMode="server"
            columnBuffer={2}
            checkboxSelection
            disableRowSelectionOnClick
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: pagination.itemsPerPage,
                        page: 0,
                    },
                },
                sorting: {
                    sortModel: [{ field: 'registerDate', sort: 'desc' }],
                },
            }}
            {...props}
        />
    </div>
);

export default compose(
    withStyles(styles),
)(Table);
