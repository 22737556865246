import { NEW_CASE, STATUSES } from 'constants/statuses';
import { differenceInDays, formatDistance } from 'date-fns';
import { range } from 'lodash';
import { Tooltip } from '@mui/material';
import { FiberManualRecord, FiberSmartRecord } from '@mui/icons-material';

const DaysInStatus = ({ status, statusChangedOn }) => {
    const daysSinceStatusChangedFormatted = formatDistance(
        new Date(statusChangedOn),
        new Date(),
        { addSuffix: true },
    );
    const daysSinceStatusChanged = differenceInDays(new Date(), new Date(statusChangedOn)) || 1;
    const rangeOfDays = range(daysSinceStatusChanged > 4 ? 5 : daysSinceStatusChanged);

    const statusInfo = STATUSES[status] ?? STATUSES[NEW_CASE];

    return statusInfo.closed ? null : (
        <Tooltip title={`${statusInfo?.label} since ${daysSinceStatusChangedFormatted}`}>
            <span style={{ display: 'flex' }}>
                {rangeOfDays.map((el) => (el === 4 ? (
                    <FiberSmartRecord
                        key={el}
                        sx={{ color: 'secondary.main', width: '12px', height: '12px' }}
                    />
                ) : (
                    <FiberManualRecord
                        key={el}
                        sx={{ color: 'secondary.main', width: '12px', height: '12px' }}
                    />
                )))}
            </span>
        </Tooltip>
    );
};

export default DaysInStatus;
