import { css } from '@emotion/react';

const styles = {
    title: css({
        paddingLeft: '24px',
        marginBottom: '16px',
    }),
};

export default styles;
