import clsx from 'clsx';

import withStyles from '@mui/styles/withStyles';
import styles from './styles';

const StepIcon = ({
    classes,
    active,
    completed,
    icon,
}) => (
    <div
        className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
        })}
    >
        {icon}
    </div>
);

export default withStyles(styles)(StepIcon);
