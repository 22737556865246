const styles = (theme) => ({
    section: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    progress: {
        marginTop: theme.spacing(2),
        height: theme.spacing(1),
        width: '100%',
        borderRadius: theme.spacing(2),
    },
});

export default styles;
