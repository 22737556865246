import { useState } from 'react';
import {
    Box,
    Button,
    Stepper as MuiStepper,
    Step,
    StepLabel,
    Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

const Stepper = ({ steps, hasErrors }) => {
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const isStepOptional = (step) => steps?.[step]?.isOptional;

    const isStepSkipped = (step) => skipped.has(step);

    const handleNext = (event) => {
        event.preventDefault();
        let newSkipped = skipped;
        if (isStepSkipped(activeStepIndex)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStepIndex);
        }

        setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);

    const handleSkip = () => {
        if (!isStepOptional(activeStepIndex)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStepIndex);
            return newSkipped;
        });
    };

    const handleReset = () => setActiveStepIndex(0);

    const RenderActiveStep = (props) => {
        const Step = steps?.[activeStepIndex]?.component;
        return <Step {...props} />;
    };

    return (
        <Box sx={{ width: '100%', mb: 2 }}>
            <MuiStepper
                activeStep={activeStepIndex}
                sx={{ mb: 2 }}
            >
                {steps.map(({ label, isOptional }, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isOptional) {
                        labelProps.optional = (
                            <Typography variant="caption">
                                <FormattedMessage defaultMessage="Optional" id="optional" />
                            </Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </MuiStepper>
            {activeStepIndex === steps.length ? (
                <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>
                            <FormattedMessage defaultMessage="Reset" id="reset" />
                        </Button>
                    </Box>
                </>
            ) : (
                <>
                    <RenderActiveStep />

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStepIndex === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            <FormattedMessage defaultMessage="Back" id="back" />
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStepIndex) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                <FormattedMessage defaultMessage="Skip" id="skip" />
                            </Button>
                        )}

                        <Button
                            disabled={hasErrors}
                            {...(activeStepIndex === steps.length - 1
                                ? { type: 'submit' }
                                : { onClick: handleNext }
                            )}
                        >
                            {activeStepIndex === steps.length - 1
                                ? <FormattedMessage defaultMessage="Finish" id="finish" />
                                : <FormattedMessage defaultMessage="Next" id="next" />}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Stepper;
