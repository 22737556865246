const styles = (theme) => ({
    section: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    text: {
        lineHeight: '50px',
        textAlign: 'center',
    },
    spacer: {
        height: theme.spacing(2),
    },
});

export default styles;
