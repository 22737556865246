import muiWithStyles from '@mui/styles/withStyles';

const withStyles = muiWithStyles((theme) => ({
    paper: {
        width: '100%',
        borderRadius: '4px 4px 0 0',
    },
    content: {
        padding: theme.spacing(2),
    },
    spacer: {
        flexGrow: 1,
    },
    list: {
        minHeight: '16em',
        maxHeight: '20em',
    },
}));

export default withStyles;
