import { AddressPaper } from 'components';
import { useFormContext, Controller } from 'react-hook-form';

const Address = () => {
    const { control, getValues, setValue } = useFormContext();
    const [lat, lon] = getValues(['_geoloc.lat', '_geoloc.lon']);

    const onUpdateCoordinates = ({ lat, lng }) => {
        const setOptions = {
            shouldValidate: true,
            shouldDirty: true,
        };
        setValue('_geoloc.lat', lat, setOptions);
        setValue('_geoloc.lon', lng, setOptions);
    };

    return (
        <Controller
            control={control}
            name="address"
            render={({ field: { onChange, value } }) => (
                <AddressPaper
                    onChange={onChange}
                    value={value}
                    initCoordinates={{ lat, lon }}
                    onUpdateCoordinates={onUpdateCoordinates}
                />
            )}
        />
    );
};

export default Address;
