import { compose } from 'redux';
import {
    Button,
    CircularProgress,
    DialogActions,
    TextField,
    Autocomplete,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { withProps } from 'libraries/components';
import { createFeatureFlag, fetchState } from 'models/featureFlags';
import { withStyles } from './styles';

const CreateFeatureFlag = ({
    handleClose,
    createFeatureFlag,
    fetchState,
    classes,
}) => {
    const {
        control,
        handleSubmit,
        formState: {
            isValid,
        },
    } = useForm({
        mode: 'onChange',
    });

    return (
        <form className={classes.container} onSubmit={handleSubmit(createFeatureFlag)} noValidate>
            <Controller
                render={({ field: { onChange, ...props } }) => (
                    <Autocomplete
                        size="small"
                        options={['Project 1', 'Project 2']}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Choose a Project"
                                variant="outlined"
                            />
                        )}
                        onChange={(e, data) => onChange(data)}
                        fullWidth
                        {...props}
                    />
                )}
                name="project"
                control={control}
            />
            <Controller
                name="label"
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Feature"
                        size="small"
                        variant="outlined"
                        fullWidth
                        error={error?.message}
                        helperText={error?.message}
                    />
                )}
            />
            <Controller
                name="description"
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Short Description"
                        size="small"
                        variant="outlined"
                        fullWidth
                        error={error?.message}
                        helperText={error?.message}
                    />
                )}
            />
            <DialogActions>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!isValid}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    {fetchState.isCreating
                        ? <CircularProgress color="inherit" size={20} />
                        : 'Create'}
                </Button>
            </DialogActions>
        </form>
    );
};

export default compose(
    withStyles,
    withProps({
        createFeatureFlag,
        fetchState,
    }),
)(CreateFeatureFlag);
