import {
    useState,
    useRef,
} from 'react';
import { TextField, Grid } from '@mui/material';
import { css } from '@mui/material/styles';
import {
    GoogleMap,
    LoadScriptNext,
    Marker,
} from '@react-google-maps/api';

import env from 'env';
import SectionPaper from 'components/sectionPaper';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomTextField } from './components';
import styles from './styles';

const libraries = ['places'];

const AddressPaper = ({
    value,
    onChange,
    initCoordinates,
    onUpdateCoordinates = () => {},
}) => {
    const ref = useRef(null);

    const [coordinates, setCoordinates] = useState({
        lat: initCoordinates.lat,
        lng: initCoordinates.lon,
    });

    const onLoad = (newRef) => {
        ref.current = newRef;
    };

    const onPlacesChanged = () => {
        const [result] = ref.current.getPlaces();

        onChange(result.formatted_address);
        const newCoordinates = {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
        };
        setCoordinates(newCoordinates);
        onUpdateCoordinates(newCoordinates);
    };

    const intl = useIntl();

    return (
        <SectionPaper title={<FormattedMessage defaultMessage="Address" id="address" />}>
            <LoadScriptNext
                googleMapsApiKey={env.googleMapsApiKey}
                libraries={libraries}
            >
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <TextField
                            value={value}
                            onChange={onChange}
                            multiline
                            fullWidth
                            placeholder={intl.formatMessage({ id: 'address', defaultMessage: 'Address' })}
                            sx={css(styles.textField)}
                            InputProps={{
                                inputComponent: CustomTextField,
                                inputProps: {
                                    onLoad,
                                    onPlacesChanged,
                                },
                            }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <div
                            style={styles.responsiveMapContainer}
                        >
                            <GoogleMap
                                mapContainerStyle={styles.innerMapContainer}
                                center={coordinates}
                                zoom={16}
                            >
                                <Marker position={coordinates} />
                            </GoogleMap>
                        </div>
                    </Grid>
                </Grid>
            </LoadScriptNext>
        </SectionPaper>
    );
};

export default AddressPaper;
