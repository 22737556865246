import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { withProps } from 'libraries/components';
import {
    expandedCaregiverProfile,
    expandedCaregiverLoading,
    expandCaregiverProfile,
} from 'models/caregivers';

import { Layout, InfoPane, ProfileSection } from './components';

const CaregiverProfile = ({
    expandedCaregiverProfile,
    expandedCaregiverLoading,
    expandCaregiverProfile,
}) => {
    const { id } = useParams();

    useEffect(() => {
        expandedCaregiverProfile?.id !== id && expandCaregiverProfile(id);

        return () => expandCaregiverProfile(null);
    }, [id]);

    return (expandedCaregiverLoading
        ? (
            'Loading...'
        ) : (
            <Layout
                info={InfoPane}
                profile={ProfileSection}
            />
        ));
};

export default withProps({
    expandedCaregiverProfile,
    expandedCaregiverLoading,
    expandCaregiverProfile,
})(CaregiverProfile);
