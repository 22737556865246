import { STATUSES } from 'constants/statuses';
import { RELATIVES } from 'constants/permissions';

import { withProps } from 'libraries/components';
import { board, loadOperations, moveCard } from 'models/operations';

import { DaysInStatus, HasPayed } from 'components';

import { useEffect } from 'react';
import { compose } from 'redux';
import Board from '@asseinfo/react-kanban';
import '@asseinfo/react-kanban/dist/styles.css';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { users } from 'models/settings';
import {
    Avatar, Box, IconButton, Paper, Tooltip, Typography,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

import styles from './styles';
import './override.css';

const Card = ({
    classes,
    card,
    dragging,
    users,
}) => {
    const user = users?.find((user) => user.id === card?.caseRepresentativeId);
    '';
    return (
        <Paper elevation={dragging ? 6 : 0} key={card?.id} className={classes.card}>
            <Box display="flex" flexDirection="row">
                <Tooltip title={card?.type === RELATIVES ? 'Relative' : 'Caregiver'}>
                    <Box
                        sx={{
                            backgroundColor: card?.type === RELATIVES ? 'primary.main' : 'warning.dark',
                            width: 6,
                            height: 90,
                            borderRadius: 12,
                            marginRight: 1,
                        }}
                    />
                </Tooltip>
                <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={1}
                    >
                        <Typography>
                            {`${card?.lastName} 
                            ${card?.firstName?.charAt(0)}.`}
                        </Typography>
                        <Tooltip title={user?.displayName ?? 'Unassigned'}>
                            <Avatar
                                alt={user?.displayName}
                                src={user?.photoURL}
                                className={classes.avatar}
                                imgProps={{ referrerpolicy: 'no-referrer' }}
                            />
                        </Tooltip>
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        <HasPayed plan={card?.plan} />
                        <DaysInStatus
                            statusChangedOn={card?.statusChangedOn}
                            status={card?.caseStatus}
                        />
                        <Box flexGrow={1} />
                        <Tooltip title={<FormattedMessage defaultMessage="Open Profile" id="open_profile" />}>
                            <IconButton
                                component={Link}
                                to={`/${card?.type === RELATIVES ? 'relatives' : 'caregivers'}/${card.id}`}
                                size="small"
                            >
                                <PermContactCalendarIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};

const Operations = ({
    classes,
    loadOperations,
    board,
    users,
    moveCard,
}) => {
    useEffect(() => {
        loadOperations();
    }, []);

    const boardArray = Object.values(board.columns);
    const boardObject = { columns: boardArray };

    const renderCard = (card, { removeCard, dragging }) => (
        <Card
            classes={classes}
            card={card}
            removeCard={removeCard}
            dragging={dragging}
            users={users}
        />
    );

    const onCardDragEnd = (card, from, to) => {
        const newStatus = Object.values(STATUSES)[to.toColumnId - 1]?.value;

        moveCard({
            type: card.type,
            id: card.id,
            newStatus,
            from,
            to,
        });
    };

    return (
        <Box width="100%" overflow="scroll">
            <Board
                renderCard={renderCard}
                renderColumnHeader={({ title }) => (
                    <Typography variant="h5">
                        <FormattedMessage
                            id={title}
                            defaultMessage={title}
                        />
                    </Typography>
                )}
                onCardDragEnd={onCardDragEnd}
                disableColumnDrag
            >
                {boardObject}
            </Board>
        </Box>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        loadOperations,
        board,
        users,
        moveCard,
    }),
)(Operations);
