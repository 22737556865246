const styles = {
    paper: {
        padding: '20px',
        paddingTop: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        width: '100%',
        paddingTop: '20px',
        '&:hover': {
            cursor: 'move',
        },
    },
    title: {
        textAlign: 'left',
    },
};

export default styles;
