const styles = (theme) => ({
    link: {
        color: theme.palette.text.primary,
    },
    green: {
        color: theme.palette.success.main,
    },
    red: {
        color: theme.palette.error.main,
    },
    rating: {
        margin: '0 auto',
    },
});

export default styles;
