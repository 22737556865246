import { Grid, Typography } from '@mui/material';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PublicIcon from '@mui/icons-material/Public';
import PetsIcon from '@mui/icons-material/Pets';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useFormContext } from 'react-hook-form';

import { FormattedMessage } from 'react-intl/lib';
import { FormToggleBox } from './components';
import styles from './styles';

const GeneralInfo = () => {
    const { control } = useFormContext();

    return (
        <>
            <Typography sx={styles.title} variant="h4">
                <FormattedMessage
                    defaultMessage="General Information"
                    id="general_information"
                />
            </Typography>
            <Grid container spacing={3}>
                <Grid item>
                    <FormToggleBox
                        label={(
                            <FormattedMessage
                                defaultMessage="Has kids"
                                id="has_kids"
                            />
                        )}
                        name="hasKids"
                        control={control}
                        icon={BabyChangingStationIcon}
                    />
                </Grid>
                <Grid item>
                    <FormToggleBox
                        label={(
                            <FormattedMessage
                                defaultMessage="Smoker"
                                id="smoker"
                            />
                        )}
                        name="isSmoker"
                        control={control}
                        positiveFromValue={(value) => !value}
                        negativeFromValue={(value) => value}
                        icon={SmokingRoomsIcon}
                    />
                </Grid>
                <Grid item>
                    <FormToggleBox
                        label={(
                            <FormattedMessage
                                id="has_drivers_licence"
                                defaultMessage="Has Drivers licence"
                            />
                        )}
                        name="hasDriversLicense"
                        control={control}
                        positiveFromValue={(value) => value}
                        negativeFromValue={(value) => !value}
                        icon={RecentActorsIcon}
                    />
                </Grid>
                <Grid item>
                    <FormToggleBox
                        label={(
                            <FormattedMessage
                                defaultMessage="Has a car"
                                id="has_a_car"
                            />
                        )}
                        name="ownsCar"
                        control={control}
                        icon={DirectionsCarIcon}
                    />
                </Grid>
                <Grid item>
                    <FormToggleBox
                        label={(
                            <FormattedMessage
                                defaultMessage="Works with pets"
                                id="works_with_pets"
                            />
                        )}
                        name="worksWithPets"
                        positiveFromValue={(value) => value}
                        negativeFromValue={(value) => !value}
                        control={control}
                        icon={PetsIcon}
                    />
                </Grid>
                <Grid item>
                    <FormToggleBox
                        label={(
                            <FormattedMessage
                                defaultMessage="Internet User"
                                id="internet_user"
                            />
                        )}
                        name="internetUser"
                        positiveFromValue={(value) => value}
                        negativeFromValue={(value) => !value}
                        control={control}
                        icon={PublicIcon}
                    />
                </Grid>
                <Grid item>
                    <FormToggleBox
                        label={(
                            <FormattedMessage
                                defaultMessage="Identification"
                                id="identification"
                            />
                        )}
                        name="identification"
                        positiveFromValue={(value) => value}
                        negativeFromValue={(value) => !value}
                        control={control}
                        icon={AssignmentIndIcon}
                    />
                </Grid>
                <Grid item>
                    <FormToggleBox
                        label={(
                            <FormattedMessage
                                defaultMessage="Health certificate"
                                id="health_certificate"
                            />
                        )}
                        name="healthCertificate"
                        positiveFromValue={(value) => value}
                        negativeFromValue={(value) => !value}
                        control={control}
                        icon={LocalHospitalIcon}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default GeneralInfo;
