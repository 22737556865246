import { compose } from 'redux';
import { Link } from 'react-router-dom';
import {
    Typography,
    Breadcrumbs as MuiBreadcrumbs,
    Link as MuiLink,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { routes, routesList } from 'routes';
import { withRouteFromPath } from 'utils';

import { FormattedMessage } from 'react-intl';
import styles from './styles';

const Breadcrumbs = ({ classes, route: { icon: Icon, ...route } }) => (
    <MuiBreadcrumbs aria-label="breadcrumb">
        {route?.parentRoutes?.map((route) => routes[route]).map((route) => (
            <MuiLink
                key={route.path}
                className={classes.link}
                color="inherit"
                to={route.path}
                component={Link}
            >
                <route.icon className={classes.icon} />
                <FormattedMessage
                    defaultMessage={route.title}
                    id={route.title}
                />
            </MuiLink>
        ))}
        {route.path !== '/' && (
            <Typography color="textPrimary" className={classes.link}>
                <Icon className={classes.icon} />
                <FormattedMessage
                    defaultMessage={route.title}
                    id={route.title}
                />
            </Typography>
        )}
    </MuiBreadcrumbs>
);

export default compose(
    withStyles(styles),
    withRouteFromPath(routesList),
)(Breadcrumbs);
