import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import Rating from '@mui/material/Rating';

import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import RateReviewIcon from '@mui/icons-material/RateReview';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ApartmentIcon from '@mui/icons-material/Apartment';

import styles from './styles';

const CaregiverInfo = ({ classes, caregiver }) => (
    <List>
        <ListItem>
            <Rating className={classes.rating} value={caregiver?.rating} />
        </ListItem>
        <ListItem className={classes.link}>
            <ListItemIcon>
                <RateReviewIcon />
            </ListItemIcon>
            <ListItemText primary="Reviewed" />
            {caregiver?.reviewed
                ? <DoneIcon className={classes.green} />
                : <CloseIcon className={classes.red} />}
        </ListItem>
        <ListItem className={classes.link}>
            <ListItemIcon>
                <ApartmentIcon />
            </ListItemIcon>
            <ListItemText primary="Employed" />
            {caregiver?.employed
                ? <DoneIcon className={classes.green} />
                : <CloseIcon className={classes.red} />}
        </ListItem>
        <Divider />
        <ListItem
            className={classes.link}
            component="a"
            href={`tel:${caregiver?.phoneNumber}`}
            button
        >
            <ListItemIcon>
                <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary={caregiver?.phoneNumber} secondary="Phone" />
        </ListItem>
        <ListItem
            className={classes.link}
            component="a"
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(caregiver?.address)}`}
            target="_blank"
            button
        >
            <ListItemIcon>
                <RoomIcon />
            </ListItemIcon>
            <ListItemText primary={caregiver?.address} secondary="Address" />
        </ListItem>
        <Divider />
    </List>
);

export default withStyles(styles)(CaregiverInfo);
