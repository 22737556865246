import { compose } from 'redux';

import {
    Paper,
    Divider, TextField,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import { expandedRelativeProfile } from 'models/relatives';
import { Avatar } from '@grandmama/ui-avatar';

import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { InfoList, RelativeActions } from './components';
import styles from './styles';

const PersonInfoPane = ({
    classes,
    expandedRelativeProfile,
}) => (
    <Paper className={classes.paper}>
        <div className={classes.stickyContainer}>
            <Avatar
                maxRows={4}
                name={`${expandedRelativeProfile?.firstName} ${expandedRelativeProfile?.lastName}`}
                photo={expandedRelativeProfile?.photo}
                avatarProps={{
                    className: classes.avatar,
                }}
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <Controller
                    fullwidth
                    name="firstName"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={(
                                <FormattedMessage
                                    defaultMessage="First Name"
                                    id="first_name"
                                />
                            )}
                            variant="standard"
                            multiline
                            maxRows={4}
                        />
                    )}
                />
                <Controller
                    fullwidth
                    name="lastName"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={(
                                <FormattedMessage
                                    defaultMessage="Last Name"
                                    id="last_name"
                                />
                            )}
                            variant="standard"
                            multiline
                            maxRows={4}
                        />
                    )}
                />
            </div>
            <InfoList relative={expandedRelativeProfile} />
            <Divider />
            <RelativeActions relative={expandedRelativeProfile} />
        </div>
    </Paper>
);

export default compose(
    withProps({
        expandedRelativeProfile,
    }),
    withStyles(styles),
)(PersonInfoPane);
