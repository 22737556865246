import { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { Add, ExpandMore, Phone } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormattedMessage } from 'react-intl';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

const CaregiversList = ({ value = [], onChange }) => {
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [dateValue, setDateValue] = useState(new Date());
    const [notesValue, setNotesValue] = useState('');
    const [phoneNumberValue, setPhoneNumberValue] = useState('');
    const [endDateValue, setEndDateValue] = useState(new Date());

    const getNew = () => ({
        firstName: firstNameValue,
        lastName: lastNameValue,
        dateCollaborationStarted: dateValue,
        phoneNumber: phoneNumberValue,
        notes: notesValue,
    });

    const onAdd = () => {
        onChange([...value, getNew()]);
        setFirstNameValue('');
        setLastNameValue('');
        setDateValue(dateValue);
        setEndDateValue(new Date());
        setNotesValue('');
        setPhoneNumberValue('');
    };

    const onRemove = (index) => {
        onChange(value.filter((_, i) => i !== index));
    };

    const onEndCollaboration = (index) => {
        const item = value[index];
        const head = value.slice(0, index);
        const tail = value.slice(index + 1, value.length);

        const newArray = [...head, { ...item, dateCollaborationEnded: endDateValue }, ...tail];

        onChange(newArray);
    };
    const onUpdateStartDateValue = (index) => (val) => {
        setDateValue(val);
        const item = value[index];
        const head = value.slice(0, index);
        const tail = value.slice(index + 1, value.length);

        const newArray = [...head, { ...item, dateCollaborationStarted: val }, ...tail];

        onChange(newArray);
        setDateValue(new Date());
    };
    const onUpdateEndDateValue = (index) => (val) => {
        setEndDateValue(val);
        const item = value[index];
        const head = value.slice(0, index);
        const tail = value.slice(index + 1, value.length);

        const newArray = [...head, { ...item, dateCollaborationEnded: val }, ...tail];

        onChange(newArray);
    };

    const onUpdateNotes = (index) => (val) => {
        setNotesValue(val);
        const item = value[index];
        const head = value.slice(0, index);
        const tail = value.slice(index + 1, value.length);

        const newArray = [...head, { ...item, notes: val }, ...tail];

        onChange(newArray);
        setNotesValue('');
    };

    return (
        <>
            <Box>
                {value?.map((employedCaregiver, index) => {
                    const dateCollaborationStarted = new Date(employedCaregiver
                        ?.dateCollaborationStarted
                        ?.seconds
                        ? employedCaregiver?.dateCollaborationStarted
                            ?.seconds * 1000
                        : employedCaregiver?.dateCollaborationStarted);
                    const dateCollaborationEnded = new Date(
                        employedCaregiver
                            ?.dateCollaborationEnded
                            ?.seconds
                            ? employedCaregiver
                                ?.dateCollaborationEnded
                                ?.seconds * 1000
                            : employedCaregiver
                                ?.dateCollaborationEnded,
                    );
                    return (
                        <Accordion
                            key={employedCaregiver?.id ?? employedCaregiver?.lastName}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                            >
                                <Grid container spacing={2} style={{ userSelect: 'text' }}>
                                    <Grid item md={4}>
                                        <Typography>
                                            {`${employedCaregiver?.lastName} ${employedCaregiver?.firstName} `}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography>
                                            <Phone fontSize="small" />
                                            {`: ${employedCaregiver?.phoneNumber}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            {dateCollaborationStarted.toLocaleDateString()}
                                            {employedCaregiver?.dateCollaborationEnded ? (
                                                ` - ${dateCollaborationEnded.toLocaleDateString()}`
                                            ) : (
                                                <>
                                                    {' - '}
                                                    {' '}
                                                    <FormattedMessage id="present" defaultMessage="Present" />
                                                </>
                                            )}

                                        </Typography>
                                    </Grid>
                                    { employedCaregiver.notes
                                        && (
                                            <Grid item md={1}>
                                                <TextSnippetIcon fontSize="small" />
                                            </Grid>
                                        )}
                                </Grid>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <TextField
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="Notes"
                                                    id="notes"
                                                />
                                            )}
                                            value={employedCaregiver.notes}
                                            onChange={(e) => onUpdateNotes(index)(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </Grid>
                                    {employedCaregiver?.dateSuggested && (
                                        <Grid item md={12}>
                                            <Typography>
                                                <FormattedMessage
                                                    id="recommended_on"
                                                    defaultMessage="Was recommended on"
                                                />
                                                {` ${new Date(employedCaregiver?.dateSuggested?.seconds
                                                    ? employedCaregiver
                                                        ?.dateSuggested?.seconds * 1000
                                                    : employedCaregiver
                                                        ?.dateSuggested).toLocaleDateString()}`}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {employedCaregiver?.dateCollaborationStarted && (
                                        <Grid item md={6}>
                                            <DesktopDatePicker
                                                label={(
                                                    <FormattedMessage
                                                        id="collaboration_started_on"
                                                        defaultMessage="Collaboration started on"
                                                    />
                                                )}
                                                value={dateCollaborationStarted}
                                                onChange={onUpdateStartDateValue(index)}
                                                renderInput={(params) => (
                                                    <TextField {...params} fullWidth />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                    {employedCaregiver?.dateCollaborationEnded ? (
                                        <Grid item md={6}>
                                            <DesktopDatePicker
                                                label={(
                                                    <FormattedMessage
                                                        id="collaboration_ended_on"
                                                        defaultMessage="Collaboration ended on"
                                                    />
                                                )}
                                                value={dateCollaborationEnded}
                                                onChange={onUpdateEndDateValue(index)}
                                                renderInput={(params) => (
                                                    <TextField {...params} fullWidth />
                                                )}
                                            />
                                        </Grid>
                                    ) : (
                                        <div style={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                            gap: 10,
                                            maxHeight: 40,
                                        }}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="warning"
                                                onClick={() => onEndCollaboration(index)}
                                            >
                                                <FormattedMessage
                                                    id="end_collaboration"
                                                    defaultMessage="End Collaboration"
                                                />
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => onRemove(index)}
                                            >
                                                <FormattedMessage
                                                    id="remove"
                                                    defaultMessage="Remove"
                                                />
                                            </Button>

                                        </div>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={1} alignItems="center">
                <Grid item md={6}>
                    <TextField
                        label={(
                            <FormattedMessage
                                defaultMessage="First Name"
                                id="first_name"
                            />
                        )}
                        value={firstNameValue}
                        onChange={(e) => setFirstNameValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        label={(
                            <FormattedMessage
                                defaultMessage="Last Name"
                                id="last_name"
                            />
                        )}
                        value={lastNameValue}
                        onChange={(e) => setLastNameValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        label={(
                            <FormattedMessage
                                defaultMessage="Phone Number"
                                id="phone_number"
                            />
                        )}
                        value={phoneNumberValue}
                        onChange={(e) => setPhoneNumberValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item md={6}>
                    <DesktopDatePicker
                        label={(
                            <FormattedMessage
                                defaultMessage="Starts on"
                                id="starts_on"
                            />
                        )}
                        value={dateValue}
                        onChange={setDateValue}
                        renderInput={(params) => (
                            <TextField fullWidth {...params} />
                        )}
                    />
                </Grid>
                <Grid item md={10}>
                    <TextField
                        multiline
                        rows={4}
                        label={(
                            <FormattedMessage
                                defaultMessage="Notes"
                                id="notes"
                            />
                        )}
                        value={notesValue}
                        onChange={(e) => setNotesValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item md={2} justifyContent="center" alignContent="center" display="flex">
                    <IconButton
                        onClick={onAdd}
                        disabled={!firstNameValue || !lastNameValue || !phoneNumberValue}
                    >
                        <Add />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};

export default CaregiversList;
