import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { effectAction } from './actions';

const effectEpic = (action$) => action$.pipe(
    ofType(effectAction.type),
    mergeMap(
        ({
            payload: { effectCreator, effectResponseAction, additionalResponseActions = [] },
        }) => from(effectCreator()).pipe(
            mergeMap(({ success, payload }) => {
                if (success) {
                    const successActions = [];

                    if (effectResponseAction && effectResponseAction.succeeded) {
                        successActions.push(effectResponseAction.succeeded(payload));
                    }

                    additionalResponseActions.map((action) => successActions.push(action(payload)));

                    return successActions;
                }
                return effectResponseAction.failed();
            }),
            catchError((error) => (effectResponseAction && effectResponseAction.failed
                ? [effectResponseAction.failed(error)]
                : [])),
        ),
    ),
);

export default effectEpic;
