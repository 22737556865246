import { actionCreator, effectActionCreator } from 'libraries/system';

export const changeCaregiversView = actionCreator('CHANGE_CAREGIVERS_VIEW');
export const setTotalCaregivers = actionCreator('SET_TOTAL_CAREGIVERS');
export const setPointerDocs = actionCreator('SET_POINTER_DOCS');
export const setItemsPerPage = actionCreator('SET_ITEMS_PER_PAGE');
export const pinCaregiver = actionCreator('PIN_CAREGIVER');
export const unpinCaregiver = actionCreator('UNPIN_CAREGIVER');
export const unpinAllCaregivers = actionCreator('UNPIN_ALL_CAREGIVERS');

export const loadCaregivers = effectActionCreator('LOAD_CAREGIVERS');
export const setCurrentPage = effectActionCreator('SET_CURRENT_PAGE');
export const expandCaregiverProfile = effectActionCreator('EXPAND_CAREGIVER_PROFILE');
export const updateCaregiverInfo = effectActionCreator('UPDATE_CAREGIVER_INFO');
export const sendRegistrationSMSToCaregiver = effectActionCreator('SEND_REGISTRATION_SMS_TO_CAREGIVER');
export const newCaregiver = effectActionCreator('NEW_CAREGIVER');
