import { Observable } from 'rxjs';

const paginationCollectionStream = (db) => ({
    collectionPath,
    orderBy,
    startOperator,
    start,
    limitOperator,
    numOfDocs,
}) => new Observable((observer) => db
    .collection(collectionPath)
    .orderBy(orderBy)[startOperator](start)[limitOperator](numOfDocs)
    .onSnapshot(
        (querySnapshot) => observer.next(querySnapshot.docs),
        (error) => observer.error(error),
        () => observer.complete(),
    ));

export default paginationCollectionStream;
