import { USER_MANAGEMENT } from 'constants/permissions';
import { MANAGE_USERS } from 'constants/dialog';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import {
    Typography,
    SwipeableDrawer,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
    ExitToApp as ExitToAppIcon,
    Settings as SettingsIcon,
    AccountBox as AccountBoxIcon,
} from '@mui/icons-material';

import env from 'env';
import { withProps } from 'libraries/components';
import {
    userDrawerOpen,
    closeUserDrawer,
    openUserDrawer,
    openDialog,
} from 'models/ui';
import { userInfo, signOut } from 'models/user';

import { FormattedMessage } from 'react-intl';
import styles from './styles';

const UserDrawer = ({
    classes,
    expanded,
    userInfo,
    userDrawerOpen,
    closeUserDrawer,
    openUserDrawer,
    openDialog,
    signOut,
}) => (
    <>
        <SwipeableDrawer
            anchor="right"
            open={userDrawerOpen}
            onClose={() => closeUserDrawer()}
            onOpen={() => openUserDrawer()}
        >
            <div className={expanded ? classes.listExpanded : classes.list}>
                <Avatar
                    alt={userInfo?.displayName}
                    className={classes.avatar}
                    src={userInfo?.photoURL}
                    imgProps={{
                        referrerpolicy: 'no-referrer',
                    }}
                >
                    {userInfo?.displayName?.charAt(0)}
                    {userInfo?.displayName?.split(' ')?.[1]?.charAt(0)}
                </Avatar>
                <Typography variant="h6" className={classes.name}>
                    {userInfo?.displayName}
                </Typography>
                <Typography
                    variant="subtitle1"
                    className={classes.name}
                >
                    {userInfo?.role ?? 'Guest'}
                </Typography>
                <Divider />
                <List>
                    {userInfo?.permissions?.includes(USER_MANAGEMENT) && (
                        <ListItem
                            button
                            onClick={
                                () => openDialog({
                                    title: 'Manage Users',
                                    type: MANAGE_USERS,
                                })
                            }
                        >
                            <ListItemIcon className={classes.itemIcon}>
                                <AccountBoxIcon />
                            </ListItemIcon>
                            <ListItemText primary={(
                                <FormattedMessage
                                    defaultMessage="MANAGE USERS"
                                    id="manage_users"
                                />
                            )}
                            />
                        </ListItem>
                    )}
                    <ListItem button component={Link} to="/settings">
                        <ListItemIcon className={classes.itemIcon}>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={(
                            <FormattedMessage
                                defaultMessage="SETTINGS"
                                id="settings"
                            />
                        )}
                        />
                    </ListItem>
                </List>
                <>
                    <Divider />
                    <List>
                        <ListItem button onClick={signOut}>
                            <ListItemIcon className={classes.itemIcon}>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary={(
                                <FormattedMessage
                                    defaultMessage="SIGN OUT"
                                    id="sign_out"
                                />
                            )}
                            />
                        </ListItem>
                    </List>
                </>
            </div>
            <Typography variant="subtitle2" className={classes.buildNum}>
                Build #
                { env.circleCIBuildNum }
            </Typography>
        </SwipeableDrawer>
    </>
);

export default compose(
    withStyles(styles, { name: 'UserDrawer' }),
    withProps({
        userDrawerOpen,
        closeUserDrawer,
        openUserDrawer,
        openDialog,
        userInfo,
        signOut,
    }),
)(UserDrawer);
