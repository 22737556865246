import { Paper } from '@mui/material';
import { withStyles } from './styles';

const Row = ({ children, style, classes }) => (
    <Paper
        square
        style={style}
        className={classes.container}
    >
        {children.map(((element) => (
            <div
                key={`row-${Math.random()}`}
                className={classes.child}
            >
                {element}
            </div>
        )))}
    </Paper>
);

export default withStyles(Row);
