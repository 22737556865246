import { compose } from 'redux';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import {
    IconButton,
    Tooltip,
} from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import {
    Bookmark as BookmarkIcon,
} from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import {
    expandRelativeProfile,
    pinnedRelatives,
    pinRelative,
    unpinRelative,
} from 'models/relatives';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

const CaregiverActions = ({
    classes,
    relative,
    expandRelativeProfile,
    pinnedRelatives,
    pinRelative,
    unpinRelative,
}) => {
    const pinned = pinnedRelatives.includes(relative);

    return (
        <div className={classes.root}>
            <Tooltip title={<FormattedMessage defaultMessage="Bookmark Profile" id="bookmark_profile" />}>
                <IconButton
                    className={clsx({
                        [classes.selectedButton]: pinned,
                    })}
                    onClick={() => (
                        pinned ? unpinRelative(relative?.id) : pinRelative(relative)
                    )}
                    size="large"
                >
                    <BookmarkIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={<FormattedMessage defaultMessage="Open Profile" id="open_profile" />}>
                <IconButton
                    component={Link}
                    to={`/relatives/${relative.id}`}
                    onClick={() => expandRelativeProfile(relative)}
                    size="large"
                >
                    <PermContactCalendarIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

const RelativeActionsWithProps = compose(
    withStyles(styles),
    withProps({
        expandRelativeProfile: expandRelativeProfile.succeeded,
        pinnedRelatives,
        pinRelative,
        unpinRelative,
    }),
)(CaregiverActions);

const actionsRenderer = ({ row }) => <RelativeActionsWithProps relative={row} />;

export default actionsRenderer;
