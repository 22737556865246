import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { useProps } from 'libraries/components';
import { expandedCaregiverProfile, updateCaregiverInfo } from 'models/caregivers';

const stateProps = {
    expandedCaregiverProfile,
    updateCaregiverInfo,
};

const withForm = (id = '') => (Component) => (props) => {
    const {
        expandedCaregiverProfile,
        updateCaregiverInfo,
    } = useProps(stateProps);

    const formMethods = useForm({
        defaultValues: expandedCaregiverProfile,
    });

    useEffect(() => formMethods.reset(expandedCaregiverProfile), [expandedCaregiverProfile]);

    return (
        <FormProvider {...formMethods}>
            <form
                id={id}
                onSubmit={formMethods.handleSubmit(updateCaregiverInfo)}
                noValidate
            >
                <Component {...props} />
            </form>
        </FormProvider>
    );
};

export default withForm;
