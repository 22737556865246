import { CAREGIVERS, RELATIVES } from 'constants/permissions';
import { combineEpics, ofType } from 'redux-observable';
import {
    filter,
    mergeMap,
    map,
    catchError,
} from 'rxjs/operators';
import { forkJoin, from } from 'rxjs';

import { showSearchResults } from 'models/ui';
import { getUserDetails } from 'models/user';
import { sendNotification } from 'models/notifications';
import { createQuery, runQuery } from 'services/algolia';
import { db, firebase } from 'services/firebaseDB';
import { db as relativesDb } from 'services/relatives';
import { db as caregiversDb } from 'services/caregivers';
import env from 'env';

import {
    searchInputChange,
    setSearchResult,
    loadRecent,
    addToRecent,
} from './actions';

const getRecentActivity = (collection, id, limit = 6) => db
    .collection(env.userActivityCollection)
    .doc(id)
    .collection(collection)
    .orderBy('visitedOn', 'desc')
    .limit(limit)
    .get();

const getRelative = (id) => relativesDb
    .collection(env.relativesCollection)
    .doc(id)
    .get()
    .then((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));

const getCaregiver = (id) => caregiversDb
    .collection(env.caregiversCollection)
    .doc(id)
    .get()
    .then((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));

const onLoadEpic = (actions$) => actions$.pipe(
    ofType(getUserDetails.succeeded.type),
    mergeMap(() => [
        loadRecent(),
    ]),
);

const searchEpic = (actions$, state$) => actions$.pipe(
    ofType(searchInputChange.type),
    filter(({ payload }) => payload.length > 3),
    map(({ payload }) => {
        const filters = state$
            .value
            ?.app
            ?.searchFilters
            ?.reduce((filterString, filter) => (
                filterString.length > 0
                    ? `${filterString} AND ${filter}=1`
                    : `${filter}=1`),
            '');
        return [payload, filters];
    }),
    map(([payload, filters]) => (
        createQuery(
            payload,
            { filters },
            state$?.value?.user?.info?.permissions ?? [],
        ))),
    mergeMap((query) => runQuery(query)),
    map(({ results }) => results.reduce((
        structResults,
        {
            index,
            hits,
            page,
            hitsPerPage,
            nbHits,
        },
    ) => ({
        ...structResults,
        [index.split('_')[1]]: {
            index,
            hits,
            page,
            hitsPerPage,
            nbHits,
        },
        length: structResults.length + nbHits,
    }), {
        length: 0,
    })),
    mergeMap((results) => [setSearchResult.succeeded(results), showSearchResults()]),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        setSearchResult.failed(error),
    ])),
);

const addToRecentEpic = (actions$, state$) => actions$.pipe(
    ofType(addToRecent.type),
    mergeMap(({ payload }) => db
        .collection(env.userActivityCollection)
        .doc(state$.value.user.info.uid)
        .collection(
            payload.type === 'relative'
                ? 'recent_relatives'
                : 'recent_caregivers',
        )
        .add({
            userId: payload.id,
            visitedOn: firebase.firestore.Timestamp.fromDate(new Date()),
        })),
    map((resp) => addToRecent.succeeded(resp)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        addToRecent.failed(),
    ])),
);

const loadRecentEpic = (actions$, state$) => actions$.pipe(
    ofType(loadRecent.type, addToRecent.succeeded.type),
    map(() => [
        state$.value.user.info.permissions.includes(RELATIVES),
        state$.value.user.info.permissions.includes(CAREGIVERS),
    ]),
    mergeMap(([hasRelativesAccess, hasCaregiversAccess]) => forkJoin([
        ...(hasRelativesAccess
            ? [getRecentActivity('recent_relatives', state$.value.user.info.uid)]
            : [new Promise((resolve) => resolve())]),
        ...(hasCaregiversAccess
            ? [getRecentActivity('recent_caregivers', state$.value.user.info.uid)]
            : [new Promise((resolve) => resolve())]),
    ])),
    map(([relatives, caregivers]) => [
        relatives?.docs?.map((doc) => doc.data()) ?? [],
        caregivers?.docs?.map((doc) => doc.data()) ?? [],
    ]),
    mergeMap(([relatives, caregivers]) => forkJoin([
        Promise.all(relatives.map(({ userId }) => getRelative(userId))),
        Promise.all(caregivers.map(({ userId }) => getCaregiver(userId))),
    ])),
    map(([relatives, caregivers]) => [
        [...new Map(relatives.map((relative) => [relative.id, relative])).values()],
        [...new Map(caregivers.map((caregiver) => [caregiver.id, caregiver])).values()],
    ]),
    map(([relatives, caregivers]) => loadRecent.succeeded({ relatives, caregivers })),
);

export default combineEpics(
    onLoadEpic,
    searchEpic,
    loadRecentEpic,
    addToRecentEpic,
);
