import { useState } from 'react';

import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

const Notifications = () => {
    const [state, setState] = useState({
        caregiver: true,
        relatives: false,
        cases: true,
    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">
                <FormattedMessage
                    defaultMessage="Notify me on"
                    id="notify_me_on"
                />
            </FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch checked={state.caregiver} onChange={handleChange} name="caregiver" />
                    }
                    label={(
                        <FormattedMessage
                            defaultMessage="New Caregivers"
                            id="new_caregivers"
                        />
                    )}
                />
                <FormControlLabel
                    control={
                        <Switch checked={state.relatives} onChange={handleChange} name="relatives" />
                    }
                    label={(
                        <FormattedMessage
                            defaultMessage="New Relatives"
                            id="new_relatives"
                        />
                    )}
                />
                <FormControlLabel
                    control={
                        <Switch checked={state.cases} onChange={handleChange} name="cases" />
                    }
                    label={(
                        <FormattedMessage
                            defaultMessage="Case Updates"
                            id="case_updates"
                        />
                    )}
                />
            </FormGroup>
        </FormControl>
    );
};

export default Notifications;
