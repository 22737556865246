import { useForm, FormProvider } from 'react-hook-form';

import { useProps } from 'libraries/components';
import { newCaregiver, sendRegistrationSMSToCaregiver } from 'models/caregivers';
import { range, isEmpty } from 'lodash';
import { emailValidator } from 'utils';

const stateProps = {
    newCaregiver,
    sendRegistrationSMSToCaregiver,
};

const initialValues = {
    message: 'Hello,\n'
        + '\n'
        + 'Please download our app to start the registration process.\n'
        + 'https://caregiver.grandmama.gr/download\n'
        + '\n'
        + 'We are very excited to have you aboard!\n'
        + 'Your friends at Grandmama.',
    password: String.fromCodePoint(...range(10).map(() => Math.floor(Math.random() * 57) + 0x41)),
};

const validate = (values) => {
    const errors = {};

    if (!values.email && !values.phoneNumber) {
        errors.email = 'Required';
        errors.phoneNumber = 'Required';
    }
    if (!values.password) {
        errors.password = 'Required';
    }
    if (!values.message) {
        errors.message = 'Required';
    }
    if (!values.phoneNumber && values.email && !emailValidator(values.email)) {
        errors.email = 'Email badly formatted.';
    }
    if (!values.email && values.phoneNumber && values.phoneNumber.length < 9) {
        errors.phoneNumber = 'Not enough digits.';
    }
    return errors;
};

const withForm = (Component) => (props) => {
    const { newCaregiver, sendRegistrationSMSToCaregiver } = useProps(stateProps);

    const formMethods = useForm({
        defaultValues: initialValues,
    });

    const handleSubmit = (values) => {
        const errors = validate(values);
        if (!isEmpty(errors)) {
            Object
                .entries(errors)
                .forEach(([field, message]) => formMethods
                    .setError(field, { type: 'manual', message }));
            return;
        }

        if (values.email && values.password) {
            newCaregiver(values);
            return;
        }

        if (values.phoneNumber && values.message) {
            sendRegistrationSMSToCaregiver({
                phoneNumber: values.phoneNumber,
                message: values.message,
            });
        }
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)} noValidate>
                <Component {...props} />
            </form>
        </FormProvider>
    );
};

export default withForm;
