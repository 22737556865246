import { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    IconButton,
    List,
    TextField,
    Typography,
} from '@mui/material';
import { Add, ExpandMore, Phone } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import withStyles from '@mui/styles/withStyles';

import { Controller, useFormContext } from 'react-hook-form';

import SectionPaper from 'components/sectionPaper';

import { FormattedMessage } from 'react-intl/lib';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import styles from './styles';

const CaregiversList = ({ value, onChange, onStartCollaboration }) => {
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [dateValue, setDateValue] = useState(new Date());
    const [notesValue, setNotesValue] = useState('');
    const [phoneNumberValue, setPhoneNumberValue] = useState('');

    const getNew = () => ({
        firstName: firstNameValue,
        lastName: lastNameValue,
        dateSuggested: dateValue,
        phoneNumber: phoneNumberValue,
        notes: notesValue,
    });

    const onAdd = () => {
        onChange([...value, getNew()]);
        setFirstNameValue('');
        setLastNameValue('');
        setDateValue(new Date());
        setNotesValue('');
        setPhoneNumberValue('');
    };

    const onRemove = (index) => onChange(
        value.slice(0, index).concat(value.slice(index + 1, value.length)),
    );
    const onUpdateNotes = (index) => (val) => {
        setNotesValue(val);
        const item = value[index];
        const head = value.slice(0, index);
        const tail = value.slice(index + 1, value.length);

        const newArray = [...head, { ...item, notes: val }, ...tail];

        onChange(newArray);
        setNotesValue('');
    };

    return (
        <>
            <List>
                {value?.map((recommendedCaregiver, index) => {
                    const dateCollaborationStarted = new Date(recommendedCaregiver
                        ?.dateSuggested
                        ?.seconds
                        ? recommendedCaregiver
                            ?.dateSuggested
                            ?.seconds * 1000
                        : recommendedCaregiver?.dateSuggested)
                        .toLocaleDateString();
                    const dateCollaborationEnded = new Date(
                        recommendedCaregiver?.dateCollaborationEnded
                            ?.seconds
                            ? recommendedCaregiver
                                ?.dateCollaborationEnded
                                ?.seconds * 1000
                            : recommendedCaregiver
                                ?.dateCollaborationEnded,
                    ).toLocaleDateString();
                    return (
                        <Accordion
                            key={recommendedCaregiver?.id ?? recommendedCaregiver?.lastName}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                            >
                                <Grid container spacing={2} style={{ userSelect: 'text' }}>
                                    <Grid item md={4}>
                                        <Typography>
                                            {`${recommendedCaregiver?.lastName} ${recommendedCaregiver?.firstName} `}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography>
                                            <Phone fontSize="small" />
                                            {`: ${recommendedCaregiver?.phoneNumber}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            {dateCollaborationStarted}
                                            {recommendedCaregiver?.dateCollaborationEnded ? (
                                                ` - ${dateCollaborationEnded}`
                                            ) : ' - '}
                                            {' '}
                                            <FormattedMessage id="present" defaultMessage="Present" />
                                        </Typography>
                                    </Grid>
                                    { recommendedCaregiver.notes
                                        && (
                                            <Grid item md={1}>
                                                <TextSnippetIcon fontSize="small" />
                                            </Grid>
                                        )}

                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{
                                    flexGrow: 1, display: 'flex', justifyContent: 'flex-end', gap: 10, alignItems: 'flex-start',
                                }}
                                >
                                    <TextField
                                        label={(
                                            <FormattedMessage
                                                defaultMessage="Notes"
                                                id="notes"
                                            />
                                        )}
                                        value={recommendedCaregiver.notes}
                                        onChange={(e) => onUpdateNotes(index)(e.target.value)}
                                        variant="outlined"
                                        multiline
                                        fullWidth
                                    />
                                    <Button
                                        variant="outlined"
                                        color="success"
                                        onClick={() => onStartCollaboration(recommendedCaregiver)}
                                    >
                                        <FormattedMessage defaultMessage="Start Collaboration" id="start_collection" />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => onRemove(index)}
                                    >
                                        <FormattedMessage defaultMessage="Remove" id="remove" />
                                    </Button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </List>
            <Grid container spacing={1} alignItems="center">
                <Grid item md={6}>
                    <TextField
                        label={(
                            <FormattedMessage
                                defaultMessage="First Name"
                                id="first_name"
                            />
                        )}
                        value={firstNameValue}
                        onChange={(e) => setFirstNameValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        label={(
                            <FormattedMessage
                                defaultMessage="Last Name"
                                id="last_name"
                            />
                        )}
                        value={lastNameValue}
                        onChange={(e) => setLastNameValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        label={(
                            <FormattedMessage
                                defaultMessage="Phone Number"
                                id="phone_number"
                            />
                        )}
                        value={phoneNumberValue}
                        onChange={(e) => setPhoneNumberValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item md={6}>
                    <DesktopDatePicker
                        label={(
                            <FormattedMessage
                                defaultMessage="Recommended on"
                                id="recommended_on"
                            />
                        )}
                        value={dateValue}
                        onChange={setDateValue}
                        renderInput={(params) => (
                            <TextField fullWidth {...params} />
                        )}
                    />
                </Grid>
                <Grid item md={10}>
                    <TextField
                        multiline
                        rows={4}
                        label={(
                            <FormattedMessage
                                defaultMessage="Notes"
                                id="notes"
                            />
                        )}
                        value={notesValue}
                        onChange={(e) => setNotesValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item md={2} justifyContent="center" alignContent="center" display="flex">
                    <IconButton
                        onClick={onAdd}
                        disabled={!firstNameValue || !lastNameValue || !phoneNumberValue}
                    >
                        <Add />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};

const RecommendedCaregiversPane = ({ classes }) => {
    const { control, watch, setValue } = useFormContext();

    const employedCaregivers = watch('employedCaregivers') ?? [];

    const onStartCollaboration = (caregiver) => {
        setValue(
            'employedCaregivers',
            [
                ...employedCaregivers,
                {
                    ...caregiver,
                    dateCollaborationStarted: new Date(),
                },
            ],
            { shouldDirty: true },
        );
    };

    return (
        <SectionPaper
            className={classes.section}
            title={<FormattedMessage defaultMessage="Recommended Caregivers" id="recommended_caregivers" />}
        >
            <Controller
                name="recommendedCaregivers"
                control={control}
                render={({ field }) => (
                    <CaregiversList {...field} onStartCollaboration={onStartCollaboration} />
                )}
            />
        </SectionPaper>
    );
};

export default withStyles(styles)(RecommendedCaregiversPane);
