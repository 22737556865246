import { DIRECTION_NEXT, DIRECTION_BACK } from 'constants/caregivers';
import { combineEpics, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { pickBy, pick } from 'lodash';

import {
    catchError,
    filter,
    map,
    mergeMap,
} from 'rxjs/operators';
import env from 'env';

import {
    Document$,
    PaginationCollection$,
    db,
    auth,
    firebase,
} from 'services/caregivers';
import SendSMS$ from 'services/smsService/smsService';

import { sendNotification } from 'models/notifications';
import { closeDialog } from 'models/ui';
import { addToRecent } from 'models/app';

import { getStartOperator } from 'utils';

import * as actions from './actions';

const { caregiversCollection } = env;

const loadCaregiversEpic = (actions$, state$) => actions$.pipe(
    ofType(
        actions.loadCaregivers.type,
        actions.setCurrentPage.type,
        actions.setItemsPerPage.type,
    ),
    map(({ payload }) => ({
        numOfDocs: state$.value.caregivers.pagination.itemsPerPage,
        start: payload?.direction === DIRECTION_NEXT
            ? state$.value.caregivers.pagination.pointers.last
            : state$.value.caregivers.pagination.pointers.first,
        startOperator: getStartOperator(payload?.direction),
        limitOperator: payload?.direction === DIRECTION_BACK ? 'limitToLast' : 'limit',
    })),
    mergeMap(({
        start,
        numOfDocs,
        startOperator,
        limitOperator,
    }) => PaginationCollection$({
        collectionPath: caregiversCollection,
        orderBy: 'firstName',
        startOperator,
        start,
        limitOperator,
        numOfDocs,
    })),
    map((docs) => [
        docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        docs[0],
        docs[docs.length - 1],
    ]),
    mergeMap(([caregivers, first, last]) => [
        actions.loadCaregivers.succeeded(caregivers),
        actions.setPointerDocs({ first, last }),
    ]),
);

const loadCaregiversLengthEpic = (actions$) => actions$.pipe(
    ofType(actions.loadCaregivers.type),
    mergeMap(() => Document$(caregiversCollection, 'length')),
    map(({ length }) => actions.setTotalCaregivers(length)),
);

const loadExpandedCaregiverEpic = (actions$) => actions$.pipe(
    ofType(actions.expandCaregiverProfile.type, actions.updateCaregiverInfo.succeeded.type),
    filter(({ payload }) => payload),
    mergeMap(({ payload }) => Document$(caregiversCollection, payload)),
    map((caregiver) => actions.expandCaregiverProfile.succeeded(caregiver)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.expandCaregiverProfile.failed(),
    ])),
);

const updateCaregiverInfoEpic = (actions$) => actions$.pipe(
    ofType(actions.updateCaregiverInfo.type),
    map(({ payload }) => pickBy(payload, (value) => value !== undefined)),
    mergeMap(({ id, ...update }) => db
        .collection(caregiversCollection)
        .doc(id)
        .set(update, { merge: true })
        .then(() => id)),
    mergeMap((id) => [
        actions.updateCaregiverInfo.succeeded(id),
        sendNotification({
            message: 'Profile updated successfully',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }),
    ]),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.updateCaregiverInfo.failed(),
    ])),
);

const newCaregiverEpic = (actions$) => actions$.pipe(
    ofType(actions.newCaregiver.type),
    mergeMap(({ payload }) => auth
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then(({ user }) => ({ user, payload }))),
    mergeMap(({ user, payload }) => db
        .collection(caregiversCollection)
        .doc(user?.uid)
        .set({
            reviewed: false,
            available: false,
            registerDate: firebase.firestore.Timestamp.fromDate(new Date()),
            email: user?.email,
            ...pick(payload, ['firstName', 'lastName']),
        })),
    mergeMap(() => [
        actions.newCaregiver.succeeded(),
        closeDialog(),
        sendNotification({
            message: 'New user created successfully',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }),
    ]),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.newCaregiver.failed(),
    ])),
);

const sendRegistrationSMSEpic = (actions$) => actions$.pipe(
    ofType(actions.sendRegistrationSMSToCaregiver.type),
    map(({ payload }) => [
        payload?.phoneNumber?.replace('+', '').replace(/ /g, ''),
        payload?.message,
    ]),
    mergeMap(([phoneNumber, message]) => SendSMS$(phoneNumber, message)),
    mergeMap((resp) => [
        sendNotification({
            message: 'SMS Delivered!',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }),
        actions.sendRegistrationSMSToCaregiver.succeeded(resp),
        closeDialog(),
    ]),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.sendRegistrationSMSToCaregiver.failed(error),
    ])),
);

const addRelativeToRecentsEpic = (actions$) => actions$.pipe(
    ofType(actions.expandCaregiverProfile.succeeded.type),
    map(({ payload }) => addToRecent({
        id: payload.id,
        type: 'caregiver',
    })),
);

export default combineEpics(
    loadCaregiversEpic,
    loadCaregiversLengthEpic,
    loadExpandedCaregiverEpic,
    updateCaregiverInfoEpic,
    newCaregiverEpic,
    sendRegistrationSMSEpic,
    addRelativeToRecentsEpic,
);
