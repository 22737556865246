import { CAREGIVERS_EXPORT_VIEW } from 'constants/permissions';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from '@mui/x-data-grid';

import { withProps } from 'libraries/components';
import { userPermissions } from 'models/user';

const Toolbar = ({ userPermissions }) => (
    <GridToolbarContainer>
        <GridToolbarColumnsButton
            id="HELLO"
        />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {userPermissions.includes(CAREGIVERS_EXPORT_VIEW) && (
            <GridToolbarExport />
        )}
    </GridToolbarContainer>
);

export default withProps({ userPermissions })(Toolbar);
