import { compose } from 'redux';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import {
    RateReview as RateReviewIcon,
    AccountCircle as AccountCircleIcon,
    PersonAdd as PersonAddIcon,
} from '@mui/icons-material';

import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import { expandedCaregiverProfile } from 'models/caregivers';

import { FormattedMessage } from 'react-intl';
import { Connector, StepIcon } from './components';
import styles from './styles';

const getSteps = () => [
    {
        label: <FormattedMessage defaultMessage="Sign on" id="sign_on" />,
        stepIcon: <PersonAddIcon />,
    },
    {
        label: <FormattedMessage defaultMessage="Personal Info" id="personal_info" />,
        stepIcon: <AccountCircleIcon />,
    },
    {
        label: <FormattedMessage defaultMessage="Review" id="review" />,
        stepIcon: <RateReviewIcon />,
    },
];

const getCurrentStep = (caregiver) => {
    if (caregiver?.reviewed && caregiver?.available) {
        return 3;
    }
    if (caregiver?.reviewed) {
        return 2;
    }
    if (
        caregiver?.firstName
        && caregiver?.lastName
        && caregiver?.phoneNumber
        && caregiver?.address
    ) {
        return 1;
    }

    return 0;
};

const ProfileProgress = ({ classes, expandedCaregiverProfile }) => {
    const steps = getSteps();
    const currentStep = getCurrentStep(expandedCaregiverProfile);

    return currentStep < 3 && (
        <div className={classes.root}>
            <Stepper alternativeLabel activeStep={currentStep} connector={<Connector />}>
                {steps.map(({
                    label,
                    stepIcon,
                }) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={
                                ({ icon, ...props }) => <StepIcon icon={stepIcon} {...props} />
                            }
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
};

export default compose(
    withStyles(styles),
    withProps({ expandedCaregiverProfile }),
)(ProfileProgress);
