export const elGR = {
    account_information: 'Πληροφορίες Λογαριασμού',
    actions: 'Ενέργειες',
    address: 'Διεύθυνση',
    age: 'Ηλικία',
    available_caregivers: 'Διαθέσιμοι Φροντιστές',
    cancelled: 'Ακυρώθηκε',
    caregivers_registered_last_week: 'Φροντιστές Εγγεγραμμένοι Την Περασμένη Εβδομάδα',
    caregivers_registered_today: 'Φροντιστές Εγγεγραμμένοι Σήμερα',
    caregivers: 'Φροντιστές',
    closed: 'Κλειστό',
    columns: 'ΣΤΗΛΕΣ',
    contract_duration: 'Διάρκεια Συμβολαίου',
    dashboard: 'Πίνακας Ελέγχου',
    density: 'ΠΥΚΝΟΤΗΤΑ',
    employed: 'Εργαζόμενος',
    export: 'ΕΞΑΓΩΓΗ',
    feature_flags: 'Σημαίες Χαρακτηριστικών',
    filters: 'ΦΙΛΤΡΑ',
    first_name: 'Όνομα',
    full_name: 'Ονοματεπώνυμο',
    last_name: 'Επώνυμο',
    matching: 'Ταίριασμα',
    matched: 'Έκλεισε',
    new_case: 'Νέα Υπόθεση',
    not_reviewed_caregivers: 'Φροντιστές Χωρίς Αξιολόγιση',
    operations: 'Λειτουργίες',
    patient_name: 'Όνομα Ασθενή',
    phone: 'Τηλέφωνο',
    phone_number: 'Αριθμός Τηλεφώνου',
    photo: 'Φωτογραφία',
    rating: 'Βαθμολογία',
    relatives_in_need: 'Συγγενείς Σε Ανάγκη',
    relatives_registered_today: 'Συγγενείς Εγγεγραμμένοι Σήμερα',
    relatives: 'Συγγενείς',
    reviewed: 'Έχουν αξιολογηθεί',
    status: 'Κατάσταση',
    total_caregivers: 'Συνολικές Φροντιστές',
    total_relatives: 'Συνολικοί Συγγενείς',
    settings: 'Ρυθμίσεις',
    manage_users: 'Διαχείριση Χρηστών',
    sign_out: 'Αποσύνδεση',
    send: 'Αποστολή',
    send_link: 'Αποστολή Συνδέσμου',
    create_account: 'Δημιουργία Λογαριασμού',
    cancel: 'Ακύρωση',
    create: 'Δημιουργία',
    add_caregiver: 'Προσθήκη Φροντιστή',
    personal_details: 'Προσωπικές Λεπτομέρειες',
    patient_details: 'Λεπτομέρειες Ασθενή',
    patient_address: 'Διεύθυνση Ασθενή',
    services: 'Υπηρεσίες',
    father: 'Πατέρας',
    mother: 'Μητέρα',
    brother: 'Αδελφός',
    sister: 'Αδελφή',
    aunt: 'Θεία',
    grandfather: 'Παππούς',
    uncle: 'Θείος',
    grandmother: 'Γιαγιά',
    patient_first_name: 'Όνομα Ασθενή',
    patient_last_name: 'Επώνυμο Ασθενή',
    lives_with: 'Ζει Μαζί',
    sex: 'Φύλο',
    date_of_birth: 'Ημερομηνία Γέννησης',
    bio: 'Βιογραφικό',
    has_kids: 'Έχει Παιδιά',
    smoker: 'Καπνιστής',
    has_drivers_licence: 'Έχει Άδεια Οδήγησης',
    has_a_car: 'Έχει Αυτοκίνητο',
    works_with_pets: 'Δουλεύει Με Κατοικίδια',
    internet_user: 'Χρήστης Διαδικτύου',
    identification: 'Ταυτοποίηση',
    health_certificate: 'Πιστοποιητικό Υγείας',
    general_information: 'Γενικές Πληροφορίες',
    availability: 'Διαθεσιμότητα',
    available: 'Διαθέσιμο',
    general: 'Γενικά',
    appearance: 'Εμφάνιση',
    notifications: 'Ειδοποιήσεις',
    organisation: 'Οργάνωση',
    new_caregivers: 'Νέοι Φροντιστές',
    new_relatives: 'Νέοι Συγγενείς',
    case_updates: 'Ενημερώσεις Περιστατικών',
    notify_me_on: 'Ειδοποίησέ Με Για',
    live_in: 'Εσωτερική',
    live_out_full_time: 'Εξωτερική - πλήρης ωραρίο',
    part_time: 'Μερική απασχόληση',
    overnight: 'Διανυκτέρευση',
    company_at_home: 'Συντροφία στο σπίτι',
    company_on_vacation: 'Συντροφία στις διακοπές',
    permanent: 'Μόνιμη',
    short_term: 'Περιορισμένης διάρκειας',
    female: 'Γυναίκα',
    male: 'Άνδρας',
    either: 'Οποιοδήποτε από τα δύο',
    cooperative: 'Συνεργάσιμος',
    diapers: 'Πάνες',
    hospital_bed: 'Νοσοκομειακό Κρεβάτι',
    catheter: 'Καθέτηρας',
    using_levin: 'Χρήση Levin',
    oxygen: 'Οξυγόνο',
    bed_rests_wounds: 'Κατακλήσεις - Πληγές',
    sleeps_at_night: 'Κοιμάται τη νύχτα',
    wakes_up_1_2_times: 'Ξυπνάει 1-2 φορές',
    wakes_up_over_3_times: 'Ξυπνάει πάνω από 3 φορές',
    memory_loss: 'Απώλεια μνήμης',
    difficulty_speaking: 'Δυσκολία στην ομιλία',
    difficulty_doing_things: 'Δυσκολία στο να κάνουν πράγματα',
    change_of_personality: 'Αλλαγή προσωπικότητας',
    weird_behaviour: 'Παράξενη συμπεριφορά',
    stress_and_depression: 'Στρες και κατάθλιψη',
    can_not_sleep: 'Δεν μπορεί να κοιμηθεί',
    Illusions: 'Ιλώσεις',
    other: 'Άλλο',
    walking_normally: 'Περπατάει κανονικά',
    walking_with_assistance: 'Περπατάει με βοήθεια',
    walking_with_stick: 'Περπατάει με μπαστούνι',
    needs_help_getting_up: 'Χρειάζεται βοήθεια για να σηκωθεί',
    needs_wheelchair: 'Χρειάζεται αμαξίδιο',
    bedridden: 'Κατάκοιτος',
    shower: 'Βοήθεια στο μπάνιο',
    personal_care: 'Προσωπική υγιεινή',
    food_preparation: 'Προετοιμασία φαγητού',
    housekeeping: 'Καθαριότητα σπιτιού',
    prescription_reminder: 'Υπενθύμιση φαρμακευτικής αγωγής',
    external_tasks: 'Εξωτερικές εργασίες',
    exercise: 'Άσκηση',
    company: 'Συντροφία',
    parkinson: 'Παρκινσόν',
    diabetes: 'Διαβήτης',
    heart_disease: 'Καρδιακά Προβλήματα',
    cancer: 'Καρκίνος',
    stroke: 'Εγκεφαλικό',
    respiratory: 'Αναπνευστικό',
    depression: 'Κατάθλιψη',
    blood_pressure: 'Αρτηριακή πίεση',
    arthritis: 'Αρθρίτιδα',
    osteoporosis: 'Οστεοπόρωση',
    vision: 'Οπτική',
    crane: 'Γερανός',
    children: 'Παιδιά',
    alone: 'Μόνος',
    they_help_a_lot: 'Χρειάζεται πολύ βοήθεια',
    they_help_a_little: 'Χρειάζεται λίγη βοήθεια',
    they_dont_help_at_all_weight_lifting: 'Δεν βοηθάει καθόλου  - Σήκωμα βάρους',
    patient_info: 'Πληροφορίες Ασθενή',
    patients_first_name: 'Όνομα Ασθενή',
    patients_last_name: 'Επώνυμο Ασθενή',
    services_needed: 'Απαιτούμενες Υπηρεσίες',
    caregiver_sex: 'Φύλο Φροντιστή',
    covid_vaccination_required: 'Απαιτείται Εμβόλιο Covid',
    yes: 'Ναι',
    no: 'Όχι',
    house_size: 'Μέγεθος Σπιτιού',
    room_for_caregiver: 'Δωμάτιο για τον Φροντιστή',
    smoking_allowed: 'Επιτρέπεται το Κάπνισμα',
    wifi: 'WiFi',
    pets: 'Κατοικίδια',
    dogs: 'Σκύλοι',
    cats: 'Γάτες',
    other_pet: 'Άλλο Κατοικίδιο',
    environment_notes: 'Σημειώσεις για το Περιβάλλον',
    environment: 'Περιβάλλον',
    compensation: 'Μισθοδοσία',
    currency: 'Νόμισμα',
    year: 'Έτος',
    month: 'Μήνας',
    week: 'Εβδομάδα',
    day: 'Ημέρα',
    hour: 'Ώρα',
    days_off_per: 'Ημέρες ανά',
    days: 'Ημέρες',
    notes: 'Σημειώσεις',
    recommended_caregivers: 'Προτεινόμενοι Φροντιστές',
    start_collection: 'Έναρξη Συνεργασίας',
    remove: 'Αφαίρεση Φροντιστή',
    recommended_on: 'Προτάθηκε στις',
    employed_caregivers: 'Απασχολούμενοι Φροντιστές',
    starts_on: 'Ξεκινά στις',
    plan_info: 'Πληροφορίες Πακέτου',
    payment_accepted: 'Αποδoχή Πληρωμής',
    cancel_payment: 'Ακύρωση Πληρωμής',
    weight: 'Βάρος',
    id: 'ID',
    date_activated: 'Ημερομηνία ενεργοποίησης',
    slug_surname: 'Επώνύμο σε μορφή slug',
    date_registered: 'Ημερομηνία εγγραφής',
    credits: 'Μονάδες Πίστωσης',
    softone_id: 'SoftOne ID',
    last_logged_in: 'Τελευταία σύνδεση',
    slug_name: 'Όνομα σε μορφή slug',
    softone_sync_date: 'Ημερομηνία συγχρονισμού SoftOne',
    paid_from_admin: 'Εξοφλήθηκε από διαχειριστή',
    approved: 'Εγκρίθηκε',
    active: 'Ενεργός',
    deleted: 'Διεγραμμένος',
    mobile_number: 'Κινητό Τηλέφωνο',
    home_number: 'Σταθερό Τηλέφωνο',
    please_enter_the_caregivers_details: 'Παρακαλώ εισάγετε τα στοιχεία του Φροντιστή',
    password_message: 'Αυτός ο κωδικός πρόσβασης δημιουργείται αυτόματα. Μοιραστείτε τον κωδικό πρόσβασης με\n'
        + '                 τον φροντιστή και ζητήστε τους να το αλλάξουν.',
    send_link_message: 'Πληκτρολογήστε έναν αριθμό για να στείλετε έναν σύνδεσμο με την εφαρμογή φροντιστή',
    finding_care_for: 'Αναζητά φροντίδα για:',
    spouse: 'Σύζυγος',
    years_old: 'Χρονών',
    next: 'Επόμενο',
    back: 'Πίσω',
    optional: 'Προαιρετικό',
    reset: 'Επαναφορά',
    skip: 'Παράλειψη',
    finish: 'Τέλος',
    profile: 'Προφίλ',
    password: 'Κωδικός',
    mon: 'Δευ',
    tue: 'Τρι',
    wed: 'Τετ',
    thu: 'Πεμ',
    fri: 'Παρ',
    sat: 'Σαβ',
    sun: 'Κυρ',
    early_morning: 'Νωρίς το πρωί (6-9)',
    morning: 'Πρωί (9-12)',
    early_afternoon: 'Νωρίς το απόγευμα (12-15)',
    afternoon: 'Απόγευμα (15-18)',
    evening: 'Βράδυ (18-21)',
    night: 'Νύχτα (21-24)',
    midnight: 'Μεσάνυχτα (24-6)',
    sign_on: 'Σύνδεση',
    personal_info: 'Προσωπικές Πληροφοριές',
    review: 'Αξιολόγιση',
    has_health_issues: 'Έχει θέματα  υγείας',
    sleep: 'Ύπνος',
    invoiced_on: 'Τιμολογήθηκε στις ',
    invoice_no: 'Τιμολόγιο Νο.',
    invoiced: 'Τιμολογήθηκε ',
    payed_on: 'Πληρώθηκε στις ',
    payed: 'Πληρώθηκε ',
    not_payed: 'Δεν Πληρώθηκε',
    public_transport: 'Μέσα Μαζικής Μεταφοράς',
    themselves: 'Για τους ίδιους',
    other_needs: 'Άλλες ανάγκες',
    delete_caregiver: 'Διαγραφή Φροντιστή',
    open_profile: 'Άνοιγμά Προφίλ',
    bookmark_profile: 'Κρατήστε τον Φροντιστή',
    hide_caregiver: 'Κρύψε τον Φροντιστή',
    show: 'Εμφάνισε τον Φροντιστή',
    search_or_jump_to: 'Αναζητήστε ή μεταφερθείτε',
    email: 'Email',
    needs: 'Ανάγκες',
    select: 'Επέλεξε',
    no_status: 'No status',
    dementia: 'Άνοια',
    movement: 'Κίνηση',
    patients_movement_effort: 'Ανάγκη βοήθειας στην κίνηση του ασθενή',
    illusions: 'Παραισθήσεις',
    other_health_issues: 'Άλλα θέματα υγείας',
    health_notes: 'Σημειώσεις για θέματα υγείας',
    add_relative: 'Προσθήκη Συγγενή',
    rep: 'Υπάλληλος',
    registered: 'Εγγράφηκε',
    collaboration_started_on: 'Η συνεργασία άρχισε στις:',
    collaboration_ended_on: 'Η συνεργασία τελείωσε στις:',
    end_collaboration: 'Τέλος συνεργασίας',
    to: 'Εώς',
    present: 'Σήμερα',
    per: 'ανά',
    no_plan_selected: 'Δεν έχει επιλεχθεί πακέτο',
    start_vip_plan: 'Ξεκινήστε πακέτο VIP',
    dementia_notes: 'Σημειώσεις για την άνοια',
    date_collaboration_started: 'Αρχή συνεργασίας',
    is: 'Είναι',
};
