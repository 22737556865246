const styles = (theme) => ({
    spacer: {
        height: theme.spacing(2),
        width: theme.spacing(2),
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    nameInput: {
        width: '50%',
    },
});

export default styles;
