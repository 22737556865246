import { compose } from 'redux';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import {
    IconButton,
    Tooltip,
} from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Delete as DeleteIcon,
    Bookmark as BookmarkIcon,
} from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import {
    expandCaregiverProfile,
    pinnedCaregivers,
    pinCaregiver,
    unpinCaregiver,
} from 'models/caregivers';
import { FormattedMessage } from 'react-intl/lib';
import styles from './styles';

const CaregiverActions = ({
    classes,
    caregiver,
    expandCaregiverProfile,
    pinnedCaregivers,
    pinCaregiver,
    unpinCaregiver,
}) => {
    const pinned = pinnedCaregivers.includes(caregiver);

    return (
        <div className={classes.root}>
            <Tooltip title={<FormattedMessage defaultMessage="Delete Caregiver" id="delete_caregiver" />}>
                <IconButton size="large">
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={caregiver.hidden
                ? (
                    <FormattedMessage
                        defaultMessage="Show Caregiver"
                        id="show_caregiver"
                    />
                )
                : (
                    <FormattedMessage
                        defaultMessage="Hide Caregiver"
                        id="hide_caregiver"
                    />
                )}
            >
                <IconButton size="large">
                    {caregiver?.hidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
            </Tooltip>
            <div className={classes.spacer} />
            <Tooltip title={<FormattedMessage defaultMessage="Bookmark Profile" id="bookmark_profile" />}>
                <IconButton
                    className={clsx({
                        [classes.selectedButton]: pinned,
                    })}
                    onClick={() => (pinned ? unpinCaregiver(caregiver) : pinCaregiver(caregiver))}
                    size="large"
                >
                    <BookmarkIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={<FormattedMessage defaultMessage="Open Profile" id="open_profile" />}>
                <IconButton
                    component={Link}
                    to={`/caregivers/${caregiver.id}`}
                    onClick={() => expandCaregiverProfile(caregiver)}
                    size="large"
                >
                    <PermContactCalendarIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        expandCaregiverProfile: expandCaregiverProfile.succeeded,
        pinnedCaregivers,
        pinCaregiver,
        unpinCaregiver,
    }),
)(CaregiverActions);
