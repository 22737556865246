import firebase from 'firebase/app';
import 'firebase/firestore';

import firebaseConfig from './config';

const caregiverFB = firebase.initializeApp(firebaseConfig, 'caregiver');

const db = caregiverFB.firestore();
const auth = caregiverFB.auth();

export {
    caregiverFB,
    db,
    auth,
    firebase,
};

export default firebase;
