import { compose } from 'redux';
import { Link } from 'react-router-dom';

import {
    Tabs as MuiTabs,
    Tab,
    IconButton,
    Tooltip,
    Divider,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import withStyles from '@mui/styles/withStyles';
import { withProps } from 'libraries/components';
import {
    pinnedCaregivers,
    unpinCaregiver,
    expandedCaregiverProfile,
    expandCaregiverProfile,
} from 'models/caregivers';

import {
    pinnedRelatives,
    unpinRelative,
    expandRelativeProfile,
} from 'models/relatives';

import styles from './styles';

const a11yProps = (id) => ({
    id: `scrollable-auto-tab-${id}`,
    'aria-controls': `scrollable-auto-tabpanel-${id}`,
    key: id,
});

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: 'transparent',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        border: 'none',
    },
}))(Tooltip);

const Tabs = ({
    classes,
    pinnedCaregivers,
    unpinCaregiver,
    expandedCaregiverProfile,
    expandCaregiverProfile,
    pinnedRelatives,
    unpinRelative,
    expandRelativeProfile,
    loadAndExpandCaregiverProfile,
    loadAndExpandRelativeProfile,
}) => (
    <>
        <MuiTabs
            className={classes.tabs}
            value={
                pinnedCaregivers.findIndex((caregiver) => (
                    caregiver.id === expandedCaregiverProfile?.id))
            }
            onChange={
                (_, selectedCaregiver) => {
                    const needsFetch = pinnedCaregivers?.[selectedCaregiver]?.needsFetch;
                    const payload = needsFetch
                        ? pinnedCaregivers?.[selectedCaregiver]?.id
                        : pinnedCaregivers[selectedCaregiver];
                    (needsFetch
                        ? loadAndExpandCaregiverProfile
                        : expandCaregiverProfile
                    )(payload);
                }
            }
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
        >
            {pinnedCaregivers.map(({ id, firstName, lastName }) => (
                <HtmlTooltip
                    title={(
                        <IconButton
                            className={classes.closeIcon}
                            onClick={() => unpinCaregiver(id)}
                            size="large"
                        >
                            <ClearIcon />
                        </IconButton>
                    )}
                    interactive
                >
                    <Tab
                        label={`${firstName} ${lastName}`}
                        component={Link}
                        to={`/caregivers/${id}`}
                        {...a11yProps(id)}
                    />
                </HtmlTooltip>
            ))}
        </MuiTabs>
        <Divider orientation="vertical" flexItem />
        <MuiTabs
            className={classes.tabs}
            value={
                pinnedRelatives.findIndex((relative) => (
                    relative.id === expandedCaregiverProfile?.id))
            }
            onChange={
                (_, selectedRelative) => (
                    selectedRelative.needsFetch
                        ? loadAndExpandRelativeProfile
                        : expandRelativeProfile
                )(pinnedRelatives[selectedRelative])
            }
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
        >
            {pinnedRelatives.map(({ id, firstName, lastName }) => (
                <HtmlTooltip
                    title={(
                        <IconButton
                            className={classes.closeIcon}
                            onClick={() => unpinRelative(id)}
                            size="large"
                        >
                            <ClearIcon />
                        </IconButton>
                    )}
                    interactive
                >
                    <Tab
                        label={`${firstName} ${lastName}`}
                        component={Link}
                        to={`/relatives/${id}`}
                        {...a11yProps(id)}
                    />
                </HtmlTooltip>
            ))}
        </MuiTabs>
    </>
);

export default compose(
    withStyles(styles),
    withProps({
        pinnedCaregivers,
        unpinCaregiver,
        expandedCaregiverProfile,
        expandCaregiverProfile: expandCaregiverProfile.succeeded,
        loadAndExpandCaregiverProfile: expandCaregiverProfile,

        pinnedRelatives,
        unpinRelative,
        expandRelativeProfile: expandRelativeProfile.succeeded,
        loadAndExpandRelativeProfile: expandRelativeProfile,
    }),
)(Tabs);
