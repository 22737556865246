import { compose } from 'redux';
import { SplashScreen } from '@grandmama/ui-splash-screen';

import { withHotKeys } from 'utils';
import { withNotifier } from 'components';
import { withProps } from 'libraries/components';
import { isAuthenticated, signInChecked, userInfo } from 'models/user';
import Routes from 'routes';

const App = ({
    isAuthenticated,
    signInChecked,
    userInfo,
}) => (!isAuthenticated && !signInChecked && !!userInfo
    ? (
        <>
            <SplashScreen logo="https://i.ibb.co/hDphXbj/nRKFsQgA.png" out={signInChecked} />
        </>
    ) : (
        <div className="App">
            <Routes />
        </div>
    ));

export default compose(
    withHotKeys,
    withProps({
        isAuthenticated,
        signInChecked,
        userInfo,
    }),
    withNotifier,
)(App);
