const styles = (theme) => ({
    icon: {
        color: 'white',
    },
    spacer: {
        flexGrow: 1,
    },
    popover: {
        width: '48vw',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
    },
    listContainer: {
        flexBasis: '50%',
        flexGrow: 0,
    },
});

export default styles;
