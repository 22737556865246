import { SAVE_ICON } from 'constants/fab';
import { useEffect } from 'react';
import { compose } from 'redux';

import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useFormContext } from 'react-hook-form';

import { useFab } from 'components/fab';
import { withProps } from 'libraries/components';
import { setShowFab } from 'models/ui';

import withForm from './withForm';
import styles from './styles';

const Layout = ({
    info: Info,
    profile: Profile,
    setShowFab,
}) => {
    const { formState } = useFormContext();

    useFab({
        fabIcon: SAVE_ICON,
        fabProps: {
            form: 'caregiverForm',
            type: 'submit',
        },
    });

    useEffect(() => {
        setShowFab(formState.isDirty);
    }, [formState.isDirty]);

    return (
        <Grid container spacing={3}>
            <Grid item md={4}>
                <Info />
            </Grid>
            <Grid item md={8}>
                <Profile />
            </Grid>
        </Grid>
    );
};

export default compose(
    withForm('caregiverForm'),
    withStyles(styles),
    withProps({ setShowFab }),
)(Layout);
