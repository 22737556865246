import withStyles from '@mui/styles/withStyles';

const styles = withStyles(() => ({
    root: {
        textAlign: 'center',
        margin: '8em auto',
    },
    graphic: {
        width: '12em',
        height: '12em',
    },
}));

export default styles;
