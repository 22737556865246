import { LIST_VIEW } from 'constants/caregivers';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initState = {
    caregivers: [],
    view: LIST_VIEW,
    totalCaregivers: 0,
    loadingCaregivers: false,
    expandedCaregiverProfile: null,
    expandedCaregiverLoading: false,
    newCaregiverLoading: false,
    pinnedCaregivers: [],
    pagination: {
        currentPage: 0,
        itemsPerPage: 50,
        pointers: {
            first: null,
            last: null,
        },
    },
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.loadCaregivers.succeeded, (state, { payload }) => {
        state.caregivers = payload;
        state.loadingCaregivers = false;
    })
    .addCase(actions.changeCaregiversView, (state, { payload }) => {
        state.view = payload;
    })
    .addCase(actions.loadCaregivers, (state) => {
        state.loadingCaregivers = true;
    })
    .addCase(actions.setTotalCaregivers, (state, { payload }) => {
        state.totalCaregivers = payload;
    })
    .addCase(actions.setCurrentPage, (state, { payload }) => {
        state.pagination.currentPage = payload.page;
        state.loadingCaregivers = true;
    })
    .addCase(actions.setPointerDocs, (state, { payload }) => {
        state.pagination.pointers = payload;
    })
    .addCase(actions.setItemsPerPage, (state, { payload }) => {
        state.pagination.itemsPerPage = payload;
        state.loadingCaregivers = true;
    })
    .addCase(actions.expandCaregiverProfile, (state, { payload }) => {
        state.expandedCaregiverProfile = payload;
        state.expandedCaregiverLoading = true;
    })
    .addCase(actions.expandCaregiverProfile.succeeded, (state, { payload }) => {
        state.expandedCaregiverProfile = payload;
        state.expandedCaregiverLoading = false;
    })
    .addCase(actions.pinCaregiver, (state, { payload }) => {
        state.pinnedCaregivers.push(payload);
    })
    .addCase(actions.unpinCaregiver, (state, { payload }) => {
        const index = state
            .pinnedCaregivers
            .findIndex((caregiver) => caregiver.id === payload);
        if (index !== -1) state.pinnedCaregivers.splice(index, 1);
    })
    .addCase(actions.unpinAllCaregivers, (state) => {
        state.pinnedCaregivers = [];
    })
    .addCase(actions.newCaregiver, (state) => {
        state.newCaregiverLoading = true;
    })
    .addCase(actions.newCaregiver.succeeded, (state) => {
        state.newCaregiverLoading = false;
    })
    .addCase(actions.newCaregiver.failed, (state) => {
        state.newCaregiverLoading = false;
    })
    .addCase(actions.sendRegistrationSMSToCaregiver, (state) => {
        state.newCaregiverLoading = true;
    })
    .addCase(actions.sendRegistrationSMSToCaregiver.succeeded, (state) => {
        state.newCaregiverLoading = false;
    })
    .addCase(actions.sendRegistrationSMSToCaregiver.failed, (state) => {
        state.newCaregiverLoading = false;
    }));

export default reducer;
