import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import el from 'date-fns/locale/el';
import ModelProvider from './ModelProvider';
import IntlProvider from './IntProvider';

const Providers = ({
    options,

    theme,
    maxNotifications,
    children,
}) => (
    <ModelProvider options={options}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={maxNotifications}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={el}>
                        <IntlProvider>
                            { children }
                        </IntlProvider>
                    </LocalizationProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </ModelProvider>
);

export default Providers;
