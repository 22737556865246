import {
    AVAILABLE_SERVICES,
    CAREGIVER_SEX_OPTIONS,
    CONTRACT_DURATION_OPTIONS,
    PATIENT_NEEDS_OPTIONS,
} from 'constants/relatives';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import withStyles from '@mui/styles/withStyles';
import SectionPaper from 'components/sectionPaper';

import { DateRangePicker } from '@mui/x-date-pickers-pro';
import '@mui/lab';
import {
    Box, capitalize, LinearProgress, TextField, Typography,
} from '@mui/material';
import { DateTime } from 'luxon';

import { Checkboxes, FormRadioButtons, FormSelect } from 'components';

import { FormattedMessage } from 'react-intl';
import styles from './styles';

const ServicesNeededPane = ({ classes }) => {
    const { control, setValue } = useFormContext();
    const contractDuration = useWatch({
        control,
        name: 'contractDuration',
    });

    return (
        <SectionPaper
            className={classes.section}
            title={<FormattedMessage defaultMessage="Services Needed" id="services_needed" />}
        >
            <FormSelect
                label={(
                    <FormattedMessage
                        defaultMessage="Services Needed"
                        id="services_needed"
                    />
                )}
                name="servicesNeeded"
                control={control}
                options={AVAILABLE_SERVICES}
                getOptionLabel={(option) => (
                    <FormattedMessage
                        defaultMessage={capitalize(option)}
                        id={option}
                    />
                )}
            />
            <Box display="flex" flexDirection="row" gap={2}>
                <Box minWidth="130px">
                    <FormRadioButtons
                        label={(
                            <FormattedMessage
                                defaultMessage="Contract Duration"
                                id="contract_duration"
                            />
                        )}
                        name="contractDuration"
                        control={control}
                        options={CONTRACT_DURATION_OPTIONS}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                    />
                </Box>
                <Box minWidth="130px">
                    <FormRadioButtons
                        label={(
                            <FormattedMessage
                                defaultMessage="Caregiver sex"
                                id="caregiver_sex"
                            />
                        )}
                        name="caregiverSex"
                        control={control}
                        options={CAREGIVER_SEX_OPTIONS}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                    />
                </Box>
                <Box minWidth="110px">
                    <Checkboxes
                        control={control}
                        name="patientNeeds"
                        setValue={setValue}
                        label={(
                            <FormattedMessage
                                defaultMessage="Needs"
                                id="needs"
                            />
                        )}
                        data={PATIENT_NEEDS_OPTIONS.map((item) => ({
                            label: <FormattedMessage defaultMessage={capitalize(item)} id={item} />,
                            value: item,
                        }))}
                    />
                </Box>
                <Box minWidth="110px">
                    <FormRadioButtons
                        label={(
                            <FormattedMessage
                                defaultMessage="Covid Vaccination Required"
                                id="covid_vaccination_required"
                            />
                        )}
                        name="covidVaccinationRequired"
                        control={control}
                        options={['yes', 'no']}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                    />
                </Box>
            </Box>
            {contractDuration === 'short_term' && (
                <Controller
                    control={control}
                    name="contractDurationDates"
                    render={({ field: { value, onChange } }) => {
                        const days = value[1] ? DateTime
                            .fromJSDate(value[1])
                            .diff(DateTime.fromJSDate(value[0]), 'days')
                            .toObject()
                            .days : 0;

                        return (
                            <>
                                <DateRangePicker
                                    value={value}
                                    onChange={onChange}
                                    renderInput={(startProps, endProps) => (
                                        <>
                                            <TextField {...startProps} fullWidth />
                                            <Box sx={{ mx: 2 }}> to </Box>
                                            <TextField {...endProps} fullWidth />
                                        </>
                                    )}
                                    localeText={{
                                        start: 'Start',
                                        end: 'End',
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            hidden: false,
                                        },
                                    }}
                                />
                                <LinearProgress
                                    className={classes.progress}
                                    variant="determinate"
                                    value={days}
                                />
                                <Typography sx={{ ml: 1 }}>
                                    {value[1] && `For ${days} days`}
                                </Typography>
                            </>
                        );
                    }}
                />
            )}
        </SectionPaper>
    );
};

export default withStyles(styles)(ServicesNeededPane);
