import { GridOverlay } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';

import withStyles from '@mui/styles/withStyles';
import styles from './styles';

const LoadingBar = ({ classes }) => (
    <GridOverlay>
        <div className={classes.root}>
            <LinearProgress />
        </div>
    </GridOverlay>
);

export default withStyles(styles)(LoadingBar);
