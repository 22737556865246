import { Fragment } from 'react';
import {
    Divider,
    List,
} from '@mui/material';

import { withProps } from 'libraries/components';
import { userPermissions } from 'models/user';
import { ListItem } from './components';

const DrawerMenu = ({ menus, userPermissions }) => (
    <>
        {menus?.map((menu, i) => (
            <Fragment key={`list-${i + 100}`}>
                <List>
                    {menu
                        ?.filter(({ hiddenLink }) => !hiddenLink)
                        ?.filter(({ permissions }) => (
                            permissions.length === 0
                            || permissions.every((perm) => userPermissions?.includes(perm))
                        ))
                        ?.map((item) => (
                            <ListItem key={item.id} {...item} />
                        ))}
                </List>
                <Divider />
            </Fragment>
        ))}
    </>
);

export default withProps({ userPermissions })(DrawerMenu);
