import firebase from 'firebase/app';
import 'firebase/firestore';

import firebaseConfig from './config';

const relativesFB = firebase.initializeApp(firebaseConfig, 'relatives');

const db = relativesFB.firestore();
const auth = relativesFB.auth();

export {
    relativesFB,
    db,
    auth,
    firebase,
};

export default firebase;
