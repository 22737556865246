import { Grid } from '@mui/material';

import { Payed, NotPayed } from './components';

const VipPlanInfo = ({ control, plan, isDirty }) => (
    <Grid container spacing={2}>
        <Grid item md={6}>
            {plan.payedInitial
                ? (
                    <Payed
                        amount={plan.initialPaymentAmount}
                        paymentDate={plan.dateOfInitialPayment}
                        invoiceNo={plan.initialInvoiceNumber}
                        control={control}
                        invoicedOnDate={plan.initialInvoiceSentOn}
                        isPayedName="plan.payedInitial"
                    />
                ) : (
                    <NotPayed
                        control={control}
                        plan={plan}
                        amountName="initialPaymentAmount"
                        invoiceSentOnName="initialInvoiceSentOn"
                        payedOnName="plan.dateOfInitialPayment"
                        invoiceName="plan.initialInvoiceNumber"
                        isPayedName="plan.payedInitial"
                        canSendInvoice={!isDirty}
                    />
                )}
        </Grid>
        <Grid item md={6}>
            {plan.payedFull
                ? (
                    <Payed
                        amount={plan.fullPaymentAmount}
                        paymentDate={plan.dateOfFullPayment}
                        invoiceNo={plan.fullInvoiceNumber}
                        control={control}
                        invoicedOnDate={plan.fullInvoiceSentOn}
                        isPayedName="plan.payedFull"
                    />
                ) : (
                    <NotPayed
                        control={control}
                        plan={plan}
                        amountName="fullPaymentAmount"
                        invoiceSentOnName="fullInvoiceSentOn"
                        payedOnName="plan.dateOfFullPayment"
                        invoiceName="plan.fullInvoiceNumber"
                        isPayedName="plan.payedFull"
                        canSendInvoice={!isDirty}
                    />
                )}
        </Grid>
    </Grid>
);

export default VipPlanInfo;
