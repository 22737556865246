import { forwardRef } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';

import styles from './styles';

const CustomTextField = ({
    onLoad,
    onPlacesChanged,
    ...rest
}) => (
    <StandaloneSearchBox
        onLoad={onLoad}
        onPlacesChanged={onPlacesChanged}
    >
        <input
            {...rest}
            style={styles.input}
            type="text"
        />
    </StandaloneSearchBox>
);

export default forwardRef(CustomTextField);
