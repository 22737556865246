import { Typography } from '@mui/material';
import { Row } from '..';

const Head = ({ columns }) => (
    <Row>
        {columns.map((column) => (
            <Typography
                key={column}
                variant="h6"
            >
                {column}
            </Typography>
        ))}
    </Row>
);

export default Head;
