import { Box, Button, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const Payed = ({
    control,
    amount,
    invoicedOnDate,
    paymentDate,
    invoiceNo,
    isPayedName,
}) => (
    <>
        <Typography variant="h4">
            {`€${amount}`}
            <Typography
                variant="caption"
                sx={{ color: 'success.main' }}
            >
                <FormattedMessage
                    defaultMessage="Payed"
                    id="payed"
                />
            </Typography>
        </Typography>
        <Typography variant="body1">
            <FormattedMessage
                defaultMessage="Invoiced"
                id="invoiced"
            />
            {`#  ${invoiceNo}`}
        </Typography>
        <Box sx={{ mt: 2 }} />
        <Typography variant="body1">
            <FormattedMessage
                defaultMessage="Invoiced on"
                id="invoiced_on"
            />
            `$
            {new Date(invoicedOnDate).toLocaleDateString()}
            `
        </Typography>
        <Typography variant="body1">
            <FormattedMessage
                defaultMessage="Payed on"
                id="payed_on"
            />
            `$
            {new Date(paymentDate).toLocaleDateString()}
            `
        </Typography>
        <Box sx={{ mt: 2 }} />
        <Controller
            render={({ field: { onChange } }) => (
                <Button variant="outlined" color="error" onClick={() => onChange(false)}>
                    <FormattedMessage
                        defaultMessage="Cancel Payment"
                        id="cancel_payment"
                    />
                </Button>
            )}
            name={isPayedName}
            control={control}
        />
    </>
);

export default Payed;
