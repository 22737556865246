import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { NoAccessGraphic } from 'assets';
import styles from './styles';

const AccessDenied = ({ classes }) => (
    <div className={classes.root}>
        <NoAccessGraphic className={classes.graphic} />
        <Typography className={classes.heading} variant="h2">
            Not sufficient permissions.
        </Typography>
        <Typography className={classes.subheading} variant="body1">
            Ask your admin for more permissions.
        </Typography>
    </div>
);

export default withStyles(styles)(AccessDenied);
