import { compose } from 'redux';
import TablePagination from '@mui/material/TablePagination';

import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import {
    totalPages,
    pagination,
    setCurrentPage,
    setItemsPerPage,
    totalCaregivers,
} from 'models/caregivers';
import { generatePageSelectorPayload } from 'utils';

import styles from './styles';

const Pagination = ({
    classes,
    totalCaregivers,
    pagination,
    setCurrentPage,
    setItemsPerPage,
}) => {
    const handleChangePage = (event, newPage) => {
        setCurrentPage(generatePageSelectorPayload(pagination.currentPage, newPage));
    };
    const handleChangeRowsPerPage = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
    };

    return (
        <div>
            <TablePagination
                className={classes.pagination}
                component="div"
                count={totalCaregivers}
                page={pagination.currentPage}
                onPageChange={handleChangePage}
                rowsPerPage={pagination.itemsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[9, 25, 50, 100]}
            />
        </div>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        totalPages,
        pagination,
        totalCaregivers,
        setCurrentPage,
        setItemsPerPage,
    }),
)(Pagination);
