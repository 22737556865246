import { compose } from 'redux';
import { useForm, Controller } from 'react-hook-form';
import {
    Button,
    CircularProgress,
    DialogActions,
    TextField,
} from '@mui/material';
import { withProps } from 'libraries/components';
import { fetchState, updateFeatureFlag } from 'models/featureFlags';
import { userInfo } from 'models/user';
import { withStyles } from './styles';

const UpdateFeatureFlag = ({
    props,
    handleClose,
    updateFeatureFlag,
    userInfo,
    fetchState,
    classes,
}) => {
    const {
        control,
        handleSubmit,
        formState: {
            isValid,
        },
    } = useForm({
        mode: 'onChange',
    });

    const onSubmit = () => {
        const { id, envType, feature } = props;
        const env = feature[envType];

        updateFeatureFlag({
            id,
            data: {
                ...feature,
                [envType]: {
                    lastModified: new Date(),
                    author: userInfo.displayName,
                    value: !env.value,
                },
            },
        });
    };

    return (
        <form className={classes.container} onSubmit={handleSubmit(onSubmit)} noValidate>
            <Controller
                name="confirm"
                control={control}
                rules={{
                    validate: {
                        isConfirm: (value) => value?.toLowerCase() === 'confirm' || 'Please type confirm to proceed',
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Type Confirm to toggle feature flag."
                        placeholder="confirm"
                        size="small"
                        variant="outlined"
                        error={error?.message}
                        helperText={error?.message}
                        fullWidth
                    />
                )}
            />
            <DialogActions>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!isValid}
                    color="primary"
                    variant="contained"
                    type="submit"
                >
                    {fetchState.isUpdating ? <CircularProgress color="inherit" size={20} /> : 'Confirm'}
                </Button>
            </DialogActions>
        </form>
    );
};

export default compose(
    withStyles,
    withProps({
        updateFeatureFlag,
        userInfo,
        fetchState,
    }),
)(UpdateFeatureFlag);
