import { GlobalHotKeys } from 'react-hotkeys';

import { useProps } from 'libraries/components';
import { setSearchBarFocus } from 'models/ui';

const stateProps = {
    setSearchBarFocus,
};

const keyMap = {
    SEARCH: '/',
};

const withHotKeys = (Component) => (props) => {
    const { setSearchBarFocus } = useProps(stateProps);
    const handlers = {
        SEARCH: () => setSearchBarFocus(true),
    };

    return (
        <>
            <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
            <Component {...props} />
        </>
    );
};

export default withHotKeys;
