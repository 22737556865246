import { useState } from 'react';

import {
    Box,
    Drawer,
    Toolbar,
    List,
    Typography,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { menuItems } from './helper';

import styles from './styles';

const Settings = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <Box sx={styles.container}>
            <Drawer
                sx={styles.drawer}
                variant="permanent"
                anchor="left"
            >
                <Toolbar>
                    <Typography variant="h4">
                        <FormattedMessage defaultMessage="Settings" id="settings" />
                    </Typography>
                </Toolbar>
                <Divider />
                <List>
                    {menuItems.map(({
                        label,
                        icon,
                    }, tab) => (
                        <ListItem
                            selected={tab === selectedTab}
                            button
                            key={label}
                            onClick={() => setSelectedTab(tab)}
                        >
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText primary={label} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={styles.content}
            >
                <Toolbar />
                {menuItems?.[selectedTab]?.component}
            </Box>
        </Box>
    );
};

export default Settings;
