import { CREATE_FEATURE_FLAG } from 'constants/dialog';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { features, fetchState, getFeatureFlags } from 'models/featureFlags';
import { withProps } from 'libraries/components';
import { openDialog } from 'models/ui';
import LoadingCover from 'components/loadingCover';
import { useFab } from 'components/fab';

import { Fab, Head, Body } from './components';

const FeatureFlags = ({
    openDialog,
    getFeatureFlags,
    fetchState,
    features,
}) => {
    useFab({
        fabProps: {
            onClick: () => openDialog({
                title: 'Add Feature',
                type: CREATE_FEATURE_FLAG,
            }),
        },
    });

    useEffect(() => {
        isEmpty(features) && getFeatureFlags();
    }, []);

    return (
        <LoadingCover isLoading={fetchState.isLoading}>
            <Grid
                container
                direction="column"
                style={{ flex: 1 }}
            >
                <div>
                    <Head columns={['Feature Flag', 'Dev', 'Prod', '']} />
                    <Body />
                </div>
                <Fab />
            </Grid>
        </LoadingCover>
    );
};

export default withProps({
    getFeatureFlags,
    fetchState,
    features,
    openDialog,
})(FeatureFlags);
