import { DIRECTION_BACK, DIRECTION_NEXT } from '../constants/caregivers';

const getDirection = (nextPage, currentPage) => {
    if (nextPage === currentPage) {
        return undefined;
    } if (nextPage > currentPage) {
        return DIRECTION_NEXT;
    }
    return DIRECTION_BACK;
};
const generatePageSelectorPayload = (currentPage, nextPage, size) => ({
    page: nextPage,
    pageSize: size,
    direction: getDirection(nextPage, currentPage),
});

export default generatePageSelectorPayload;
