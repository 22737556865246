import { DIRECTION_BACK, DIRECTION_NEXT } from 'constants/caregivers';
import { NEW_CASE } from 'constants/statuses';
import { combineEpics, ofType } from 'redux-observable';
import { from, of, forkJoin } from 'rxjs';
import { pickBy } from 'lodash';

import {
    catchError,
    filter,
    map,
    mergeMap,
    tap,
} from 'rxjs/operators';

import {
    Document$,
    PaginationCollection$,
    db,
    firebase,
} from 'services/relatives';

import { sendNotification } from 'models/notifications';
import { addToRecent } from 'models/app';
import { getStartOperator } from 'utils';
import { logger } from 'services/logger';
import env from 'env';
import SendSMS$ from 'services/smsService/smsService';
import { closeDialog } from 'models/ui';
import * as actions from './actions';

const { relativesCollection } = env;

const eventLogger = logger({ loggerId: 'relatives_actions' });

const loadRelativesEpic = (actions$, state$) => actions$.pipe( //! !!!! needs rewriting
    ofType(
        actions.loadRelatives.type,
        actions.setCurrentRelativesPage.type,
        actions.setRelativeItemsPerPage.type,
    ),
    map(({ payload }) => ({
        numOfDocs: state$.value.relatives.pagination.itemsPerPage,
        start: payload?.direction === DIRECTION_NEXT
            ? state$.value.relatives.pagination.pointers.last
            : state$.value.relatives.pagination.pointers.first,
        startOperator: getStartOperator(payload?.direction),
        limitOperator: payload?.direction === DIRECTION_BACK ? 'limitToLast' : 'limit',
    })),
    mergeMap(({
        start,
        numOfDocs,
        startOperator,
        limitOperator,
    }) => PaginationCollection$({
        collectionPath: relativesCollection,
        orderBy: 'firstName',
        startOperator,
        start,
        limitOperator,
        numOfDocs,
    })),
    map((docs) => [
        docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        docs[0],
        docs[docs.length - 1],
    ]),
    mergeMap(([relatives, first, last]) => [
        actions.loadRelatives.succeeded(relatives),
        actions.setRelativesPointerDocs({ first, last }),
    ]),
);

const loadRelativesLengthEpic = (actions$) => actions$.pipe(
    ofType(actions.loadRelatives.type),
    mergeMap(() => Document$(relativesCollection, 'length')),
    map(({ length }) => actions.setTotalRelatives(length)),
);

const loadExpandedRelativeEpic = (actions$) => actions$.pipe(
    ofType(actions.expandRelativeProfile.type, actions.updateRelativeInfo.succeeded.type),
    filter(({ payload }) => payload),
    mergeMap(({ payload }) => Document$(relativesCollection, payload)),
    map((relative) => actions.expandRelativeProfile.succeeded(relative)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.expandRelativeProfile.failed(),
    ])),
);

const updateRelativeInfoEpic = (actions$) => actions$.pipe(
    ofType(actions.updateRelativeInfo.type),
    map(({ payload }) => pickBy(payload, (value) => value !== undefined)),
    mergeMap(({ id, registeredBy, ...update }) => db
        .collection(relativesCollection)
        .doc(id)
        .set(update, { merge: true })
        .then(() => id)),
    mergeMap((id) => [
        actions.updateRelativeInfo.succeeded(id),
        sendNotification({
            message: 'Profile updated successfully',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }),
    ]),
    tap(() => eventLogger.info('relativeInfoUpdated')),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.updateRelativeInfo.failed(),
    ])),
);

const addNewRelativeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addNewRelative.type),
    map(({ payload }) => pickBy(payload, (value) => value !== undefined)),
    map((payload) => ({
        ...payload,
        registerDate: firebase.firestore.Timestamp.fromDate(new Date()),
        registeredBy: state$.value.user.info.id,
        caseStatus: NEW_CASE,
        statusChangedOn: firebase.firestore.Timestamp.fromDate(new Date()),
    })),
    mergeMap((payload) => db
        .collection(relativesCollection)
        .add(payload)
        .then(({ id }) => id)),
    mergeMap((id) => [
        actions.addNewRelative.succeeded(id),
        sendNotification({
            message: 'Relative added successfully',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }),
    ]),
    tap(() => eventLogger.info('newRelativeAdded')),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.addNewRelative.failed(),
    ])),
);

const sendInvoiceToRelativeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.sendInvoiceToRelative.type),
    map(({ payload }) => [
        payload?.mobilePhone?.replace('+', '').replace(/ /g, ''),
        payload?.message,
        payload,
    ]),
    mergeMap(([phoneNumber, message, original]) => forkJoin([
        SendSMS$(phoneNumber, message),
        of(original),
    ])),
    mergeMap(([resp, payload]) => [
        sendNotification({
            message: 'SMS Delivered!',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }),
        actions.sendInvoiceToRelative.succeeded(resp),
        actions.updateRelativeInfo({
            id: state$.value.relatives?.expandedRelativeProfile?.id,
            plan: {
                [payload.invoiceSentOnName]: firebase.firestore.Timestamp.fromDate(new Date()),
            },
        }),
        closeDialog(),
    ]),
    tap(() => eventLogger.info('sent invoice to relative')),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.sendInvoiceToRelative.failed(error),
    ])),
);

const addRelativeToRecentsEpic = (actions$) => actions$.pipe(
    ofType(actions.expandRelativeProfile.succeeded.type),
    map(({ payload }) => addToRecent({
        id: payload.id,
        type: 'relative',
    })),
);

export default combineEpics(
    loadRelativesEpic,
    loadRelativesLengthEpic,
    loadExpandedRelativeEpic,
    updateRelativeInfoEpic,
    addNewRelativeEpic,
    sendInvoiceToRelativeEpic,
    addRelativeToRecentsEpic,
);
