import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initState = {
    totalCaregivers: 0,
    totalRelatives: 0,
    availableCaregivers: 0,
    notReviewedCaregivers: 0,
    relativesInNeed: 0,
    caregiversRegistered: [],
    relativesRegistered: [],
    contractDurationGroups: {},
    caregiversRegisteredLastWeek: {},
    relativesRegisteredLastWeek: {},
    loading: {
        totalCaregivers: false,
        totalRelatives: false,
        availableCaregivers: false,
        caregiversRegistered: false,
        relativesRegistered: false,
        notReviewedCaregivers: false,
        relativesInNeed: false,
        contractDurationGroups: false,
        caregiversRegisteredLastWeek: false,
        relativesRegisteredLastWeek: false,
    },
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.fetchDashboardData, (state) => {
        state.loading.totalCaregivers = true;
        state.loading.totalRelatives = true;
        state.loading.caregiversRegistered = true;
        state.loading.relativesRegistered = true;
        state.loading.availableCaregivers = true;
        state.loading.notReviewedCaregivers = true;
        state.loading.relativesInNeed = true;
        state.loading.contractDurationGroups = true;
        state.loading.caregiversRegisteredLastWeek = true;
        state.loading.relativesRegisteredLastWeek = true;
    })
    .addCase(actions.setRelatives, (state, { payload }) => {
        state.relativesRegistered = payload;
        state.loading.relativesRegistered = false;
    })
    .addCase(actions.setCaregivers, (state, { payload }) => {
        state.caregiversRegistered = payload;
        state.loading.caregiversRegistered = false;
    })
    .addCase(actions.setTotalCaregivers, (state, { payload }) => {
        state.totalCaregivers = payload;
        state.loading.totalCaregivers = false;
    })
    .addCase(actions.setTotalRelatives, (state, { payload }) => {
        state.totalRelatives = payload;
        state.loading.totalRelatives = false;
    })
    .addCase(actions.setAvailableCaregivers, (state, { payload }) => {
        state.availableCaregivers = payload;
        state.loading.availableCaregivers = false;
    })
    .addCase(actions.setNotReviewedCaregivers, (state, { payload }) => {
        state.notReviewedCaregivers = payload;
        state.loading.notReviewedCaregivers = false;
    })
    .addCase(actions.setRelativesInNeed, (state, { payload }) => {
        state.relativesInNeed = payload;
        state.loading.relativesInNeed = false;
    })
    .addCase(actions.setContractDurationGroups, (state, { payload }) => {
        state.contractDurationGroups = payload;
        state.loading.contractDurationGroups = false;
    })
    .addCase(actions.setCaregiversRegisteredLastWeek, (state, { payload }) => {
        state.caregiversRegisteredLastWeek = payload;
        state.loading.caregiversRegisteredLastWeek = false;
    })
    .addCase(actions.setRelativesRegisteredLastWeek, (state, { payload }) => {
        state.relativesRegisteredLastWeek = payload;
        state.loading.relativesRegisteredLastWeek = false;
    }));

export default reducer;
