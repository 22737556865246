import { CREATE_FEATURE_FLAG } from 'constants/dialog';
import { withProps } from 'libraries/components';
import { openDialog } from 'models/ui';
import { useFab } from 'components/fab';

const Fab = ({ openDialog }) => {
    useFab({
        fabProps: {
            onClick: () => openDialog({
                title: 'Add Feature',
                type: CREATE_FEATURE_FLAG,
            }),
        },
    });

    return <></>;
};

export default withProps({
    openDialog,
})(Fab);
