import { AVAILABLE_SERVICES } from 'constants/relatives';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormattedMessage } from 'react-intl';

const ServicesNeededCustomFilter = ({ item, applyValue }) => {
    const handleFilterChange = (event, newValue) => {
        applyValue({ ...item, value: newValue });
    };
    return (
        <FormControl fullWidth>
            <InputLabel id="servicesNeeded">
                <FormattedMessage
                    id="services_needed"
                    defaultMessage="services_needed"
                />
            </InputLabel>
            <Select
                value={item.value?.props?.value}
                label="Services Needed"
                onChange={handleFilterChange}
            >
                {AVAILABLE_SERVICES.map(
                    (service) => (
                        <MenuItem value={service} key={service}>
                            <FormattedMessage id={service} defaultMessage={service} />
                        </MenuItem>
                    ),
                )}
            </Select>
        </FormControl>
    );
};
export default ServicesNeededCustomFilter;
