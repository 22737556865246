import {
    LIST_VIEW,
    GRID_VIEW,
} from 'constants/caregivers';
import { compose } from 'redux';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {
    List as ListIcon,
    GridOn as GridIcon,
} from '@mui/icons-material';

import { withProps } from 'libraries/components';
import {
    changeCaregiversView,
    view,
} from 'models/caregivers';

const ViewSelector = ({
    changeCaregiversView,
    view,
}) => {
    const handleAlignment = (event, newView) => {
        if (newView !== null) {
            changeCaregiversView(newView);
        }
    };

    return (
        <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleAlignment}
            aria-label="change view"
            size="small"
        >
            <ToggleButton value={LIST_VIEW} aria-label="list view">
                <ListIcon />
            </ToggleButton>
            <ToggleButton value={GRID_VIEW} aria-label="grid view">
                <GridIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default compose(
    withProps({
        changeCaregiversView,
        view,
    }),
)(ViewSelector);
