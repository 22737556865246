import { useState } from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import {
    Popover,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Box,
} from '@mui/material';

import HistoryIcon from '@mui/icons-material/History';
import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import { recentlyViewedRelatives, recentlyViewedCaregivers } from 'models/app';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

const RecentlyViewed = ({
    classes,
    recentlyViewedRelatives,
    recentlyViewedCaregivers,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton
                size="large"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
            >
                <HistoryIcon className={classes.icon} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Typography variant="h6" sx={{ p: 2 }}>
                    <FormattedMessage
                        defaultMessage="Recently Viewed"
                        id="recently_viewed"
                    />
                </Typography>
                <Box className={classes.popover}>
                    <Box className={classes.listContainer}>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                defaultMessage="relatives"
                                id="relatives"
                            />
                        </Typography>
                        <List>
                            {recentlyViewedRelatives.map((relative) => (
                                <ListItem key={relative.id} disablePadding>
                                    <ListItemButton
                                        component={Link}
                                        to={`/relatives/${relative.id}`}
                                    >
                                        <ListItemText
                                            primary={`${relative.firstName} ${relative.lastName}`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box className={classes.listContainer}>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                defaultMessage="caregivers"
                                id="caregivers"
                            />
                        </Typography>
                        <List>
                            {recentlyViewedCaregivers.map((caregiver) => (
                                <ListItem key={caregiver.id} disablePadding>
                                    <ListItemButton
                                        component={Link}
                                        to={`/caregivers/${caregiver.id}`}
                                    >
                                        <ListItemText
                                            primary={`${caregiver.firstName} ${caregiver.lastName}`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Popover>
        </div>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        recentlyViewedRelatives,
        recentlyViewedCaregivers,
    }),
)(RecentlyViewed);
