import { SEND_INVOICE } from 'constants/dialog';
import {
    Box,
    Button,
    InputAdornment,
    TextField,
    Typography,
    IconButton, Tooltip,
} from '@mui/material';
import { RequestPage as RequestPageIcon } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { openDialog } from 'models/ui';
import { withProps } from 'libraries/components';
import { FormattedMessage } from 'react-intl';

const NotPayed = ({
    control,
    plan,
    amountName,
    invoiceSentOnName,
    payedOnName,
    invoiceName,
    isPayedName,
    canSendInvoice,
    openDialog,
}) => (
    <>
        <Box display="flex">
            <Typography variant="h4" flexGrow={1}>
                €
                {plan?.[amountName]}
                <Typography
                    variant="caption"
                    sx={{
                        color: typeof plan?.[invoiceSentOnName] === 'string'
                            ? 'error.main'
                            : 'info.main',
                    }}
                >
                    {typeof plan?.[invoiceSentOnName] === 'string'
                        ? <FormattedMessage defaultMessage="Not payed" id="not_payed" />
                        : <FormattedMessage defaultMessage="Invoiced" id="invoiced" /> }
                </Typography>
            </Typography>
            <Tooltip title={canSendInvoice ? 'Send Invoice' : 'You have unsaved changes!'}>
                <div>
                    <IconButton
                        disabled={!canSendInvoice}
                        onClick={() => openDialog({
                            title: `Send €${plan?.[amountName]} Invoice`,
                            type: SEND_INVOICE,
                            showXButton: true,
                            props: {
                                plan,
                                invoiceSentOnName,
                                amountName,
                            },
                        })}
                    >
                        <RequestPageIcon />
                    </IconButton>
                </div>
            </Tooltip>
        </Box>
        <Box sx={{ mt: 2 }} />
        <Controller
            render={({ field: { value, onChange } }) => (
                <DesktopDatePicker
                    label={(
                        <FormattedMessage
                            defaultMessage="Invoiced on"
                            id="invoiced_on"
                        />
                    )}
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            )}
            name={`plan.${invoiceSentOnName}`}
            control={control}
        />
        <Box sx={{ mt: 2 }} />
        <Controller
            render={({ field: { value, onChange } }) => (
                <DesktopDatePicker
                    label={(
                        <FormattedMessage
                            defaultMessage="Payed on"
                            id="payed_on"
                        />
                    )}
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    componentsProps={{
                        toolbar: {
                            hidden: false,
                        },
                    }}
                />
            )}
            name={payedOnName}
            control={control}
        />
        <Box sx={{ mt: 2 }} />
        <Controller
            render={({ field: { value, onChange } }) => (
                <TextField
                    label={(
                        <FormattedMessage
                            defaultMessage="Invoice no"
                            id="invoice_no"
                        />
                    )}
                    value={value}
                    onChange={onChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">#</InputAdornment>,
                    }}
                    variant="outlined"
                    fullWidth
                    type="number"
                />
            )}
            name={invoiceName}
            control={control}
        />
        <Box sx={{ mt: 2 }} />
        <Controller
            render={({ field: { onChange } }) => (
                <Button variant="contained" color="primary" onClick={() => onChange(true)}>
                    <FormattedMessage
                        defaultMessage="Payment Accepted"
                        id="payment_accepted"
                    />
                </Button>
            )}
            name={isPayedName}
            control={control}
        />
    </>
);

export default withProps({ openDialog })(NotPayed);
