import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const FormTextField = ({
    control,
    name,
    label,
    controllerProps,
    inputProps,
    required = false,
}) => (
    <Controller
        name={name}
        control={control}
        rules={{
            required,
        }}
        render={({ field, formState }) => (
            <TextField
                {...field}
                label={label}
                variant="outlined"
                fullWidth
                required={required}
                error={formState.errors?.[name]?.message}
                helperText={formState.errors?.[name]?.message}
                {...inputProps}
            />
        )}
        {...controllerProps}
    />
);

export default FormTextField;
