import { ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';

import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import { withProps } from 'libraries/components';
import { searchFilters, toggleSearchFilters } from 'models/app';

const SearchFilters = ({ searchFilters, toggleSearchFilters }) => (
    <ToggleButtonGroup
        value={searchFilters}
        onChange={(_, newFilters) => toggleSearchFilters(newFilters)}
        aria-label="Search Filters"
        size="small"
    >
        <ToggleButton value="active" aria-label="bold">
            <Tooltip title="Only active">
                <ToggleOnIcon />
            </Tooltip>
        </ToggleButton>
        <ToggleButton value="available" aria-label="italic">
            <Tooltip title="Only available">
                <EventAvailableIcon />
            </Tooltip>
        </ToggleButton>
    </ToggleButtonGroup>
);

export default withProps({
    searchFilters,
    toggleSearchFilters,
})(SearchFilters);
