import { actionCreator, effectActionCreator } from 'libraries/system';

export const setRelativesPointerDocs = actionCreator('SET_RELATIVES_POINTER_DOCS');
export const setRelativesItemsPerPage = actionCreator('SET_RELATIVES_ITEMS_PER_PAGE');
export const setRelativeItemsPerPage = actionCreator('SET_RELATIVE_ITEMS_PER_PAGE');
export const pinRelative = actionCreator('PIN_RELATIVE');
export const unpinRelative = actionCreator('UNPIN_RELATIVE');
export const unpinAllRelatives = actionCreator('UNPIN_ALL_RELATIVES');
export const setTotalRelatives = actionCreator('SET_TOTAL_RELATIVES');

export const loadRelatives = effectActionCreator('LOAD_RELATIVES');
export const setCurrentRelativesPage = effectActionCreator('SET_CURRENT_RELATIVES_PAGE');
export const expandRelativeProfile = effectActionCreator('EXPAND_RELATIVE_PROFILE');
export const updateRelativeInfo = effectActionCreator('UPDATE_RELATIVE_INFO');
export const sendInvoiceToRelative = effectActionCreator('SEND_INVOICE_TO_RELATIVE');
export const addNewRelative = effectActionCreator('ADD_NEW_RELATIVE');
