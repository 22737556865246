import { theme } from '@grandmama/ui-theme';
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        ...theme.palette,
        mode: 'dark',
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: theme.palette.primary.main,
                        borderLeft: `3px solid ${theme.palette.primary.main}`,
                        backgroundColor: 'unset',
                        paddingLeft: 13,
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 1,
            },
        },
    },
});

export default darkTheme;
