import {
    DEMENTIA_OPTIONS, MOVEMENT_OPTIONS, OTHER_HEALTH_ISSUES_OPTIONS, GENERAL_OPTIONS, SLEEP_OPTIONS,
} from 'constants/relatives';
import {
    capitalize, Grid, TextField, InputAdornment,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
    useFormContext, Controller, useWatch,
} from 'react-hook-form';
import { subYears, differenceInYears } from 'date-fns';
import SectionPaper from 'components/sectionPaper';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
    Checkboxes, FormRadioButtons, FormSelect,
} from 'components';

import { FormattedMessage } from 'react-intl';
import styles from './styles';

const PatientInfoPane = ({ classes }) => {
    const { control, setValue, watch } = useFormContext();
    const hasHealthIssues = useWatch({
        control, name: 'hasHealthIssues',
    });
    const dementiaOther = useWatch({
        control, name: 'dementia',
    });

    const dob = watch('dateOfBirth');

    return (
        <SectionPaper
            className={classes.section}
            title={<FormattedMessage defaultMessage="Patient Info" id="patient_info" />}
        >
            <Controller
                freeSolo
                render={({ field }) => (
                    <FormSelect
                        {...field}
                        options={['father', 'mother', 'brother', 'spouse', 'sister', 'uncle', 'aunt', 'grandfather', 'grandmother', 'themselves', 'other']}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                        label={(
                            <FormattedMessage
                                defaultMessage="Finding care for"
                                id="finding_care_for"
                            />
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Finding care for"
                                        id="finding_care_for"
                                    />
                                )}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                    />
                )}
                name="careFor"
                control={control}
            />
            <div className={classes.spacer} />
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={(
                                    <FormattedMessage
                                        defaultMessage={'Patient\'s First Name'}
                                        id="patients_first_name"
                                    />
                                )}
                                fullWidth
                                variant="outlined"
                            />
                        )}
                        name="patientFirstName"
                        control={control}
                    />
                </Grid>
                <Grid item md={6}>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={(
                                    <FormattedMessage
                                        defaultMessage={'Patient\'s Last Name'}
                                        id="patients_last_name"
                                    />
                                )}
                                fullWidth
                                variant="outlined"
                            />
                        )}
                        name="patientLastName"
                        control={control}
                    />
                </Grid>
                <Grid item md={12}>
                    <FormSelect
                        name="livesWith"
                        control={control}
                        label={(
                            <FormattedMessage
                                defaultMessage="Lives with"
                                id="lives_with"
                            />
                        )}
                        options={['spouse', 'children', 'alone', 'other']}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={3}>
                    <FormRadioButtons
                        control={control}
                        name="sex"
                        label={(
                            <FormattedMessage
                                defaultMessage="Sex"
                                id="sex"
                            />
                        )}
                        options={['male', 'female']}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                        radioGroupProps={{
                            style: { flexDirection: 'row' },
                        }}
                    />
                </Grid>
                <Grid item md={3}>
                    <Controller
                        render={({ field: { onChange } }) => (
                            <DesktopDatePicker
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Date of birth"
                                        id="date_of_birth"
                                    />
                                )}
                                value={dob}
                                onChange={onChange}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                    />
                                )}
                                openTo="year"
                            />
                        )}
                        name="dateOfBirth"
                        control={control}
                    />
                </Grid>
                <Grid item md={3}>
                    <TextField
                        label={(
                            <FormattedMessage
                                defaultMessage="Age"
                                id="age"
                            />
                        )}
                        value={dob ? differenceInYears(new Date(), new Date(Number(dob))) : ''}
                        onChange={({ target }) => setValue('dateOfBirth', target?.value > 0 ? subYears(new Date(), Number(target?.value)) : '', { shouldDirty: true })}
                        type="number"
                        InputProps={{
                            endAdornment:
    <InputAdornment position="end">
        <FormattedMessage id="years_old" defaultMessage="Years old" />
    </InputAdornment>,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item md={3}>
                    <Controller
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Weight"
                                        id="weight"
                                    />
                                )}
                                value={value}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                }}
                                variant="outlined"
                                fullWidth
                                type="number"
                            />
                        )}
                        name="patientWeight"
                        control={control}
                    />
                </Grid>
                <Grid item md={12}>
                    <Checkboxes
                        control={control}
                        setValue={setValue}
                        name="hasHealthIssues"
                        label={(
                            <FormattedMessage
                                defaultMessage="Has Health Issues"
                                id="has_health_issues"
                            />
                        )}
                        data={['has_health_issues'].map((item) => ({
                            label: <FormattedMessage
                                defaultMessage={capitalize(item)}
                                id={item}
                            />,
                            value: item,
                        }))}
                    />
                </Grid>
                {hasHealthIssues?.includes('has_health_issues') && (
                    <>
                        <Grid item md={12}>
                            <Checkboxes
                                control={control}
                                setValue={setValue}
                                name="dementia"
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Dementia"
                                        id="dementia"
                                    />
                                )}
                                data={DEMENTIA_OPTIONS.map((item) => ({
                                    label: <FormattedMessage
                                        defaultMessage={capitalize(item)}
                                        id={item}
                                    />,
                                    value: item,
                                }))}
                            />
                            {dementiaOther?.includes('other') && (
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="Dementia Notes"
                                                    id="dementia_notes"
                                                />
                                            )}
                                            multiline
                                            fullWidth
                                            rows={4}
                                            variant="outlined"
                                        />
                                    )}
                                    name="dementiaNotes"
                                    control={control}
                                />
                            )}
                            <div className={classes.spacer} />
                            <Checkboxes
                                control={control}
                                setValue={setValue}
                                name="movement"
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Movement"
                                        id="movement"
                                    />
                                )}
                                data={MOVEMENT_OPTIONS.map((item) => ({
                                    label: <FormattedMessage
                                        defaultMessage={capitalize(item)}
                                        id={item}
                                    />,
                                    value: item,
                                }))}
                            />
                            <FormRadioButtons
                                control={control}
                                name="movementEffort"
                                label={(
                                    <FormattedMessage
                                        defaultMessage={'Patient\'s movement effort'}
                                        id="patients_movement_effort"
                                    />
                                )}
                                options={['they_help_a_lot', 'they_help_a_little', 'they_dont_help_at_all_weight_lifting']}
                                getOptionLabel={(option) => (
                                    <FormattedMessage
                                        defaultMessage={capitalize(option)}
                                        id={option}
                                    />
                                )}
                                radioGroupProps={{
                                    style: { flexDirection: 'row' },
                                }}
                            />
                            <FormSelect
                                control={control}
                                name="otherIssues"
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Has Other Health Issues"
                                        id="other_health_issues"
                                    />
                                )}
                                getOptionLabel={(option) => (
                                    <FormattedMessage
                                        defaultMessage={capitalize(option)}
                                        id={option}
                                    />
                                )}
                                options={OTHER_HEALTH_ISSUES_OPTIONS}
                            />
                            <div className={classes.spacer} />
                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={(
                                            <FormattedMessage
                                                defaultMessage="Health Notes"
                                                id="health_notes"
                                            />
                                        )}
                                        multiline
                                        fullWidth
                                        rows={4}
                                        variant="outlined"
                                    />
                                )}
                                name="healthNotes"
                                control={control}
                            />
                        </Grid>
                    </>
                )}
                <Grid item>
                    <Checkboxes
                        control={control}
                        setValue={setValue}
                        name="general"
                        label={(
                            <FormattedMessage
                                defaultMessage="General"
                                id="general"
                            />
                        )}
                        data={GENERAL_OPTIONS.map((item) => ({
                            label: <FormattedMessage
                                defaultMessage={capitalize(item)}
                                id={item}
                            />,
                            value: item,
                        }))}
                    />
                </Grid>
                <Grid item>
                    <FormRadioButtons
                        control={control}
                        name="patientSleep"
                        label={(
                            <FormattedMessage
                                defaultMessage="Sleep"
                                id="sleep"
                            />
                        )}
                        options={SLEEP_OPTIONS}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                        radioGroupProps={{
                            style: { flexDirection: 'row' },
                        }}
                    />
                </Grid>
                <Grid item md={12}>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Other Needs"
                                        id="other_needs"
                                    />
                                )}
                                multiline
                                fullWidth
                                rows={4}
                                variant="outlined"
                            />
                        )}
                        name="otherNeeds"
                        control={control}
                    />
                </Grid>
            </Grid>
        </SectionPaper>
    );
};

export default withStyles(styles)(PatientInfoPane);
