const styles = {
    textField: {
        'div:first-of-type': {
            width: '100%',
        },
    },
    responsiveMapContainer: {
        height: 400,
        width: '100%',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        padding: 0,
    },
    innerMapContainer: {
        width: '100%',
        marginLeft: 0,
    },
};

export default styles;
