import {
    CREATE_FEATURE_FLAG,
    UPDATE_FEATURE_FLAG,
    MANAGE_USERS,
    NEW_CAREGIVER,
    SETTINGS,
    NEW_RELATIVE,
    SEND_INVOICE,
} from 'constants/dialog';
import { compose } from 'redux';
import {
    Dialog as MuiDialog,
    DialogTitle,
    Grid,
    Typography,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { withProps } from 'libraries/components';
import { closeDialog, dialog } from 'models/ui';
import { FormattedMessage } from 'react-intl';
import {
    UpdateFeatureFlag,
    CreateFeatureFlag,
    ManageUsers,
    NewCaregiver,
    Settings,
    NewRelative,
    SendInvoice,
} from './components';
import { withStyles } from './styles';

const Dialog = ({ classes, closeDialog, dialog }) => {
    const handleClose = () => {
        dialog?.onClose?.();
        closeDialog();
    };

    const Component = {
        [CREATE_FEATURE_FLAG]: CreateFeatureFlag,
        [UPDATE_FEATURE_FLAG]: UpdateFeatureFlag,
        [MANAGE_USERS]: ManageUsers,
        [NEW_CAREGIVER]: NewCaregiver,
        [SETTINGS]: Settings,
        [NEW_RELATIVE]: NewRelative,
        [SEND_INVOICE]: SendInvoice,
    }[dialog.type] || (() => null);

    return (
        <MuiDialog
            open={dialog.isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            {...dialog.dialogProps}
        >
            <Grid
                container
                direction="column"
                className={classes.container}
            >
                <DialogTitle>
                    {dialog.title}
                    {dialog.showXButton && (
                        <IconButton
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: '#000',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </DialogTitle>
                {dialog.description && (
                    <Typography variant="body1" gutterBottom>
                        <FormattedMessage
                            id={dialog.description}
                            defaultMessage={dialog.description}
                        />
                    </Typography>
                )}

                <Component
                    props={dialog.props}
                    handleClose={handleClose}
                />
            </Grid>
        </MuiDialog>
    );
};

export default compose(
    withStyles,
    withProps({ dialog, closeDialog }),
)(Dialog);
