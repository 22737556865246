const styles = (theme) => ({
    root: {
        margin: '10em auto',
        width: '30em',
    },
    logo: {
        width: '30em',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(3),
    },
    button: {
        marginTop: theme.spacing(4),
    },
    icon: {
        width: '1em',
    },
});

export default styles;
