import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initState = {
    users: [],
    permissions: [],
    updating: false,
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.loadUsers.succeeded, (state, { payload }) => {
        state.users = payload;
    })
    .addCase(actions.loadPermissions.succeeded, (state, { payload }) => {
        state.permissions = Object.values(payload);
    })
    .addCase(actions.updateUser, (state) => {
        state.updating = true;
    })
    .addCase(actions.updateUser.succeeded, (state) => {
        state.updating = false;
    })
    .addCase(actions.updateUser.failed, (state) => {
        state.updating = false;
    }));

export default reducer;
