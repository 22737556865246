import { NEW_CAREGIVER } from 'constants/dialog';
import { GRID_VIEW } from 'constants/caregivers';
import { useEffect } from 'react';
import { compose } from 'redux';

import { useFab } from 'components/fab';
import { withProps } from 'libraries/components';
import {
    caregivers,
    loadCaregivers,
    view,
    pinnedCaregivers,
} from 'models/caregivers';
import { openDialog } from 'models/ui';

import { FormattedMessage } from 'react-intl';
import {
    Toolbar,
    GridView,
    ListView,
} from './components';

const Caregivers = ({
    caregivers,
    loadCaregivers,
    view,
    openDialog,
}) => {
    useEffect(() => {
        caregivers.length === 0 && loadCaregivers();
    }, []);

    useFab({
        fabProps: {
            onClick: () => openDialog({
                title: <FormattedMessage defaultMessage="Add Caregiver" id="add_caregiver" />,
                type: NEW_CAREGIVER,
            }),
        },
    });

    return (
        <>
            <Toolbar />
            {view === GRID_VIEW
                ? <GridView />
                : <ListView />}
        </>
    );
};

export default compose(
    withProps({
        caregivers,
        loadCaregivers,
        view,
        pinnedCaregivers,
        openDialog,
    }),
)(Caregivers);
