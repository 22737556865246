import { featureFlagService } from 'services/featureFlagService';

export const getFeatureFlagsRequest = () => featureFlagService.getAll();

export const createFeatureFlagRequest = (data) => featureFlagService.create(data);

export const deleteFeatureFlagRequest = ({ payload }) => featureFlagService.delete({ id: payload });

export const updateFeatureFlagRequest = ({
    payload: { id, data },
}) => featureFlagService.update({ id, data });
