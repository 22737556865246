import withStyles from '@mui/styles/withStyles';

const styles = withStyles(() => ({
    fullScreen: {
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
    },
    container: {
    },
}));

export {
    styles as withStyles,
};
