import withStyles from '@mui/styles/withStyles';

import { ViewSelector } from './components';
import styles from './styles';

const Toolbar = ({ classes }) => (
    <div className={classes.root}>
        <div className={classes.spacer} />
        <ViewSelector />
    </div>
);

export default withStyles(styles)(Toolbar);
