import {
    ListItem as MuiListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
    Link, matchPath, useLocation,
} from 'react-router-dom';

import { withProps } from 'libraries/components';
import { drawerOpen } from 'models/ui';

const disabledProps = {
    disableFocusListener: true,
    disableHoverListener: true,
    disableTouchListener: true,
};

const getItemProps = (props) => ({
    ...(props.path ? {
        component: Link,
        to: {
            pathname: props.path,
            state: {
                route: props,
            },
        },
    } : {
        href: props.link,
    }),
});

const ListItem = ({
    icon: Icon,
    title,
    subtitle,
    external,
    hideTooltip,
    drawerOpen,
    ...props
}) => {
    const location = useLocation();

    const selected = !!matchPath(
        { path: props.path },
        location.pathname,
    );

    return (
        <Tooltip
            title={title}
            placement="right"
            {...(hideTooltip || drawerOpen ? disabledProps : {})}
        >
            <MuiListItem
                button
                selected={selected}
                {...getItemProps({
                    title,
                    subtitle,
                    ...props,
                })}
            >
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id={title} />} secondary={subtitle} />
                {external && <OpenInNewIcon />}
            </MuiListItem>
        </Tooltip>
    );
};
export default withProps({ drawerOpen })(ListItem);
