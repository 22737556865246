import { useEffect } from 'react';
import { compose } from 'redux';

import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import { fetchDashboardData } from 'models/dashboard';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { Grid } from './components';
import styles from './styles';

const Dashboard = ({
    classes,
    fetchDashboardData,
}) => {
    useEffect(() => {
        fetchDashboardData();
    }, []);

    return (
        <div className={classes.container}>
            <Grid />
        </div>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        fetchDashboardData,
    }),
)(Dashboard);
