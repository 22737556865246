import { Observable } from 'rxjs';

import { newSMS } from '@grandmama/sdk-sms';
import env from 'env';

const smsService = newSMS({
    env: 'prod',
    apiKey: env.smsAPIKey,
});

const SendSMS$ = (phoneNumber, message) => new Observable((observer) => {
    smsService.sendSMS({
        phones: phoneNumber,
        text: message,
    })
        .then((response) => observer.next(response))
        .catch((err) => observer.error(err))
        .finally(() => observer.complete());
});

export default SendSMS$;
