import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
    Close as CloseIcon,
    Done as DoneIcon,
} from '@mui/icons-material';

import styles from './styles';

const FormListItem = ({
    classes,
    input: {
        onChange,
        value,
    },
    icon,
    label,
    ...rest
}) => (
    <ListItem
        className={classes.link}
        onClick={() => onChange(!value)}
        button
        {...rest}
    >
        <ListItemIcon>
            {icon}
        </ListItemIcon>
        <ListItemText primary={label} />
        {value
            ? <DoneIcon className={classes.green} />
            : <CloseIcon className={classes.red} />}
    </ListItem>
);

export default withStyles(styles)(FormListItem);
