import { Controller, useFormContext } from 'react-hook-form';

import {
    TextField,
    Grid,
    InputAdornment,
    Chip,
    Collapse,
} from '@mui/material';
import { PhoneInput } from '@grandmama/ui-phone-input';
import { FormTextField } from 'components';
import { FormattedMessage } from 'react-intl';

const commonEmails = ['@gmail.com', '@yahoo.com', '@hotmail.com'];

const PersonalDetails = () => {
    const { control } = useFormContext();

    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item md={6}>
                        <FormTextField
                            name="firstName"
                            control={control}
                            label={<FormattedMessage defaultMessage="First Name" id="first_name" />}
                            required
                        />
                    </Grid>
                    <Grid item md={6}>
                        <FormTextField
                            name="lastName"
                            control={control}
                            label={<FormattedMessage defaultMessage="Last Name" id="last_name" />}
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12}>
                <Controller
                    name="email"
                    control={control}
                    render={({ field, formState }) => (
                        <TextField
                            {...field}
                            label="Email"
                            variant="outlined"
                            fullWidth
                            error={formState.errors?.email?.message}
                            helperText={formState.errors?.email?.message}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Collapse orientation="horizontal" in={!field?.value?.includes('@')}>
                                            {commonEmails.map((email) => (
                                                <Chip
                                                    sx={{
                                                        mr: 1,
                                                    }}
                                                    key={email}
                                                    label={email}
                                                    size="small"
                                                    onClick={() => {
                                                        field.onChange(`${field.value}${email}`);
                                                    }}
                                                />
                                            ))}
                                        </Collapse>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item md={12}>
                <Controller
                    name="email2"
                    control={control}
                    render={({ field, formState }) => (
                        <TextField
                            {...field}
                            label="Email 2"
                            variant="outlined"
                            fullWidth
                            error={formState.errors?.email2?.message}
                            helperText={formState.errors?.email2?.message}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Collapse orientation="horizontal" in={!field?.value?.includes('@')}>
                                            {commonEmails.map((email2) => (
                                                <Chip
                                                    sx={{
                                                        mr: 1,
                                                    }}
                                                    key={email2}
                                                    label={email2}
                                                    size="small"
                                                    onClick={() => {
                                                        field.onChange(`${field.value}${email2}`);
                                                    }}
                                                />
                                            ))}
                                        </Collapse>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item md={6}>
                <Controller
                    name="mobilePhone"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <PhoneInput
                            {...field}
                            label={(
                                <FormattedMessage
                                    defaultMessage="Mobile Number"
                                    id="mobile_number"
                                />
                            )}
                            fullWidth
                            error={error?.message}
                            helperText={error?.message}
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            <Grid item md={6}>
                <Controller
                    name="homePhone"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <PhoneInput
                            {...field}
                            label={(
                                <FormattedMessage
                                    defaultMessage="Home Number"
                                    id="home_number"
                                />
                            )}
                            fullWidth
                            error={error?.message}
                            helperText={error?.message}
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default PersonalDetails;
