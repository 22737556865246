import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initState = {
    relatives: [],
    pinnedRelatives: [],
    totalRelatives: 0,
    loadingRelatives: false,
    expandedRelativeProfile: null,
    expandedRelativeLoading: false,
    sendingInvoiceToRelative: false,
    newRelativeLoading: false,
    pagination: {
        currentPage: 0,
        itemsPerPage: 100,
        pointers: {
            first: null,
            last: null,
        },
    },
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.loadRelatives.succeeded, (state, { payload }) => {
        state.relatives = payload;
        state.loadingRelatives = false;
    })
    .addCase(actions.loadRelatives, (state) => {
        state.loadingRelatives = true;
    })
    .addCase(actions.setTotalRelatives, (state, { payload }) => {
        state.totalRelatives = payload;
    })
    .addCase(actions.setCurrentRelativesPage, (state, { payload }) => {
        state.pagination.currentPage = payload.page;
        state.pagination.itemsPerPage = payload.pageSize;
        state.loadingRelatives = true;
    })
    .addCase(actions.setRelativesPointerDocs, (state, { payload }) => {
        state.pagination.pointers = payload;
    })
    .addCase(actions.setRelativeItemsPerPage, (state, { payload }) => {
        state.pagination.itemsPerPage = payload;
        state.loadingRelatives = true;
    })
    .addCase(actions.pinRelative, (state, { payload }) => {
        state.pinnedRelatives.push(payload);
    })
    .addCase(actions.unpinRelative, (state, { payload }) => {
        const index = state
            .pinnedRelatives
            .findIndex((relative) => relative.id === payload);
        if (index !== -1) state.pinnedRelatives.splice(index, 1);
    })
    .addCase(actions.unpinAllRelatives, (state) => {
        state.pinnedRelatives = [];
    })
    .addCase(actions.expandRelativeProfile, (state) => {
        state.expandedRelativeLoading = true;
    })
    .addCase(actions.expandRelativeProfile.succeeded, (state, { payload }) => {
        state.expandedRelativeProfile = payload;
        state.expandedRelativeLoading = false;
    })
    .addCase(actions.sendInvoiceToRelative, (state) => {
        state.sendingInvoiceToRelative = true;
    })
    .addCase(actions.sendInvoiceToRelative.succeeded, (state) => {
        state.sendingInvoiceToRelative = false;
    })
    .addCase(actions.sendInvoiceToRelative.failed, (state) => {
        state.sendingInvoiceToRelative = false;
    }));

export default reducer;
