import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { withProps } from 'libraries/components';
import {
    expandedRelativeProfile,
    expandedRelativeLoading,
    expandRelativeProfile, sendingInvoiceToRelative,
} from 'models/relatives';

import {
    Layout,
    PersonInfoPane,
    AccountInfoPane,
    PatientInfoPane,
    ServicesNeededPane,
    EnvironmentPane,
    CompensationPane,
    NotesPane,
    RecommendedCaregiversPane,
    EmployedCaregiversPane,
    PlanInfo,
    Address,
    StatusPane,
} from './Components';

const RelativeProfile = ({
    expandedRelativeProfile,
    expandedRelativeLoading,
    expandRelativeProfile,
    sendingInvoiceToRelative,
}) => {
    const { id } = useParams();

    useEffect(() => {
        expandedRelativeProfile?.id !== id && expandRelativeProfile(id);
    }, [id]);

    return (expandedRelativeLoading || sendingInvoiceToRelative
        ? (
            'Loading...'
        ) : (
            <Layout
                status={StatusPane}
                accountInfo={AccountInfoPane}
                personInfo={PersonInfoPane}
                patientInfo={PatientInfoPane}
                servicesNeeded={ServicesNeededPane}
                environment={EnvironmentPane}
                compensation={CompensationPane}
                recommendedCaregivers={RecommendedCaregiversPane}
                employedCaregivers={EmployedCaregiversPane}
                notes={NotesPane}
                planInfo={PlanInfo}
                address={Address}
            />
        ));
};

export default withProps({
    expandedRelativeProfile,
    expandedRelativeLoading,
    expandRelativeProfile,
    sendingInvoiceToRelative,
})(RelativeProfile);
