const caregiverConfig = {
    apiKey: 'AIzaSyC5ZRSk9aVnk3ysyGkt1hiWAtwGslk2HWA',
    authDomain: 'grandmama-caregiver-app.firebaseapp.com',
    projectId: 'grandmama-caregiver-app',
    storageBucket: 'grandmama-caregiver-app.appspot.com',
    messagingSenderId: '663804241287',
    appId: '1:663804241287:web:7b0499b1a5cee98a6a9f22',
};

export default caregiverConfig;
