import { useForm, FormProvider } from 'react-hook-form';
import Box from '@mui/material/Box';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { withProps } from 'libraries/components';
import { addNewRelative } from 'models/relatives';

import { FormattedMessage } from 'react-intl';
import {
    Stepper,
    PersonalDetails,
    PatientDetails,
    PatientAddress,
    ServicesNeeded,
} from './components';

import styles from './styles';

const steps = [
    {
        label: <FormattedMessage defaultMessage="Personal Details" id="personal_details" />,
        component: PersonalDetails,
        isOptional: false,
    },
    {
        label: <FormattedMessage defaultMessage="Patient Details" id="patient_details" />,
        component: PatientDetails,
        isOptional: true,
    },
    {
        label: <FormattedMessage defaultMessage="Patient Address" id="patient_address" />,
        component: PatientAddress,
        isOptional: false,
    },
    {
        label: <FormattedMessage defaultMessage="Services" id="services" />,
        component: ServicesNeeded,
        isOptional: true,
    },
];

const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email(),
    email2: yup.string().email(),
    mobilePhone: yup.string().min(13).required(),
    homePhone: yup.string(),
}).required();

const NewRelative = ({ handleClose, addNewRelative }) => {
    const methods = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: {
            contractDurationDates: [null, null],
        },
    });

    const onSubmit = (values) => {
        addNewRelative(values);
        handleClose();
    };

    return (
        <Box sx={styles.container}>
            <FormProvider {...methods}>
                <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                    <Stepper
                        steps={steps}
                        hasErrors={!methods.formState.isValid}
                    />
                </form>
            </FormProvider>
        </Box>
    );
};

export default withProps({ addNewRelative })(NewRelative);
