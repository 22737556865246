const caregiverConfig = {
    apiKey: 'AIzaSyDatDq2qjUOsutAQEwA6ZtuzxhkpWiFkfE',
    authDomain: 'grandmama-site.firebaseapp.com',
    projectId: 'grandmama-site',
    storageBucket: 'grandmama-site.appspot.com',
    messagingSenderId: '690719296308',
    appId: '1:690719296308:web:dce9c42c5b3b4b3cbf7361',
    measurementId: 'G-HLXKFD95JC',
};

export default caregiverConfig;
