const styles = (theme) => ({
    root: {
        padding: theme.spacing(1),
        display: 'flex',
    },
    spacer: {
        flexGrow: 1,
    },
    selectedButton: {
        backgroundColor: theme.palette.primary.light,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    icon: {
        color: 'inherit',
    },
});

export default styles;
