import { from, Observable } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import {
    ignoreElements,
    map,
    mergeMap,
    tap,
} from 'rxjs/operators';

import {
    auth,
    googleAuthProvider,
    firebase,
    Document$,
} from 'services/firebaseDB';

import { caregiverFB } from 'services/caregivers';

import { relativesFB } from 'services/relatives';
import * as actions from './actions';

const checkForSignedInUser = () => from(
    new Observable((obs) => auth.onAuthStateChanged(
        (user) => obs.next(user),
        (err) => obs.error(err),
        () => obs.complete(),
    )),
).pipe(
    mergeMap((user) => {
        const emailDomain = user?.email.split('@').slice(-1)[0];
        const hasGrandmamaDomain = emailDomain === 'grandmama.gr';
        const userInfo = {
            email: user?.email,
            displayName: user?.displayName,
            uid: user?.uid,
        };

        return user && hasGrandmamaDomain
            ? [actions.authenticateUser.succeeded(userInfo), actions.getUserDetails(user?.uid)]
            : [actions.authenticateUser.failed()];
    }),
    // tap(() => {
    //     firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
    //         console.log('JWT ----   ', idToken);
    //     }).catch((error) => {
    //         console.log('JWT error ----   ', error);
    //     });
    // }),
);

const getUserDetailsEpic = (action$) => action$.pipe(
    ofType(actions.getUserDetails.type),
    mergeMap(({ payload: uid }) => Document$('users', uid)),
    map((userInfo) => actions.getUserDetails.succeeded(userInfo)),
);

const signInEpic = (action$) => action$.pipe(
    ofType(actions.signIn.type),
    tap(() => auth.signInWithPopup(googleAuthProvider).then((account) => {
        const credential = firebase.auth.GoogleAuthProvider.credential(account.credential.idToken);
        caregiverFB
            .auth()
            .signInWithCredential(credential)
            .catch((err) => console.log(err.message));
        relativesFB
            .auth()
            .signInWithCredential(credential)
            .catch((err) => console.log(err.message));
    })),
    ignoreElements(),
);

const signOutEpic = (action$) => action$.pipe(
    ofType(actions.signOut.type),
    tap(() => auth.signOut()),
    ignoreElements(),
);

export default combineEpics(
    checkForSignedInUser,
    signInEpic,
    signOutEpic,
    getUserDetailsEpic,
);
