import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

import firebaseConfig from './config';

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export {
    firebase,
    db,
    auth,
    googleAuthProvider,
};

export default firebase;
