import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    IconButton,
    TextField,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
    RateReview as RateReviewIcon,
    Apartment as ApartmentIcon,
    Phone as PhoneIcon,
    Room as RoomIcon,
    Mail as MailIcon,
    BusinessCenter as BusinessCenterIcon,
} from '@mui/icons-material';

import { useFormContext, Controller } from 'react-hook-form';

import FormRating from 'components/formRating';
import FormListItem from 'components/formListItem';

import { FormattedMessage } from 'react-intl';
import styles from './styles';

const InfoList = ({ classes, caregiver }) => {
    const { control } = useFormContext();

    return (
        <List>
            <ListItem>
                <Controller
                    name="rating"
                    control={control}
                    render={({ field }) => (
                        <FormRating className={classes.rating} input={field} />
                    )}
                />
            </ListItem>
            <Controller
                name="reviewed"
                control={control}
                render={({ field }) => (
                    <FormListItem
                        label={(
                            <FormattedMessage
                                defaultMessage="Reviewed"
                                id="reviewed"
                            />
                        )}
                        icon={<RateReviewIcon />}
                        input={field}
                    />
                )}
            />
            <Controller
                name="available"
                control={control}
                render={({ field }) => (
                    <FormListItem
                        label={(
                            <FormattedMessage
                                defaultMessage="Available"
                                id="available"
                            />
                        )}
                        icon={<BusinessCenterIcon />}
                        input={field}
                    />
                )}
            />
            <Controller
                name="employed"
                control={control}
                render={({ field }) => (
                    <FormListItem
                        label={(
                            <FormattedMessage
                                defaultMessage="Employed"
                                id="employed"
                            />
                        )}
                        icon={<ApartmentIcon />}
                        input={field}
                    />
                )}
            />
            <Divider />
            <ListItem className={classes.link}>
                <ListItemIcon>
                    <IconButton
                        className={classes.link}
                        size="small"
                        component="a"
                        href={`tel:${caregiver?.phoneNumber}`}
                    >
                        <PhoneIcon />
                    </IconButton>
                </ListItemIcon>
                <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={(
                                <FormattedMessage
                                    defaultMessage="phone_number"
                                    id="phone_number"
                                />
                            )}
                            variant="standard"
                            fullWidth
                        />
                    )}
                />
            </ListItem>
            <ListItem className={classes.link}>
                <ListItemIcon>
                    <IconButton
                        className={classes.link}
                        size="small"
                        component="a"
                        href={`mailto:${caregiver?.email}`}
                    >
                        <MailIcon />
                    </IconButton>
                </ListItemIcon>
                <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField {...field} label="Email" variant="standard" fullWidth />
                    )}
                />
            </ListItem>
            <ListItem className={classes.link}>
                <ListItemIcon>
                    <IconButton
                        className={classes.link}
                        size="small"
                        component="a"
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(caregiver?.address)}`}
                        target="_blank"
                    >
                        <RoomIcon />
                    </IconButton>
                </ListItemIcon>
                <Controller
                    name="address"
                    control={control}
                    rules={{ readOnly: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={(
                                <FormattedMessage
                                    defaultMessage="Address"
                                    id="address"
                                />
                            )}
                            variant="standard"
                            multiline
                            maxRows={4}
                            fullWidth
                            aria-readonly
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    )}
                />
            </ListItem>
            <Divider />
        </List>
    );
};

export default withStyles(styles)(InfoList);
