import { Controller } from 'react-hook-form';
import ToggleBox from './ToggleBox';

const FormToggleBox = ({
    label,
    name,
    control,
    negativeFromValue = () => false,
    positiveFromValue = () => false,
    icon,
}) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
            <ToggleBox
                label={label}
                onChange={onChange}
                value={value}
                negative={negativeFromValue(value)}
                positive={positiveFromValue(value)}
                icon={icon}
            />
        )}
    />
);

export default FormToggleBox;
