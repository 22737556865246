import clsx from 'clsx';
import { compose } from 'redux';

import {
    AppBar,
    Toolbar,
    IconButton,
    Tooltip,
    Slide,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { withProps } from 'libraries/components';
import { drawerOpen } from 'models/ui';
import { unpinAllCaregivers, pinnedCaregivers } from 'models/caregivers';
import { unpinAllRelatives, pinnedRelatives } from 'models/relatives';

import { Tabs } from './components';
import styles from './styles';

const BottomBar = ({
    classes,
    drawerOpen,
    unpinAllCaregivers,
    pinnedCaregivers,
    unpinAllRelatives,
    pinnedRelatives,
}) => (
    <Slide
        in={(pinnedCaregivers.length + pinnedRelatives.length) > 0}
        direction="up"
    >
        <AppBar
            position="fixed"
            color="default"
            elevation={0}
            className={clsx(classes.bottomBar, {
                [classes.bottomBarShift]: drawerOpen,
            })}
        >
            <Toolbar variant="dense">
                <Tooltip title="Clear all">
                    <IconButton
                        size="small"
                        onClick={() => {
                            unpinAllCaregivers();
                            unpinAllRelatives();
                        }}
                    >
                        <ClearAllIcon />
                    </IconButton>
                </Tooltip>
                <Tabs />
            </Toolbar>
        </AppBar>
    </Slide>
);

export default compose(
    withStyles(styles),
    withProps({
        drawerOpen,
        unpinAllCaregivers,
        pinnedCaregivers,
        unpinAllRelatives,
        pinnedRelatives,
    }),
)(BottomBar);
