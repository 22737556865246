import Rating from '@mui/material/Rating';

const FormRating = ({
    input: {
        name = 'rating',
        onChange = () => {},
        value = 0,
    },
    ...rest
}) => (
    <Rating
        value={+value}
        name={name}
        onChange={(_, value) => onChange(value)}
        {...rest}
    />
);

export default FormRating;
