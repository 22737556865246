import { datadogLogs } from '@datadog/browser-logs';

const datadogLogger = datadogLogs.logger;

export const logger = ({
    loggerId = 'website_logger',
}) => {
    const log = (level, message, meta) => {
        datadogLogger.log(message, meta, level);
    };
    const loggerMeta = { loggerId };

    return {
        info: (message, meta) => log('info', message, { ...loggerMeta, ...meta }),
        error: (message, meta) => log('error', message, { ...loggerMeta, ...meta }),
        warn: (message, meta) => log('warn', message, { ...loggerMeta, ...meta }),
    };
};

export const loggingMiddleware = (...types) => {
    const actionLogger = logger({ loggerId: 'action_logger' });

    return (store) => (next) => (action) => {
        if (types.includes(action.type)) {
            actionLogger[action.type.includes('FAILED') ? 'error' : 'info'](
                action.type,
                {
                    action,
                    state: store.getState(),
                },
            );
        } else if (action.type.includes('FAILED')) {
            actionLogger.error(action.type, {
                action,
                state: store.getState(),
            });
        }
        return next(action);
    };
};
