import { SETTINGS } from 'constants/dialog';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { openDialog, dialog } from 'models/ui';
import { withProps } from 'libraries/components';
import { FormattedMessage } from 'react-intl';

const Settings = ({ openDialog }) => {
    const navigate = useNavigate();

    useEffect(() => {
        openDialog({
            title: <FormattedMessage defaultMessage="Settings" id="settings" />,
            type: SETTINGS,
            dialogProps: {
                fullScreen: true,
            },
            showXButton: true,
            onClose: () => {
                navigate(-1);
            },
        });
    }, []);

    return null;
};

export default withProps({
    openDialog,
    dialog,
})(Settings);
