import { combineEpics, ofType } from 'redux-observable';
import { from } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
} from 'rxjs/operators';
import { groupBy } from 'lodash';

import { firebase, db as relativesDB } from 'services/relatives';
import { db as caregiversDB } from 'services/caregivers';
import env from 'env';

import { sendNotification } from '../notifications';
import * as actions from './actions';

const getStartOfToday = () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return firebase.firestore.Timestamp.fromDate(now);
};

const fetchNewRelativesEpic = (action$) => action$.pipe(
    ofType(actions.fetchDashboardData.type),
    mergeMap(() => relativesDB
        .collection(env.relativesCollection)
        .where('registerDate', '>=', getStartOfToday())
        .get()),
    map((snapshot) => snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
    }))),
    map((data) => actions.setRelatives(data)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.fetchDashboardData.failed(),
    ])),
);

const fetchNewCaregiversEpic = (action$) => action$.pipe(
    ofType(actions.fetchDashboardData.type),
    mergeMap(() => caregiversDB
        .collection(env.caregiversCollection)
        .where('registerDate', '>=', getStartOfToday())
        .get()),
    map((snapshot) => snapshot?.docs?.map((doc) => ({
        ...doc.data(),
        id: doc.id,
    }))),
    map((data) => actions.setCaregivers(data)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.fetchDashboardData.failed(),
    ])),
);

const fetchTotalCaregivers = (actions$) => actions$.pipe(
    ofType(actions.fetchDashboardData.type),
    mergeMap(() => caregiversDB
        .collection(env.caregiversCollection)
        .doc('length')
        .get()),
    map((snapshot) => snapshot.data().length),
    map((total) => actions.setTotalCaregivers(total)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.fetchDashboardData.failed(),
    ])),
);

const fetchTotalRelatives = (actions$) => actions$.pipe(
    ofType(actions.fetchDashboardData.type),
    mergeMap(() => relativesDB
        .collection(env.relativesCollection)
        .doc('length')
        .get()),
    map((snapshot) => snapshot.data().length),
    map((total) => actions.setTotalRelatives(total)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.fetchDashboardData.failed(),
    ])),
);

const fetchAvailableCaregiversEpic = (actions$) => actions$.pipe(
    ofType(actions.fetchDashboardData.type),
    mergeMap(() => caregiversDB
        .collection(env.caregiversCollection)
        .where('available', '==', true)
        .get()),
    map((snapshot) => snapshot.docs.length),
    map((total) => actions.setAvailableCaregivers(total)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.fetchDashboardData.failed(),
    ])),
);

const fetchNotReviewedCaregiversEpic = (actions$) => actions$.pipe(
    ofType(actions.fetchDashboardData.type),
    mergeMap(() => caregiversDB
        .collection(env.caregiversCollection)
        .where('reviewed', '!=', true)
        .get()),
    map((snapshot) => snapshot.docs.length),
    map((total) => actions.setNotReviewedCaregivers(total)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.fetchDashboardData.failed(),
    ])),
);

const fetchRelativesInNeedEpic = (actions$) => actions$.pipe(
    ofType(actions.fetchDashboardData.type),
    mergeMap(() => relativesDB
        .collection(env.relativesCollection)
        .where('hasCaregiver', '!=', true)
        .get()),
    map((snapshot) => snapshot?.docs?.length),
    map((total) => actions.setRelativesInNeed(total)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.fetchDashboardData.failed(),
    ])),
);

const fetchContractDurationGroupsEpic = (actions$) => actions$.pipe(
    ofType(actions.fetchDashboardData.type),
    mergeMap(() => relativesDB
        .collection(env.relativesCollection)
        .where('contractDuration', '!=', null)
        .get()),
    map((snapshot) => snapshot?.docs?.map((doc) => ({
        ...doc.data(),
        id: doc.id,
    }))),
    map((relatives) => {
        const groups = groupBy(relatives, 'contractDuration');

        return {
            permanent: groups.permanent?.length || 0,
            shortTerm: groups['short term']?.length || 0,
        };
    }),
    map((data) => actions.setContractDurationGroups(data)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.fetchDashboardData.failed(),
    ])),
);

const fetchCaregiversRegisteredLastWeekEpic = (actions$) => actions$.pipe(
    ofType(actions.fetchDashboardData.type),
    mergeMap(() => caregiversDB
        .collection(env.caregiversCollection)
        .where(
            'registerDate',
            '>',
            firebase.firestore.Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)),
        )
        .get()),
    map((snapshot) => snapshot?.docs?.map((doc) => ({
        ...doc.data(),
        id: doc.id,
    }))),
    map((caregivers) => caregivers.map(({ registerDate, ...caregiver }) => ({
        ...caregiver,
        registerDate: new Date(registerDate.toDate()).toLocaleDateString(),
    }))),
    map((caregivers) => groupBy(caregivers, 'registerDate')),
    map((dateGroups) => actions.setCaregiversRegisteredLastWeek(dateGroups)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.fetchDashboardData.failed(),
    ])),
);

export const fetchRelativesRegisteredLastWeekEpic = (actions$) => actions$.pipe(
    ofType(actions.fetchDashboardData.type),
    mergeMap(() => relativesDB
        .collection(env.relativesCollection)
        .where(
            'registerDate',
            '>',
            firebase.firestore.Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)),
        )
        .get()),
    map((snapshot) => snapshot?.docs?.map((doc) => ({
        ...doc.data(),
        id: doc.id,
    }))),
    map((relatives) => relatives.map(({ registerDate, ...relative }) => ({
        ...relative,
        registerDate: new Date(registerDate.toDate()).toLocaleDateString(),
    }))),
    map((relatives) => groupBy(relatives, 'registerDate')),
    map((dateGroups) => actions.setRelativesRegisteredLastWeek(dateGroups)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.fetchDashboardData.failed(),
    ])),
);

export default combineEpics(
    fetchNewRelativesEpic,
    fetchNewCaregiversEpic,
    fetchTotalCaregivers,
    fetchTotalRelatives,
    fetchAvailableCaregiversEpic,
    fetchNotReviewedCaregiversEpic,
    fetchRelativesInNeedEpic,
    fetchContractDurationGroupsEpic,
    fetchCaregiversRegisteredLastWeekEpic,
    fetchRelativesRegisteredLastWeekEpic,
);
