import { Navigate } from 'react-router-dom';

import { useProps } from 'libraries/components';
import { isAuthenticated } from 'models/user';

const modelProps = { isAuthenticated };

const withRedirect = (redirectTo) => (Component) => (props) => {
    const { isAuthenticated } = useProps(modelProps);

    return isAuthenticated ? <Navigate to={redirectTo} /> : <Component {...props} />;
};

export default withRedirect;
