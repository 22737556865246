import {
    avatarRenderer,
    ratingRenderer,
} from 'components/table';

import actionsRenderer from './actions';

export const columns = [
    {
        field: 'id',
        filterable: false,
        headerName: 'actions',
        sortable: false,
        width: 230,
        renderCell: actionsRenderer,
    },
    {
        field: 'photo',
        filterable: false,
        headerName: 'photo',
        sortable: false,
        renderCell: avatarRenderer,
    },
    {
        field: 'fullName',
        headerName: 'full_name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        valueGetter: (params) => `${params?.row?.lastName ?? ''} ${params?.row?.firstName ?? ''}`,
    },
    {
        field: 'lastName',
        headerName: 'last_name',
        width: 200,
    },
    {
        field: 'firstName',
        headerName: 'first_name',
        width: 200,
        // editable: true,
    },
    {
        field: 'age',
        headerName: 'age',
        type: 'number',
        width: 110,
    },
    {
        field: 'reviewed',
        headerName: 'reviewed',
        type: 'boolean',
        width: 150,
    },
    {
        field: 'employed',
        headerName: 'employed',
        type: 'boolean',
        width: 150,
    },
    {
        field: 'phoneNumber',
        headerName: 'phone_number',
        width: 150,
    },
    {
        field: 'email',
        headerName: 'email',
        width: 250,
        editable: false,
    },
    {
        field: 'address',
        headerName: 'address',
        width: 250,
    },
    {
        field: 'rating',
        headerName: 'rating',
        type: 'number',
        width: 250,
        renderCell: ratingRenderer,
    },
];
