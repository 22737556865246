import { Typography } from '@mui/material';

import { ConstructionGraphic } from 'assets';
import withStyles from './styles';

const UnderConstruction = ({ classes }) => (
    <div className={classes.root}>
        <ConstructionGraphic className={classes.graphic} />
        <Typography variant="h2">
            Under Construction
        </Typography>
        <Typography variant="h6">
            Coming soon
        </Typography>
    </div>
);

export default withStyles(UnderConstruction);
