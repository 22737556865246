import { CAREGIVERS, RELATIVES } from 'constants/permissions';
import algoliaSearch from 'algoliasearch';
import { Observable } from 'rxjs';
import env from 'env';

export const client = algoliaSearch(env.algoliaAppId, env.algoliaApiKey);

export const initIndex = (index) => client.initIndex(index);

export const createQuery = (query, params, userPermissions) => {
    const queries = [];

    if (userPermissions.includes(CAREGIVERS)) {
        queries.push({
            indexName: env.caregiversIndex,
            query,
            params,
        });
    }

    if (userPermissions.includes(RELATIVES)) {
        queries.push({
            indexName: env.relativesIndex,
            query,
            params,
        });
    }

    return queries;
};

export const runQuery = (query) => new Observable((observer) => {
    client
        .multipleQueries(query)
        .then((response) => observer.next(response))
        .catch((err) => observer.error(err))
        .finally(() => observer.complete());
});
