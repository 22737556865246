const styles = () => ({
    gridItem: {
        height: 'fit-content',
    },
    userCard: {
        width: '100%',
        height: '90%',
        minWidth: '10em',
        alignItems: 'center',
        margin: '3em auto 0em auto',
    },
});

export default styles;
