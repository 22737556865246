import { compose } from 'redux';
import clsx from 'clsx';

import {
    IconButton,
    Tooltip,
} from '@mui/material';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Delete as DeleteIcon,
    Bookmark as BookmarkIcon,
} from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import {
    pinnedCaregivers,
    pinCaregiver,
    unpinCaregiver,
} from 'models/caregivers';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

const CaregiverActions = ({
    classes,
    caregiver,
    pinnedCaregivers,
    pinCaregiver,
    unpinCaregiver,
}) => {
    const pinned = pinnedCaregivers.includes(caregiver);

    return (
        <div className={classes?.root}>
            <Tooltip title={<FormattedMessage defaultMessage="Delete Caregiver" id="delete_caregiver" />}>
                <IconButton size="large">
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={`${caregiver?.hidden
                ? (
                    <FormattedMessage
                        defaultMessage="Show"
                        id="show"
                    />
                )
                : (
                    <FormattedMessage
                        defaultMessage="Hide"
                        id="hide"
                    />
                )
            } Caregiver`}
            >
                <IconButton size="large">
                    {caregiver?.hidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
            </Tooltip>
            <div className={classes.spacer} />
            <Tooltip title={<FormattedMessage defaultMessage="Bookmark Profile" id="bookmark_profile" />}>
                <IconButton
                    className={clsx({
                        [classes.selectedButton]: pinned,
                    })}
                    onClick={() => (pinned ? unpinCaregiver(caregiver) : pinCaregiver(caregiver))}
                    size="large"
                >
                    <BookmarkIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        pinnedCaregivers,
        pinCaregiver,
        unpinCaregiver,
    }),
)(CaregiverActions);
