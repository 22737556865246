import { compose } from 'redux';

import {
    Paper,
    Typography,
    Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { withRedirect } from 'utils';
import { googleIcon } from 'assets';
import { withProps } from 'libraries/components';
import { signIn } from 'models/user';

import styles from './styles';

const SignOn = ({ classes, signIn }) => (
    <div className={classes.root}>
        <img
            className={classes.logo}
            src="https://i.ibb.co/hDphXbj/nRKFsQgA.png"
            alt="logo"
        />
        <Paper className={classes.paper}>
            <Typography variant="h3">
                SignOn
            </Typography>
            <Button
                className={classes.button}
                fullWidth
                startIcon={<img className={classes.icon} src={googleIcon} alt="G" />}
                variant="outlined"
                onClick={signIn}
            >
                With Google
            </Button>
        </Paper>
    </div>
);

export default compose(
    withStyles(styles),
    withProps({ signIn }),
    withRedirect('/'),
)(SignOn);
