import { TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { useFormContext, Controller } from 'react-hook-form';

import SectionPaper from 'components/sectionPaper';

import { FormattedMessage } from 'react-intl';
import styles from './styles';

const NotesPane = ({ classes }) => {
    const { control } = useFormContext();

    return (
        <SectionPaper className={classes.section} title={<FormattedMessage defaultMessage="Notes" id="notes" />}>
            <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                    <TextField
                        className={classes.text}
                        {...field}
                        variant="outlined"
                        multiline
                        rows={5}
                        fullWidth
                    />
                )}
            />
        </SectionPaper>
    );
};

export default withStyles(styles)(NotesPane);
