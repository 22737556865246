import { Button } from '@mui/material';
import { withProps } from 'libraries/components';
import { language, changeLanguage } from 'models/ui';
import { FormattedMessage } from 'react-intl';
import { SelectOption } from './components';

const Appearance = ({ language, changeLanguage }) => (
    <>
        <SelectOption
            label="Theme"
            options={[{ value: 'light', label: 'Light' }, { value: 'dark', label: 'Dark' }]}
            defaultValue="light"
        />
        <SelectOption
            label="Language"
            defaultValue={language}
            onChange={(language) => {
                console.log(language);
                changeLanguage(language);
            }}
            options={[{ value: 'el', label: 'Greek' }, { value: 'en', label: 'English' }]}
        />
        <SelectOption
            label="Font Size"
            options={[{ value: 'regular', label: 'Regular' }, { value: 'large', label: 'Large' }]}
            defaultValue="regular"
        />
        <Button variant="outlined">
            <FormattedMessage defaultMessage="Reset" id="reset" />
        </Button>
    </>
);

export default withProps({ language, changeLanguage })(Appearance);
