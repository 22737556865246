import {
    CAREGIVERS,
    FEATURE_FLAGS,
    RELATIVES,
    OPERATIONS,
} from 'constants/permissions';
import {
    Dashboard as DashboardIcon,
    SupervisedUserCircle as SupervisedUserCircleIcon,
    Flag as FlagIcon,
    AccountCircle as AccountCircleIcon,
    SupervisorAccount as SupervisorAccountIcon,
    ViewWeek as ViewWeekIcon,
} from '@mui/icons-material';

import SignOn from './signOn';
import Dashboard from './dashboard';
import Operations from './operations';
import Caregivers from './caregivers';
import FeatureFlags from './featureFlags';
import CaregiverProfile from './caregiverProfile';
import Relatives from './relatives';
import RelativeProfile from './relativeProfile';
import Settings from './settings';

const routes = {
    signOn: {
        id: 'signOn',
        path: '/sign-on',
        component: SignOn,
        isPrivate: false,
        hiddenLink: true,
        permissions: [],
        parentRoutes: [],
        inLayout: false,
    },
    dashboard: {
        id: 'dashboard',
        path: '/',
        component: Dashboard,
        isPrivate: true,
        permissions: [],
        parentRoutes: ['dashboard'],
        inLayout: true,
        exact: true,
        title: 'dashboard',
        icon: DashboardIcon,
    },
    operations: {
        id: 'operations',
        path: '/operations',
        component: Operations,
        isPrivate: true,
        permissions: [OPERATIONS],
        parentRoutes: ['dashboard'],
        inLayout: true,
        exact: true,
        title: 'operations',
        icon: ViewWeekIcon,
    },
    caregivers: {
        id: 'caregivers',
        path: '/caregivers',
        component: Caregivers,
        isPrivate: true,
        permissions: [CAREGIVERS],
        parentRoutes: ['dashboard'],
        inLayout: true,
        exact: true,
        title: 'caregivers',
        icon: SupervisedUserCircleIcon,
    },
    caregiverProfile: {
        id: 'caregiverProfile',
        path: '/caregivers/:id',
        component: CaregiverProfile,
        isPrivate: true,
        permissions: [CAREGIVERS],
        parentRoutes: ['dashboard', 'caregivers'],
        inLayout: true,
        hiddenLink: true,
        exact: true,
        title: 'profile',
        icon: AccountCircleIcon,
    },
    relatives: {
        id: 'relatives',
        path: '/relatives',
        component: Relatives,
        isPrivate: true,
        permissions: [RELATIVES],
        parentRoutes: ['dashboard'],
        inLayout: true,
        exact: true,
        title: 'relatives',
        icon: SupervisorAccountIcon,
    },
    relativeProfile: {
        id: 'relativeProfile',
        path: '/relatives/:id',
        component: RelativeProfile,
        isPrivate: true,
        permissions: [RELATIVES],
        parentRoutes: ['dashboard', 'relatives'],
        inLayout: true,
        hiddenLink: true,
        exact: true,
        title: 'profile',
        icon: SupervisorAccountIcon,
    },
    featureFlags: {
        id: 'featureFlags',
        path: '/feature-flags',
        component: FeatureFlags,
        isPrivate: true,
        permissions: [FEATURE_FLAGS],
        parentRoutes: ['dashboard'],
        inLayout: true,
        exact: true,
        title: 'feature_flags',
        icon: FlagIcon,
    },
    settings: {
        id: 'settings',
        path: '/settings',
        component: Settings,
        isPrivate: true,
        permissions: [],
        parentRoutes: ['dashboard'],
        inLayout: true,
        hiddenLink: true,
        exact: false,
        title: 'settings',
        icon: FlagIcon,
    },
};

export const routesList = Object
    .values(routes)
    .map(({
        component,
        ...route
    }) => route);

export default routes;
