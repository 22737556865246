import { actionCreator } from 'libraries/system';

const openDrawer = actionCreator('OPEN_DRAWER');
const closeDrawer = actionCreator('CLOSE_DRAWER');
const openUserDrawer = actionCreator('OPEN_USER_DRAWER');
const closeUserDrawer = actionCreator('CLOSE_USER_DRAWER');

const openDialog = actionCreator('OPEN_DIALOG');
const closeDialog = actionCreator('CLOSE_DIALOG');

const setSearchBarFocus = actionCreator('SET_SEARCH_BAR_FOCUS');

const setFabAttributes = actionCreator('SET_FAB_ATTRIBUTES');
const setShowFab = actionCreator('SET_SHOW_FAB');

const showSearchResults = actionCreator('SHOW_SEARCH_RESULTS');
const hideSearchResults = actionCreator('HIDE_SEARCH_RESULTS');

const changeLanguage = actionCreator('CHANGE_LANGUAGE');

export {
    openDrawer,
    closeDrawer,
    openUserDrawer,
    closeUserDrawer,
    setSearchBarFocus,
    openDialog,
    closeDialog,
    setFabAttributes,
    setShowFab,
    showSearchResults,
    hideSearchResults,
    changeLanguage,
};
