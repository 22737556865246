import { SAVE_ICON } from 'constants/fab';
import { useEffect } from 'react';
import { compose } from 'redux';

import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useFab } from 'components/fab';
import { withProps } from 'libraries/components';
import { setShowFab } from 'models/ui';

import withForm from './withForm';

const Layout = ({
    status: Status,
    accountInfo: AccountInfo,
    personInfo: PersonInfo,
    patientInfo: PatientInfo,
    servicesNeeded: ServicesNeeded,
    notes: Notes,
    environment: Environment,
    compensation: Compensation,
    planInfo: PlanInfo,
    address: Address,
    recommendedCaregivers: RecommendedCaregivers,
    employedCaregivers: EmployedCaregivers,
    setShowFab,
}) => {
    const { formState } = useFormContext();

    useFab({
        fabIcon: SAVE_ICON,
        fabProps: {
            form: 'relativeForm',
            type: 'submit',
        },
    });

    useEffect(() => {
        setShowFab(formState.isDirty);
    }, [formState.isDirty]);

    return (
        <Grid container spacing={2}>
            <Grid item md={4}>
                <PersonInfo />
            </Grid>
            <Grid item md={8}>
                <Status />
                <AccountInfo />
                <PatientInfo />
                <ServicesNeeded />
                <Address />
                <Environment />
                <Compensation />
                <Notes />
                <RecommendedCaregivers />
                <EmployedCaregivers />
                <PlanInfo />
            </Grid>
        </Grid>
    );
};

export default compose(
    withForm('relativeForm'),
    withProps({ setShowFab }),
)(Layout);
