const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentSpacer: {
        height: 32,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
});

export default styles;
