import { theme } from '@grandmama/ui-theme';

const lightTheme = {
    ...theme,
    palette: {
        ...theme.palette,
        background: {
            default: '#fafafa',
        },
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: theme.palette.primary.main,
                        borderLeft: `3px solid ${theme.palette.primary.main}`,
                        backgroundColor: 'unset',
                        paddingLeft: 13,
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
        },
    },
};

export default lightTheme;
