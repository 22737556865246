import withStyles from '@mui/styles/withStyles';

import SectionPaper from 'components/sectionPaper';

import { FormattedMessage } from 'react-intl';
import { AccountGrid } from './components';
import styles from './styles';

const AccountInfoPane = ({ classes }) => (
    <SectionPaper
        className={classes.section}
        title={(
            <FormattedMessage
                defaultMessage="Account Information"
                id="account_information"
            />
        )}
    >
        <AccountGrid />
    </SectionPaper>
);

export default withStyles(styles)(AccountInfoPane);
