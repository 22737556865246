const styles = (theme) => ({
    paper: {
        paddingTop: theme.spacing(4),
        height: '100%',
    },
    avatar: {
        width: '10em',
        height: '10em',
        margin: '0 auto',
    },
    name: {
        textAlign: 'center',
    },
    rating: {
        margin: '0 auto',
        textAlign: 'center',
    },
    stickyContainer: {
        position: 'sticky',
        top: '100px',
        zIndex: '1',
    },
    fullNameForRelativePage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default styles;
