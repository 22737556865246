import {
    List,
    ListItem,
    ListItemIcon,
    IconButton,
    TextField, Divider,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
    PhoneAndroid as MobilePhoneIcon,
    Phone as HomePhoneIcon,
    Room as RoomIcon,
    Mail as MailIcon,
    Check as CheckIcon,
    Circle as CircleIcon,
    DeleteForever as DeleteForeverIcon,
} from '@mui/icons-material';

import { Controller, useFormContext } from 'react-hook-form';

import FormListItem from 'components/formListItem';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

const InfoList = ({
    classes,
    relative,
}) => {
    const { control } = useFormContext();

    return (
        <List>
            <Controller
                name="approved"
                control={control}
                render={({ field }) => (
                    <FormListItem
                        label={(
                            <FormattedMessage
                                defaultMessage="Approved"
                                id="approved"
                            />
                        )}
                        icon={<CheckIcon />}
                        input={field}
                    />
                )}
            />
            <Controller
                name="active"
                control={control}
                render={({ field }) => (
                    <FormListItem
                        label={(
                            <FormattedMessage
                                defaultMessage="Active"
                                id="active"
                            />
                        )}
                        icon={<CircleIcon />}
                        input={field}
                    />
                )}
            />
            <Controller
                name="deleted"
                control={control}
                render={({ field }) => (
                    <FormListItem
                        label={(
                            <FormattedMessage
                                defaultMessage="Deleted"
                                id="deleted"
                            />
                        )}
                        icon={<DeleteForeverIcon />}
                        input={field}
                    />
                )}
            />
            <Divider />
            <ListItem className={classes.link}>
                <ListItemIcon>
                    <IconButton
                        className={classes.link}
                        size="small"
                        component="a"
                        href={`tel:${relative?.mobilePhone}`}
                    >
                        <MobilePhoneIcon />
                    </IconButton>
                </ListItemIcon>
                <Controller
                    name="mobilePhone"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={(
                                <FormattedMessage
                                    defaultMessage="Mobile Number"
                                    id="mobile_number"
                                />
                            )}
                            variant="standard"
                            fullWidth
                        />
                    )}
                />
            </ListItem>
            <ListItem className={classes.link}>
                <ListItemIcon>
                    <IconButton
                        className={classes.link}
                        size="small"
                        component="a"
                        href={`tel:${relative?.phoneNumber}`}
                    >
                        <HomePhoneIcon />
                    </IconButton>
                </ListItemIcon>
                <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={(
                                <FormattedMessage
                                    defaultMessage="Home Number"
                                    id="home_number"
                                />
                            )}
                            variant="standard"
                            fullWidth
                        />
                    )}
                />
            </ListItem>
            <ListItem className={classes.link}>
                <ListItemIcon>
                    <IconButton
                        className={classes.link}
                        size="small"
                        component="a"
                        href={`mailto:${relative?.email}`}
                    >
                        <MailIcon />
                    </IconButton>
                </ListItemIcon>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextField {...field} label="Email" variant="standard" fullWidth />
                    )}
                />
            </ListItem>
            <ListItem className={classes.link}>
                <ListItemIcon>
                    <IconButton
                        className={classes.link}
                        size="small"
                        component="a"
                        href={`mailto:${relative?.email2}`}
                    >
                        <MailIcon />
                    </IconButton>
                </ListItemIcon>
                <Controller
                    name="email2"
                    control={control}
                    render={({ field }) => (
                        <TextField {...field} label="Email 2" variant="standard" fullWidth />
                    )}
                />
            </ListItem>
            <ListItem className={classes.link}>
                <ListItemIcon>
                    <IconButton
                        className={classes.link}
                        size="small"
                        component="a"
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(relative?.address)}`}
                        target="_blank"
                    >
                        <RoomIcon />
                    </IconButton>
                </ListItemIcon>
                <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={(
                                <FormattedMessage
                                    defaultMessage="Address"
                                    id="address"
                                />
                            )}
                            variant="standard"
                            multiline
                            maxRows={4}
                            fullWidth
                        />
                    )}
                />
            </ListItem>
        </List>
    );
};

export default withStyles(styles)(InfoList);
