import { useFormContext, Controller } from 'react-hook-form';

import { Timetable } from 'components';

const Availability = () => {
    const { control } = useFormContext();

    return (
        <Controller
            name="availabilityHours"
            control={control}
            render={({ field: { onChange, value } }) => (
                <Timetable
                    onChange={onChange}
                    value={value}
                />
            )}
        />
    );
};

export default Availability;
