import {
    ButtonBase,
    Typography,
    Paper,
    Checkbox,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from './styles';

const getBackground = (positive, negative, theme) => {
    if (positive) {
        return 'linear-gradient(180deg, rgba(255,255,255,1) 92%, rgba(46,125,50,1) 100%)';
    }
    if (negative) {
        return 'linear-gradient(180deg, rgba(255,255,255,1) 92%, rgba(255,65,54,1) 100%)';
    }
    return theme.palette.background.paper;
};

const StyledPaper = styled(Paper)(({ theme, positive, negative }) => ({
    minWidth: '200px',
    width: '100%',
    textAlign: 'left',
    padding: '16px',
    background: getBackground(positive, negative, theme),
}));

const ToggleBox = ({
    label,
    value,
    icon: Icon,
    onChange,
    positive,
    negative,
}) => (
    <ButtonBase onClick={() => onChange(!value)}>
        <StyledPaper positive={positive} negative={negative}>
            <Typography variant="h5">
                <Checkbox
                    checked={!!value}
                    value="checkedA"
                    color="primary"
                    size="large"
                    disableRipple
                    sx={styles.checkbox}
                />
                {label}
            </Typography>
            <Box sx={styles.container}>
                <Icon sx={styles.icon} />
            </Box>
        </StyledPaper>
    </ButtonBase>
);

export default ToggleBox;
