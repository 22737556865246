const styles = (theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    avatar: {
        width: '5em',
        height: '5em',
        margin: '0 auto',
    },
    editButton: {
        display: 'block',
        margin: '0 auto',
        marginTop: theme.spacing(1),
    },
    form: {
        width: '100%',
    },
    field: {
        display: 'block',
        marginBottom: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
});

export default styles;
