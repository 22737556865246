import { effectActionCreator, actionCreator } from 'libraries/system';

export const fetchDashboardData = effectActionCreator('FETCH_DASHBOARD_DATA');

export const setCaregivers = actionCreator('SET_CAREGIVERS');
export const setRelatives = actionCreator('SET_RELATIVES');
export const setTotalCaregivers = actionCreator('SET_TOTAL_CAREGIVERS');
export const setTotalRelatives = actionCreator('SET_TOTAL_RELATIVES');
export const setAvailableCaregivers = actionCreator('SET_AVAILABLE_CAREGIVERS');
export const setNotReviewedCaregivers = actionCreator('SET_NOT_REVIEWED_CAREGIVERS');
export const setRelativesInNeed = actionCreator('SET_RELATIVES_IN_NEED');
export const setContractDurationGroups = actionCreator('SET_CONTRACT_DURATION_GROUPS');
export const setCaregiversRegisteredLastWeek = actionCreator('SET_CAREGIVERS_REGISTERED_LAST_WEEK');
export const setRelativesRegisteredLastWeek = actionCreator('SET_RELATIVES_REGISTERED_LAST_WEEK');
