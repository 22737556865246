const styles = (theme) => ({
    section: {
        padding: theme.spacing(2),
    },
    avatar: {
        width: '16px',
        height: '16px',
        marginRight: '2px',
        fontSize: '11px',
        display: 'inline-flex',
    },
    inputSpan: {
        display: 'flex',
        alignItems: 'center',
    },
});

export default styles;
