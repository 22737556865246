import {
    TextField,
    Grid, Autocomplete, capitalize, Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useFormContext, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import SectionPaper from 'components/sectionPaper';
import styles from './styles';

const CompensationPane = ({ classes }) => {
    const { control } = useFormContext();
    const intl = useIntl();
    return (
        <SectionPaper className={classes.section} title={<FormattedMessage defaultMessage="Compensation" id="compensation" />}>
            <div className={classes.spacer} />
            <Grid container spacing={1}>
                <Grid item md={4}>
                    <Controller
                        name="compensation"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Compensation"
                                        id="compensation"
                                    />
                                )}
                                className={classes.text}
                                {...field}
                                variant="outlined"
                                type="number"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item md={3}>
                    <Controller
                        freeSolo
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={['EUR €', 'GBP £', 'USD $']}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={(
                                            <FormattedMessage
                                                defaultMessage="Currency"
                                                id="currency"
                                            />
                                        )}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                                onChange={(_, data) => field.onChange(data)}
                            />
                        )}
                        name="compensationCurrency"
                        control={control}
                    />
                </Grid>
                <Grid item md={1}>
                    <Typography className={classes.text}>
                        <FormattedMessage id="per" defaultMessage="per" />
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Controller
                        freeSolo
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={['hour', 'day', 'week', 'month', 'year']}
                                getOptionLabel={(option) => (
                                    intl.formatMessage({
                                        id: option,
                                        defaultMessage: capitalize(option),
                                    })
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                                onChange={(_, data) => field.onChange(data)}
                            />
                        )}
                        name="compensationCycle"
                        control={control}
                    />
                </Grid>
            </Grid>
            <div className={classes.spacer} />
            <Grid container spacing={1}>
                <Grid item md={4}>
                    <Controller
                        name="daysOff"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Days"
                                        id="days"
                                    />
                                )}
                                className={classes.text}
                                {...field}
                                variant="outlined"
                                type="number"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <div className={classes.spacer} />
                <Grid item md={4}>
                    <Typography className={classes.text}>
                        <FormattedMessage
                            defaultMessage="days off per"
                            id="days_off_per"
                        />
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Controller
                        freeSolo
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={['week', 'month', 'year']}
                                getOptionLabel={(option) => (
                                    intl.formatMessage({
                                        id: option,
                                        defaultMessage: capitalize(option),
                                    })
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                                onChange={(_, data) => field.onChange(data)}
                            />
                        )}
                        name="daysOffCycle"
                        control={control}
                    />
                </Grid>
            </Grid>
        </SectionPaper>
    );
};

export default withStyles(styles)(CompensationPane);
