import clsx from 'clsx';
import { compose } from 'redux';

import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';

import {
    Drawer as MuiDrawer,
    IconButton,
    Divider,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';
import { withProps } from 'libraries/components';
import { drawerOpen, closeDrawer } from 'models/ui';

import styles from './styles';

const Drawer = ({
    classes,
    drawerOpen,
    closeDrawer,
    children,
}) => (
    <MuiDrawer
        variant="permanent"
        className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
            paper: clsx({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
            }),
        }}
    >
        <div className={classes.toolbar}>
            <img
                className={classes.logo}
                src="https://i.ibb.co/yNSS8tF/ex3Kt-NQ.png"
                alt="logo"
            />
            <IconButton onClick={() => closeDrawer()} size="large">
                <ChevronLeftIcon />
            </IconButton>
        </div>
        <Divider />
        { children }
    </MuiDrawer>
);

export default compose(
    withStyles(styles),
    withProps({ drawerOpen, closeDrawer }),
)(Drawer);
