import { UPDATE_FEATURE_FLAG } from 'constants/dialog';
import { useState } from 'react';
import {
    CircularProgress, Grid, ListItemText, Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

import { deleteFeatureFlag, features, fetchState } from 'models/featureFlags';
import { openDialog } from 'models/ui';
import { withProps } from 'libraries/components';

import { createDialogTitle } from './helper';

import { Row, Switch } from '..';

const Body = ({
    features,
    fetchState,
    openDialog,
    deleteFeatureFlag,
}) => {
    const [rowId, setRowId] = useState(null);

    const handleChange = (id, envType) => () => {
        const feature = features[id];

        openDialog({
            title: createDialogTitle(feature, envType),
            type: UPDATE_FEATURE_FLAG,
            props: {
                id,
                envType,
                feature,
            },
        });
    };

    return Object.entries(features)?.map(([id, feature]) => (
        <Row key={id}>
            <ListItemText
                primary={`${feature.project} / ${feature.label}`}
                secondary={feature.description}
            />
            <Grid container alignItems="center">
                <Switch
                    checked={feature.dev.value}
                    onChange={handleChange(id, 'dev')}
                />
                <Typography variant="caption">
                    {feature.dev.author}
                </Typography>
            </Grid>
            <Grid container alignItems="center">
                <Switch
                    checked={feature.prod.value}
                    onChange={handleChange(id, 'prod')}
                />
                <Typography variant="caption">
                    {feature.prod.author}
                </Typography>
            </Grid>
            {rowId === id && fetchState.isDeleting
                ? <CircularProgress size={20} />
                : (
                    <Delete
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setRowId(id);
                            deleteFeatureFlag(id);
                        }}
                    />
                )}
        </Row>
    ));
};

export default withProps({
    features,
    fetchState,
    openDialog,
    deleteFeatureFlag,
})(Body);
