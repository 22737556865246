const styles = () => ({
    root: {
        textAlign: 'center',
        width: 'fit-content',
        margin: '0 auto',
    },
    graphic: {
        width: '16em',
        height: '16em',
    },
});

export default styles;
