import withStyles from '@mui/styles/withStyles';
import { child } from 'libraries/styles';

const styles = withStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        '& > div': {
            marginBottom: 16,
        },
        [child('MuiDialogActions-root')]: {
            '& > button': {
                minHeight: '36px',
            },
        },
    },
}));

export { styles as withStyles };
