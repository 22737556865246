const styles = (theme) => ({
    section: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    spacer: {
        marginTop: '8px',
        width: '100%',
    },
    indent: {
        paddingLeft: '16px',
    },
});

export default styles;
