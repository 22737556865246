const styles = (theme) => ({
    root: {
        width: '100%',
        margin: '0 0 24px 0',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

export default styles;
