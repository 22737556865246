import withStyles from '@mui/styles/withStyles';

import { routesList } from 'routes';
import {
    AppBar,
    Dialog,
    Drawer,
    DrawerMenu,
    Header,
    UserDrawer,
    BottomBar,
    Fab,
} from './components';
import styles from './styles';

const Layout = ({ classes, children }) => (
    <div className={classes.root}>
        <Dialog />
        <AppBar />
        <Drawer>
            <DrawerMenu menus={[routesList]} />
        </Drawer>
        <UserDrawer />
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Header />
            <div className={classes.contentSpacer} />
            { children }
            <Fab />
            <BottomBar />
        </main>
    </div>
);

export default withStyles(styles)(Layout);
