import { map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { effect } from 'libraries/system';

import { closeDialog } from 'models/ui';
import * as actions from './actions';
import * as services from './services';

const getFeatureFlagsEpic = (action$) => action$.pipe(
    ofType(actions.getFeatureFlags.type),
    effect(
        services.getFeatureFlagsRequest,
        actions.getFeatureFlags,
    ),
);

const createFeatureFlagEpic = (action$, state$) => action$.pipe(
    ofType(actions.createFeatureFlag.type),
    map(({ payload }) => {
        const { displayName } = state$.value.user.info;
        const info = {
            author: displayName,
            value: false,
            lastModified: new Date(),
        };
        return {
            project: payload.project,
            label: payload.label,
            description: payload.description,
            dev: info,
            prod: info,
        };
    }),
    effect(
        services.createFeatureFlagRequest,
        actions.createFeatureFlag,
        [() => closeDialog()],
    ),
);

const updateFeatureFlagEpic = (action$) => action$.pipe(
    ofType(actions.updateFeatureFlag.type),
    effect(
        services.updateFeatureFlagRequest,
        actions.updateFeatureFlag,
        [() => closeDialog()],
    ),
);

const deleteFeatureFlagEpic = (action$) => action$.pipe(
    ofType(actions.deleteFeatureFlag.type),
    effect(
        services.deleteFeatureFlagRequest,
        actions.deleteFeatureFlag,
    ),
);

export default combineEpics(
    getFeatureFlagsEpic,
    createFeatureFlagEpic,
    updateFeatureFlagEpic,
    deleteFeatureFlagEpic,
);
