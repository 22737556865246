import { BrowserRouter as Router, Routes as BrowserRoutes, Route } from 'react-router-dom';

import Layout from 'components/layout';
import PrivateRoute from 'components/privateRoute';
import { withProps } from 'libraries/components';
import { isAuthenticated, userInfo } from 'models/user';

import routes from './config';

const NoLayout = ({ children }) => <>{children}</>;

const Routes = ({
    isAuthenticated,
    userInfo,
}) => (
    <Router>
        <BrowserRoutes>
            {Object.values(routes).map((route) => (
                <Route
                    key={route.id}
                    path={route.path}
                    element={(
                        <PrivateRoute
                            layout={route.inLayout ? Layout : NoLayout}
                            isAuthenticated={isAuthenticated}
                            userPermissions={userInfo?.permissions}
                            {...route}
                        />
                    )}
                />
            ))}
        </BrowserRoutes>
    </Router>
);

export default withProps({
    isAuthenticated,
    userInfo,
})(Routes);
