const styles = (theme) => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: '100px',
        height: '70vh',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
});

export default styles;
