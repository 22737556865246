import { compose } from 'redux';

import {
    Button,
    DialogActions,
    DialogContent,
    LinearProgress,
    TextField,
    Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { PhoneInput } from '@grandmama/ui-phone-input';
import {
    expandedRelativeProfile,
    sendInvoiceToRelative,
    sendingInvoiceToRelative,
} from 'models/relatives';
import { withProps } from 'libraries/components';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const initialMessage = 'ΛOΓAPIAΣMOΣ Viva Payment Services : IBAN: GR5770100000000859855223121  GRANDMAMA AE 50€  (ΓIA THN ENTOΛH ANAΘEΣHΣ ΔIAPKEIAΣ 1 MHNA) H ΣTON ΣYNΔEΣMO https://pay.vivawallet.com/grandmama XΩPIΣ ΠPOMHΘEIA TPAΠEZΩN ΠAPAKAΛOYME NA AΠOΣTEIΛETE TO KATAΘETHPIO ΣTO info@grandmama.gr ΣAΣ EYXAPIΣTOYME grandmama';
const finalMessage = 'ΛOΓAPIAΣMOΣ Viva Payment Services : IBAN: GR5770100000000859855223121  GRANDMAMA AE 249€  (ΓIA THN ENTOΛH ANAΘEΣHΣ ΔIAPKEIAΣ 1 MHNA) H ΣTON ΣYNΔEΣMO https://pay.vivawallet.com/grandmama XΩPIΣ ΠPOMHΘEIA TPAΠEZΩN ΠAPAKAΛOYME NA AΠOΣTEIΛETE TO KATAΘETHPIO ΣTO info@grandmama.gr ΣAΣ EYXAPIΣTOYME grandmama';

const SendInvoice = ({
    classes,
    expandedRelativeProfile,
    sendInvoiceToRelative,
    sendingInvoiceToRelative,
    props,
    handleClose,
}) => {
    const { id, mobilePhone } = expandedRelativeProfile;
    const { invoiceSentOnName } = props;
    const { handleSubmit, control } = useForm({
        defaultValues: {
            mobilePhone,
            message: props?.amountName === 'initialPaymentAmount' ? initialMessage : finalMessage,
        },
    });

    const sendInvoice = ({ mobilePhone, message }) => sendInvoiceToRelative({
        mobilePhone,
        message,
        id,
        invoiceSentOnName,
    });

    return (
        <form onSubmit={handleSubmit(sendInvoice)} noValidate>
            {sendingInvoiceToRelative && <LinearProgress color="primary" />}

            <DialogContent>
                <div className={classes.spacer} />
                <Typography>
                    The invoice will be sent to the following phone number
                </Typography>
                <div className={classes.spacer} />
                <Controller
                    name="mobilePhone"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <PhoneInput
                            {...field}
                            label="Phone Number"
                            fullWidth
                            autoFocus
                            error={error?.mobilePhone}
                            helperText={error?.mobilePhone}
                        />
                    )}
                />
                <div className={classes.spacer} />
                <Controller
                    name="message"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Message"
                            multiline
                            fullWidth
                            maxRows={7}
                            error={error?.message}
                            helperText={error?.message}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.cancelButton}
                    onClick={handleClose}
                    disabled={sendingInvoiceToRelative}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    type="submit"
                    disabled={sendingInvoiceToRelative}
                >
                    Send
                </Button>
            </DialogActions>
        </form>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        expandedRelativeProfile,
        sendInvoiceToRelative,
        sendingInvoiceToRelative,
    }),
)(SendInvoice);
