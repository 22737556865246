import { createSelector } from 'reselect';

export const relatives = ({ relatives }) => relatives.relatives;
export const totalRelatives = ({ relatives }) => relatives.totalRelatives;
export const loadingRelatives = ({ relatives }) => relatives.loadingRelatives;
export const pagination = ({ relatives }) => relatives.pagination;
export const pinnedRelatives = ({ relatives }) => relatives.pinnedRelatives;
export const sendingInvoiceToRelative = ({ relatives }) => relatives.sendingInvoiceToRelative;

export const expandedRelativeProfile = createSelector(
    ({ relatives }) => relatives.expandedRelativeProfile,
    ({ settings }) => settings.users,
    (profile, users) => ({
        ...profile,
        registeredBy: users.find((user) => user.id === profile?.registeredBy),
        registerDate: profile?.registerDate && profile?.registerDate?.seconds
            ? new Date(profile.registerDate.seconds * 1000)
            : null,
        contractDurationDates: profile
            ?.contractDurationDates
            ?.map((contractDurationDates) => (
                contractDurationDates?.seconds
                    ? new Date(contractDurationDates?.seconds * 1000)
                    : null)),
        dateOfBirth: profile?.dateOfBirth
            ? new Date(profile.dateOfBirth?.seconds * 1000)
            : '',
        compensationCurrency: profile?.compensationCurrency ?? 'EUR €',
        compensation: profile?.compensation ?? 700,
        compensationCycle: profile?.compensationCycle ?? 'month',
        daysOff: profile?.daysOff ?? 4,
        daysOffCycle: profile?.daysOffCycle ?? 'month',
        statusChangedOn: profile?.statusChangedOn
            ? new Date(profile.statusChangedOn.seconds * 1000)
            : new Date(),
        pets: {
            ...profile?.pets,
            dogs: profile?.pets?.dogs ?? 0,
            cats: profile?.pets?.cats ?? 0,
            other: profile?.pets?.other ?? '',
        },
        recommendedCaregivers: profile?.recommendedCaregivers ?? [],
        plan: profile?.plan
            ? {
                ...profile?.plan,
                dateOfInitialPayment: profile?.plan?.dateOfInitialPayment?.seconds ? new Date(profile?.plan?.dateOfInitialPayment?.seconds * 1000) : '',
                dateOfFullPayment: profile?.plan?.dateOfFullPayment && profile?.plan?.dateOfFullPayment?.seconds ? new Date(profile?.plan?.dateOfFullPayment?.seconds * 1000) : '',
                initialInvoiceSentOn: profile?.plan?.initialInvoiceSentOn && profile?.plan?.initialInvoiceSentOn?.seconds ? new Date(profile?.plan?.initialInvoiceSentOn?.seconds * 1000) : '',
                fullInvoiceSentOn: profile?.plan?.fullInvoiceSentOn && profile?.plan?.fullInvoiceSentOn?.seconds ? new Date(profile?.plan?.fullInvoiceSentOn?.seconds * 1000) : '',
            } : undefined,
        dateCollaborationStarted: profile?.dateCollaborationStarted,
    }),
);

export const expandedRelativeLoading = ({ relatives }) => relatives.expandedRelativeLoading;
