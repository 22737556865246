import { Link } from 'react-router-dom';
import {
    ListItem,
    Typography,
    ListItemAvatar,
    ListItemText,
    IconButton,
    ListItemSecondaryAction,
    Chip,
} from '@mui/material';
import {
    Bookmark as BookmarkIcon,
    Done as DoneIcon,
    Cancel as CancelIcon,
} from '@mui/icons-material';

import { Avatar } from '@grandmama/ui-avatar';

import withStyles from './styles';

const CaregiverListItem = ({
    classes,
    caregiver: {
        _highlightResult,
        address,
        available,
        active,
        firstName,
        lastName,
        objectID,
    },
    expandedPathPrefix,
    onBookmarkClick,
}) => (
    <ListItem
        button
        component={Link}
        to={`/${expandedPathPrefix}/${objectID}`}
    >
        <ListItemAvatar>
            <Avatar name={`${firstName} ${lastName}`} />
        </ListItemAvatar>
        <div style={{ display: 'block' }}>
            <ListItemText primary={`${firstName} ${lastName}`} secondary={address} />
            <Chip
                className={classes.chip}
                icon={available ? <DoneIcon /> : <CancelIcon />}
                color={available ? 'success' : 'error'}
                label={available ? 'Available' : 'Not Available'}
            />
            <Chip
                className={classes.chip}
                icon={active ? <DoneIcon /> : <CancelIcon />}
                color={active ? 'success' : 'error'}
                label={active ? 'Active' : 'Inactive'}
            />
            <Typography variant="caption" component="h5">
                Matches:
                {
                    Object
                        .entries(_highlightResult)
                        .filter(([, value]) => value?.matchLevel === 'full')
                        .map(([id]) => ` ${id}`)
                }
            </Typography>
        </div>
        <ListItemSecondaryAction>
            <IconButton
                edge="end"
                aria-label="Pin Caregiver"
                onClick={() => onBookmarkClick({
                    id: objectID,
                    firstName,
                    lastName,
                    needsFetch: true,
                })}
                size="large"
            >
                <BookmarkIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
);

export default withStyles(CaregiverListItem);
