import { compose } from 'redux';

import {
    Paper,
    Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { Avatar } from '@grandmama/ui-avatar';

import { withProps } from 'libraries/components';
import { expandedCaregiverProfile } from 'models/caregivers';

import { InfoList, CaregiverActions } from './components';
import styles from './styles';

const InfoPane = ({
    classes,
    expandedCaregiverProfile,
}) => (
    <Paper className={classes.paper}>
        <div className={classes.stickyContainer}>
            <Avatar
                name={`${expandedCaregiverProfile?.firstName} ${expandedCaregiverProfile?.lastName}`}
                photo={expandedCaregiverProfile?.photo}
                avatarProps={{
                    className: classes.avatar,
                }}
            />
            <Typography className={classes.name} variant="h4">
                {`${expandedCaregiverProfile?.firstName} ${expandedCaregiverProfile?.lastName}`}
            </Typography>
            <InfoList caregiver={expandedCaregiverProfile} />
            <CaregiverActions caregiver={expandedCaregiverProfile} />
        </div>
    </Paper>
);

export default compose(
    withProps({
        expandedCaregiverProfile,
    }),
    withStyles(styles),
)(InfoPane);
