import { Typography, Divider } from '@mui/material';

import withStyles from './styles';

const ResultBox = ({
    classes,
    title,
    totalHits,
    children,
}) => (
    <div className={classes.root}>
        <div className={classes.titleBox}>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="caption">
                {`Found ${totalHits}`}
            </Typography>
        </div>
        <Divider orientation="vertical" />
        <div className={classes.childrenContainer}>
            {children}
        </div>
    </div>
);

export default withStyles(ResultBox);
