import React, { forwardRef, Suspense, useState } from 'react';
import {
    Paper,
    Box,
    Typography,
    Skeleton,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

const loadWidget = (widget) => React.lazy(() => import(`../widgets/${widget.id}`));

const DashboardWidget = forwardRef(({ widget, ...props }, ref) => {
    const [WidgetComponent] = useState(loadWidget(widget));

    return (
        <Paper sx={styles.paper} ref={ref} {...props}>
            <Box sx={styles.header} className="grid-item__header">
                <Typography sx={styles.title} variant="h5">
                    {}
                    <FormattedMessage
                        id={widget.title}
                        defaultMessage={widget.title}
                    />

                </Typography>
            </Box>
            <Suspense fallback={<Skeleton width="100%" height="100%" />}>
                <WidgetComponent />
                {props.children}
            </Suspense>
        </Paper>
    );
});

export default DashboardWidget;
