import { Observable } from 'rxjs';

const documentStream = (db) => (collectionPath, documentPath) => new Observable((observer) => db
    .collection(collectionPath).doc(documentPath)
    .onSnapshot(
        (doc) => observer.next({
            id: doc.id,
            ...doc.data(),
        }),
        (error) => observer.error(error),
        () => observer.complete(),
    ));

export default documentStream;
