import clsx from 'clsx';
import { Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const SectionPaper = ({
    classes,
    title,
    children,
    noMarginTop,
    ...props
}) => (
    <Paper
        className={clsx(classes.paper, { [classes.noMarginTop]: noMarginTop })}
        {...props}
    >
        <Typography variant="h4">
            {title}
        </Typography>
        {children}
    </Paper>
);

export default withStyles(styles)(SectionPaper);
