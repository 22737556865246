import {
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Box,
    Chip,
    MenuItem,
} from '@mui/material';

import { Controller } from 'react-hook-form';
import {
    getStyles,
    MenuProps,
    handleChange,
} from './helper';

const formatValue = (value) => (typeof value === 'string' ? [value] : value ?? []);

const FormSelect = ({
    label = 'Select',
    control,
    name,
    getOptionLabel = (x) => x,
    options = [],
}) => (
    <Controller
        freeSolo
        render={({ field: { value, onChange } }) => (
            <FormControl sx={{ width: '100%' }}>
                <InputLabel id={`multiple-chip-label-${name}`}>{label}</InputLabel>
                <Select
                    labelId={`multiple-chip-label-${name}`}
                    multiple
                    value={formatValue(value)}
                    onChange={handleChange(onChange)}
                    input={<OutlinedInput id={`select-input-${name}`} label={label} fullWidth />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={getOptionLabel(value)} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {options.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, value)}
                        >
                            {getOptionLabel(name)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )}
        name={name}
        control={control}
    />
);

export default FormSelect;
