import { useEffect } from 'react';
import { useProps } from 'libraries/components';

import { setFabAttributes } from 'models/ui';

const stateProps = {
    setFabAttributes,
};

const useFab = ({ fabIcon, fabProps }) => {
    const { setFabAttributes } = useProps(stateProps);

    useEffect(() => {
        setFabAttributes({
            showFab: true,
            fabProps,
            fabIcon,
        });

        return () => setFabAttributes({
            showFab: false,
            fabProps: {},
            fabIcon: undefined,
        });
    }, []);
};

export default useFab;
