import SettingsIcon from '@mui/icons-material/Settings';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import { FormattedMessage } from 'react-intl';
import { Appearance, General, Notifications } from './components';

export const menuItems = [
    {
        label: <FormattedMessage
            defaultMessage="General"
            id="general"
        />,
        icon: <SettingsIcon />,
        component: <General />,
    },
    {
        label: <FormattedMessage
            defaultMessage="Appearance"
            id="appearance"
        />,
        icon: <ViewQuiltIcon />,
        component: <Appearance />,
    },
    {
        label: <FormattedMessage
            defaultMessage="Notifications"
            id="notifications"
        />,
        icon: <NotificationsActiveIcon />,
        component: <Notifications />,
    },
    {
        label: <FormattedMessage
            defaultMessage="Organisation"
            id="organisation"
        />,
        icon: <CorporateFareIcon />,
        component: <General />,
    },
];
