import { useFormContext, Controller } from 'react-hook-form';

import { AddressPaper } from 'components';
import './styles.css';
import { FormattedMessage } from 'react-intl';

const PatientAddress = () => {
    const { control, setValue, getValues } = useFormContext();
    const [lat, lon] = getValues(['_geoloc.lat', '_geoloc.lon']);

    const onUpdateCoordinates = ({ lat, lng }) => {
        const setOptions = {
            shouldValidate: true,
            shouldDirty: true,
        };
        setValue('_geoloc.lat', lat, setOptions);
        setValue('_geoloc.lon', lng, setOptions);
    };

    return (
        <Controller
            name="address"
            control={control}
            label={<FormattedMessage defaultMessage="Address" id="address" />}
            render={({ field }) => (
                <AddressPaper
                    {...field}
                    sectionTitle=""
                    initCoordinates={{
                        lat: lat ?? 0,
                        lon: lon ?? 0,
                    }}
                    onUpdateCoordinates={onUpdateCoordinates}
                />
            )}
        />
    );
};

export default PatientAddress;
