import { AVAILABLE_SERVICES, CONTRACT_DURATION_OPTIONS } from 'constants/relatives';

import { DateRangePicker } from '@mui/x-date-pickers-pro';
import '@mui/lab';

import { FormRadioButtons, FormSelect } from 'components';

import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import {
    Box, capitalize, TextField, Typography,
} from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const ServicesNeeded = () => {
    const { control } = useFormContext();
    const contractDuration = useWatch({
        control,
        name: 'contractDuration',
    });

    return (
        <>
            <FormSelect
                label={(
                    <FormattedMessage
                        defaultMessage="Services Needed"
                        id="services_needed"
                    />
                )}
                name="servicesNeeded"
                control={control}
                options={AVAILABLE_SERVICES}
                getOptionLabel={(option) => (
                    <FormattedMessage
                        defaultMessage={capitalize(option)}
                        id={option}
                    />
                )}
            />
            <FormRadioButtons
                label={(
                    <FormattedMessage
                        defaultMessage="Contract Duration"
                        id="contract_duration"
                    />
                )}
                name="contractDuration"
                control={control}
                options={CONTRACT_DURATION_OPTIONS}
                getOptionLabel={(option) => (
                    <FormattedMessage
                        defaultMessage={capitalize(option)}
                        id={option}
                    />
                )}
            />
            {contractDuration === 'short term' && (
                <Controller
                    control={control}
                    name="contractDurationDates"
                    render={({ field: { value, onChange } }) => (
                        <>
                            <DateRangePicker
                                value={value}
                                onChange={onChange}
                                renderInput={(startProps, endProps) => (
                                    <>
                                        <TextField {...startProps} fullWidth />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <TextField {...endProps} fullWidth />
                                    </>
                                )}
                                localeText={{
                                    start: 'Start',
                                    end: 'End',
                                }}
                            />
                            <Typography sx={{ ml: 1 }}>
                                {value[1] && `For ${DateTime
                                    .fromJSDate(value[1])
                                    .diff(DateTime.fromJSDate(value[0]), 'days')
                                    .toObject().days} days`}
                            </Typography>
                        </>
                    )}
                />
            )}
        </>
    );
};

export default ServicesNeeded;
