import { useState } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import styles from './styles';

const SelectOption = ({
    options,
    defaultValue,
    label,
    onChange,
}) => {
    const [selected, setSelected] = useState(defaultValue);

    const handleChange = (event) => {
        setSelected(event.target.value);
        onChange(event.target.value);
    };

    return (
        <Box sx={styles.container}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected}
                    label={label}
                    onChange={handleChange}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default SelectOption;
