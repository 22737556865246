import { createSelector } from 'reselect';

import { pinnedRelatives } from '../relatives/selectors';
import { pinnedCaregivers } from '../caregivers/selectors';
import { searchResultsOpen as searchResultsPopulated } from '../app/selectors';

const drawerOpen = ({ ui }) => ui.drawerOpen;
const userDrawerOpen = ({ ui }) => ui.userDrawerOpen;
const searchBarFocus = ({ ui }) => ui.searchBarFocus;
const dialog = ({ ui }) => ui.dialog;
const showFab = ({ ui }) => ui.showFab;
const fabProps = ({ ui }) => ui.fabProps;
const fabIcon = ({ ui }) => ui.fabIcon;
const searchResultsOpen = ({ ui }) => ui.searchResultsOpen;

const language = ({ ui }) => ui.language;
const raisedFab = createSelector(
    pinnedRelatives,
    pinnedCaregivers,
    (relatives, caregivers) => (relatives.length + caregivers.length) > 0,
);

const searchResultsVisible = createSelector(
    searchResultsPopulated,
    searchResultsOpen,
    (populated, open) => populated && open,
);

export {
    drawerOpen,
    userDrawerOpen,
    searchBarFocus,
    dialog,
    showFab,
    fabProps,
    fabIcon,
    language,
    raisedFab,
    searchResultsVisible,
};
