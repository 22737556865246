import { RepRenderer, StatusPill, ServicesNeededCustomFilter } from 'components';
import { FormattedMessage } from 'react-intl/lib';
import actionsRenderer from './actions';

const statusRenderer = ({ value }) => (
    <StatusPill status={value} />
);
const repRenderer = ({ row }) => (
    <RepRenderer id={row.caseRepresentativeId} />
);
const servicesNeededOperators = [
    {
        label: <FormattedMessage id="is" defaultMessage="is" />,
        value: 'services_needed',
        getApplyFilterFn: (filter) => ({ value }) => value?.includes(filter?.value?.props?.value),
        InputComponent: ServicesNeededCustomFilter,
    },
];
export const columns = (users) => [
    {
        field: 'id',
        filterable: false,
        headerName: 'actions',
        sortable: false,
        width: 120,
        renderCell: actionsRenderer,
    },
    {
        field: 'fullName',
        headerName: 'full_name',
        sortable: true,
        width: 160,
        valueGetter: (params) => `${params?.row?.lastName ?? ''} ${params?.row?.firstName ?? ''}`,
    },
    {
        field: 'address',
        headerName: 'address',
        width: 250,
    },
    {
        field: 'mobilePhone',
        headerName: 'phone_number',
        width: 150,
    },
    {
        field: 'email',
        headerName: 'email',
        width: 250,
        editable: false,
    },
    {
        field: 'registerDate',
        headerName: 'date_collaboration_started',
        width: 350,
        type: 'dateTime',
        valueGetter: (params) => (
            params.row?.registerDate
                ? new Date(params.row?.registerDate?.seconds * 1000)
                : new Date()),
        editable: false,
    },
    {
        field: 'servicesNeeded',
        headerName: 'services_needed',
        width: 300,
        editable: false,
        type: 'singleSelect',
        filterOperators: servicesNeededOperators,
        valueGetter: (params) => params.row?.servicesNeeded,
        renderCell: (params) => (params.row?.servicesNeeded
            ? params.row?.servicesNeeded.map((service) => (
                <FormattedMessage id={service} defaultMessage={service} />
            ))
            : '-'),
    },
    {
        field: 'representative',
        headerName: 'rep',
        width: 250,
        renderCell: repRenderer,
        type: 'singleSelect',
        valueOptions: users.map((user) => ({ value: user?.id, label: user?.displayName })),
        valueGetter: (params) => {
            const user = params.colDef.valueOptions?.find(
                (user) => user.value === params.row?.caseRepresentativeId,
            );
            return user ? user.value : '-';
        },
        editable: false,
    },
    {
        field: 'caseStatus',
        headerName: 'status',
        width: 130,
        editable: false,
        renderCell: statusRenderer,
        valueOptions: ['MATCHED', 'CLOSED', 'NEW_CASE', 'MATCHING'].map((status) => ({
            label: <FormattedMessage id={status} defaultMessage={status} />, value: status,
        })),
        valueGetter: (params) => params.row?.caseStatus,
        type: 'singleSelect',
    },
    {
        field: 'patientName',
        headerName: 'patient_name',
        sortable: false,
        width: 160,
        valueGetter: (params) => `${params?.row?.patientFirstName ?? ''} ${params?.row?.patientLastName ?? ''}`,
    },
];
