import { compose } from 'redux';

import clsx from 'clsx';

import {
    IconButton,
    Tooltip,
} from '@mui/material';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Delete as DeleteIcon,
    Bookmark as BookmarkIcon,
} from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import {
    pinnedRelatives,
    pinRelative,
    unpinRelative,
} from 'models/relatives';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

const RelativeActions = ({
    classes,
    relative,
    pinnedRelatives,
    pinRelative,
    unpinRelative,
}) => {
    const pinned = pinnedRelatives.includes(relative);

    return (
        <div className={classes.root}>
            <Tooltip title="Delete Relative">
                <IconButton size="large">
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={relative.hidden
                ? (
                    <FormattedMessage
                        defaultMessage="Show Caregiver"
                        id="show_caregiver"
                    />
                )
                : (
                    <FormattedMessage
                        defaultMessage="Hide Caregiver"
                        id="hide_caregiver"
                    />
                )}
            >
                <IconButton size="large">
                    {relative?.hidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
            </Tooltip>
            <div className={classes.spacer} />
            <Tooltip title={<FormattedMessage defaultMessage="Bookmark Profile" id="bookmark_profile" />}>
                <IconButton
                    className={clsx({
                        [classes.selectedButton]: pinned,
                    })}
                    onClick={() => (pinned ? unpinRelative(relative) : pinRelative(relative))}
                    size="large"
                >
                    <BookmarkIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        pinnedRelatives,
        pinRelative,
        unpinRelative,
    }),
)(RelativeActions);
