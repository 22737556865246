import { compose } from 'redux';
import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import { users } from 'models/settings';

import { UserInfo } from './components';
import styles from './styles';

const ManageUsers = ({ users }) => (
    <>
        {users.map((user) => (<UserInfo key={user.uid} user={user} />))}
    </>
);

export default compose(
    withStyles(styles),
    withProps({ users }),
)(ManageUsers);
