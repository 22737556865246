import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles';

import Providers from 'providers';
import { lightTheme } from 'themes';
import env from 'env';

import * as serviceWorker from './serviceWorker';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

const ddConfig = {
    applicationId: env.datadogAppID,
    clientToken: env.datadogApiKey,
    site: 'datadoghq.eu',
    service: 'dashboard',
    env: env.stage,
    version: `1.0.0-${env.circleCIBuildNum}`,
    defaultPrivacyLevel: 'mask-user-input',
};

datadogLogs.init({
    ...ddConfig,
    forwardErrorsToLogs: true,
    sampleRate: 100,
});

datadogRum.init({
    ...ddConfig,
    sampleRate: 50,
    replaySampleRate: 0,
    trackInteractions: true,
});

datadogRum.startSessionReplayRecording();

root.render(
    <StrictMode>
        <Providers
            options={{ appName: '' }}
            theme={createTheme(lightTheme)}
            maxNotifications={3}
        >
            <CssBaseline />
            <App />
        </Providers>
    </StrictMode>,
);

serviceWorker.unregister();
