import {
    TextField,
    Grid,
    InputAdornment,
    FormLabel, capitalize,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { useFormContext, Controller } from 'react-hook-form';

import SectionPaper from 'components/sectionPaper';

import { FormattedMessage } from 'react-intl';
import styles from './styles';
import { FormRadioButtons } from '../../../../components';

const EnvironmentPane = ({ classes }) => {
    const { control } = useFormContext();

    return (
        <SectionPaper className={classes.section} title={<FormattedMessage defaultMessage="Environment" id="environment" />}>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <Controller
                        name="houseSizeSqM"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                className={classes.text}
                                {...field}
                                variant="outlined"
                                label={(
                                    <FormattedMessage
                                        defaultMessage="House size"
                                        id="house_size"
                                    />
                                )}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><span>&#13217;</span></InputAdornment>,
                                }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item md={8}>
                    <Controller
                        name="nearPublicTransport"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                className={classes.text}
                                {...field}
                                variant="outlined"
                                label={<FormattedMessage defaultMessage="Public Transport" id="public_transport" />}
                                fullWidth
                                multiline
                                rows={2}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={4}>
                    <FormRadioButtons
                        control={control}
                        name="roomForCaregiver"
                        label={(
                            <FormattedMessage
                                defaultMessage="Room For Caregiver"
                                id="room_for_caregiver"
                            />
                        )}
                        options={['yes', 'no']}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={4}>
                    <FormRadioButtons
                        control={control}
                        name="smokingAllowed"
                        label={(
                            <FormattedMessage
                                defaultMessage="Smoking allowed"
                                id="smoking_allowed"
                            />
                        )}
                        options={['yes', 'no']}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={4}>
                    <FormRadioButtons
                        control={control}
                        name="hasWifi"
                        label={(
                            <FormattedMessage
                                defaultMessage="WiFi"
                                id="wifi"
                            />
                        )}
                        options={['yes', 'no']}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12}>
                    <FormLabel>
                        <FormattedMessage
                            defaultMessage="Pets"
                            id="pets"
                        />
                    </FormLabel>
                </Grid>
                <Grid item md={3}>
                    <Controller
                        name="pets.dogs"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                className={classes.text}
                                {...field}
                                variant="outlined"
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Dogs"
                                        id="dogs"
                                    />
                                )}
                                type="number"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item md={3}>
                    <Controller
                        name="pets.cats"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                className={classes.text}
                                {...field}
                                variant="outlined"
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Cats"
                                        id="cats"
                                    />
                                )}
                                type="number"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6}>
                    <Controller
                        name="pets.other"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                className={classes.text}
                                {...field}
                                variant="outlined"
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Other pet"
                                        id="other_pet"
                                    />
                                )}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12}>
                    <Controller
                        name="environmentNotes"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                className={classes.text}
                                {...field}
                                variant="outlined"
                                label={(
                                    <FormattedMessage
                                        defaultMessage="Environment Notes"
                                        id="environment_notes"
                                    />
                                )}
                                fullWidth
                                multiline
                                rows={3}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </SectionPaper>
    );
};

export default withStyles(styles)(EnvironmentPane);
