import { withProps } from 'libraries/components';
import { translations } from 'translations/translations';
import { language } from 'models/ui';
import { IntlProvider as Provider } from 'react-intl';

const IntlProvider = ({ language, children }) => (
    <Provider
        messages={translations[language]}
        locale={language}
        defaultLocale="en"
    >
        { children }
    </Provider>
);
export default withProps({ language })(IntlProvider);
