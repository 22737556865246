import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    TextField,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const AccountGrid = () => {
    const { control } = useFormContext();

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {' '}
                <Typography> Click Here to View </Typography>
                {' '}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <List>
                            <ListItem>
                                <Controller
                                    name="id"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="ID"
                                                    id="id"
                                                />
                                            )}
                                            variant="standard"
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </ListItem>
                            <ListItem>
                                <Controller
                                    name="registerDate"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="Date registered"
                                                    id="date_registered"
                                                />
                                            )}
                                            variant="standard"
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </ListItem>
                            <ListItem>
                                <Controller
                                    name="lastLoginDate"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="Last logged in"
                                                    id="last_logged_in"
                                                />
                                            )}
                                            variant="standard"
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={4}>
                        <List>
                            <ListItem>
                                <Controller
                                    name="dateActivated"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="Date activated"
                                                    id="date_activated"
                                                />
                                            )}
                                            variant="standard"
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </ListItem>
                            <ListItem>
                                <Controller
                                    name="credits"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="Credits"
                                                    id="credits"
                                                />
                                            )}
                                            variant="standard"
                                            fullWidth
                                        />
                                    )}
                                />
                            </ListItem>
                            <ListItem>
                                <Controller
                                    name="slugName"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField {...field} label="Slug name" variant="standard" fullWidth />
                                    )}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={4}>
                        <List>
                            <ListItem>
                                <Controller
                                    name="slugSurname"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="Slug Surname"
                                                    id="slug_surname"
                                                />
                                            )}
                                            variant="standard"
                                            fullWidth
                                        />
                                    )}
                                />
                            </ListItem>

                            <ListItem>
                                <Controller
                                    name="softOneId"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="SoftOne ID"
                                                    id="softone_id"
                                                />
                                            )}
                                            variant="standard"
                                            fullWidth
                                        />
                                    )}
                                />
                            </ListItem>
                            <ListItem>
                                <Controller
                                    name="softOneSyncedDate"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="SoftOne Sync Date"
                                                    id="softone_sync_date"
                                                />
                                            )}
                                            variant="standard"
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </ListItem>
                            <ListItem>
                                <Controller
                                    name="paidFromAdmin"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <FormControlLabel
                                            control={(
                                                <Checkbox
                                                    checked={!!value}
                                                    onChange={(e) => onChange(e.target.checked)}
                                                />
                                            )}
                                            label={(
                                                <FormattedMessage
                                                    defaultMessage="Paid from admin"
                                                    id="paid_from_admin"
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccountGrid;
