import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';

import configStore from 'libraries/configStore';
import { epics as systemEpics } from 'libraries/system';
import { loggingMiddleware } from 'services/logger';

import { reducer as ui, epics as uiEpics } from 'models/ui';
import { reducer as notifications, epics as notificationsEpics, sendNotification } from 'models/notifications';
import { reducer as featureFlags, epics as featureFlagsEpics, updateFeatureFlag } from 'models/featureFlags';
import { reducer as user, epics as userEpics } from 'models/user';
import { reducer as caregivers, epics as caregiversEpics } from 'models/caregivers';
import { reducer as settings, epics as settingsEpics } from 'models/settings';
import { reducer as relatives, epics as relativesEpics } from 'models/relatives';
import { reducer as app, epics as appEpics } from 'models/app';
import { reducer as dashboard, epics as dashboardEpics } from 'models/dashboard';
import { reducer as operations, epics as operationsEpics } from 'models/operations';

const actionLogger = loggingMiddleware(
    sendNotification.type,
    updateFeatureFlag.type,
    updateFeatureFlag.succeeded.type,
    updateFeatureFlag.failed.type,
);

const ModelProvider = ({ options, children }) => {
    const rootReducer = combineReducers({
        ui,
        user,
        featureFlags,
        notifications,
        caregivers,
        settings,
        relatives,
        app,
        dashboard,
        operations,
    });

    const rootEpic = combineEpics(
        systemEpics,
        notificationsEpics,
        featureFlagsEpics,
        userEpics,
        caregiversEpics,
        settingsEpics,
        relativesEpics,
        appEpics,
        dashboardEpics,
        operationsEpics,
        uiEpics,
    );

    const epicMiddleware = createEpicMiddleware({
        dependencies: { options },
    });

    const middleWares = [epicMiddleware, actionLogger];
    const store = configStore(rootReducer, {}, middleWares);

    epicMiddleware.run(rootEpic);

    return <Provider store={store}>{children}</Provider>;
};

export default ModelProvider;
