import { createSelector } from 'reselect';

const searchInputValue = ({ app }) => app.searchInputValue;
const searchResults = ({ app }) => app.searchResults;
const searchFilters = ({ app }) => app.searchFilters;
const searchLoading = ({ app }) => app.searchLoading;
const recentlyViewed = ({ app }) => app.recentlyViewed;

const searchResultsOpen = createSelector(searchResults, (results) => results.length > 0);
const recentlyViewedCaregivers = createSelector(
    recentlyViewed,
    (recentlyViewed) => recentlyViewed.caregivers,
);
const recentlyViewedRelatives = createSelector(
    recentlyViewed,
    (recentlyViewed) => recentlyViewed.relatives,
);

export {
    searchInputValue,
    searchResults,
    searchFilters,
    searchLoading,

    searchResultsOpen,
    recentlyViewedCaregivers,
    recentlyViewedRelatives,
};
