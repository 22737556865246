import { Avatar, Typography } from '@mui/material';
import { compose } from 'redux';
import withStyles from '@mui/styles/withStyles';
import { users } from 'models/settings';
import { withProps } from 'libraries/components';
import styles from './styles';

const RepRenderer = ({ id, classes, users }) => {
    const user = users?.find((user) => user.id === id);
    return (
        user ? (
            <span className={classes.inputSpan}>
                <Avatar
                    alt={user.displayName}
                    src={user.photoURL}
                    className={classes.avatar}
                    imgProps={{ referrerpolicy: 'no-referrer' }}
                />
                {user.displayName}
            </span>
        )
            : <Typography> - </Typography>
    );
};
export default compose(
    withStyles(styles),
    withProps({ users }),
)(RepRenderer);
