import StepConnector from '@mui/material/StepConnector';
import withStyles from '@mui/styles/withStyles';

const Connector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(124, 80, 107) 0%,rgb(233,64,87) 50%,rgb(124, 80, 107) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(124, 80, 107) 0%,rgb(233,64,87) 50%,rgb(124, 80, 107) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

export default Connector;
