import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    FormControlLabel,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';
import SectionPaper from '../sectionPaper';

import {
    days,
    times,
    alwaysAvailable,
} from './helper';

const Timetable = ({
    header = <FormattedMessage
        defaultMessage="Availability"
        id="availability"
    />,
    onChange = () => {},
    value = [],
}) => (
    <SectionPaper title={header}>
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={value.length === 49}
                                        indeterminate={value.length > 0 && value.length < 49}
                                        onChange={(event) => {
                                            onChange(event.target.checked ? alwaysAvailable : []);
                                        }}
                                        name="Mon"
                                    />
                                )}
                                label="24/7"
                            />
                        </TableCell>
                        {days.map((day) => (
                            <TableCell align="center" key={day}>
                                <FormattedMessage defaultMessage={day} id={day} />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {times.map((timeframe, row) => (
                        <TableRow
                            key={timeframe}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <FormattedMessage defaultMessage={timeframe} id={timeframe} />
                            </TableCell>
                            {days.map((day, cell) => {
                                const boxKey = `${row + 1}-${cell + 1}`;
                                return (
                                    <TableCell align="center" key={day}>
                                        <Checkbox
                                            checked={value.includes(boxKey)}
                                            onChange={(event) => {
                                                onChange(event.target.checked
                                                    ? [...value, boxKey]
                                                    : value.filter((key) => key !== boxKey));
                                            }}
                                        />
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </SectionPaper>
);

export default Timetable;
