import { Typography, Divider } from '@mui/material';

import env from 'env';

const General = () => (
    <>
        <Typography variant="h5">
            Grandmama - Dashboard
        </Typography>
        <Divider />
        <Typography>
            Version: 1.0.0
        </Typography>
        <Typography>
            {`Build: ${env.circleCIBuildNum}`}
        </Typography>
        <Typography>
            {`Stage: ${env.isProd ? 'Production' : 'Development'}`}
        </Typography>
        <Divider />
        <Typography fontWeight="bold">
            Caregivers
        </Typography>
        <Typography>
            {`  Collection: ${env.caregiversCollection}`}
        </Typography>
        <Typography>
            {`  Search Index: ${env.caregiversIndex}`}
        </Typography>
        <Typography fontWeight="bold">
            Relatives
        </Typography>
        <Typography>
            {`  Collection: ${env.relativesCollection}`}
        </Typography>
        <Typography>
            {`  Search Index: ${env.relativesIndex}`}
        </Typography>
    </>
);

export default General;
