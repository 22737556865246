import { createSelector } from 'reselect';

export const caregiversRegistered = ({ dashboard }) => dashboard.caregiversRegistered;
export const relativesRegistered = ({ dashboard }) => dashboard.relativesRegistered;
export const totalCaregivers = ({ dashboard }) => dashboard.totalCaregivers;
export const totalRelatives = ({ dashboard }) => dashboard.totalRelatives;
export const availableCaregivers = ({ dashboard }) => dashboard.availableCaregivers;
export const notReviewedCaregivers = ({ dashboard }) => dashboard.notReviewedCaregivers;
export const relativesInNeed = ({ dashboard }) => dashboard.relativesInNeed;
export const contractDurationGroups = ({ dashboard }) => dashboard.contractDurationGroups;
export const caregiversRegisteredLastWeek = ({ dashboard }) => (
    dashboard.caregiversRegisteredLastWeek
);
export const relativesRegisteredLastWeek = ({ dashboard }) => dashboard.relativesRegisteredLastWeek;

export const dashboardLoading = ({ dashboard }) => dashboard.loading;

export const loadingCaregiversRegistered = createSelector(
    dashboardLoading,
    (loading) => loading.caregiversRegistered,
);

export const loadingRelativesRegistered = createSelector(
    dashboardLoading,
    (loading) => loading.relativesRegistered,
);

export const loadingTotalCaregivers = createSelector(
    dashboardLoading,
    (loading) => loading.totalCaregivers,
);

export const loadingTotalRelatives = createSelector(
    dashboardLoading,
    (loading) => loading.totalRelatives,
);

export const loadingAvailableCaregivers = createSelector(
    dashboardLoading,
    (loading) => loading.availableCaregivers,
);

export const loadingNotReviewedCaregivers = createSelector(
    dashboardLoading,
    (loading) => loading.notReviewedCaregivers,
);

export const loadingRelativesInNeed = createSelector(
    dashboardLoading,
    (loading) => loading.relativesInNeed,
);

export const loadingContractDurationGroups = createSelector(
    dashboardLoading,
    (loading) => loading.contractDurationGroups,
);

export const loadingCaregiversRegisteredLastWeek = createSelector(
    dashboardLoading,
    (loading) => loading.caregiversRegisteredLastWeek,
);

export const loadingRelativesRegisteredLastWeek = createSelector(
    dashboardLoading,
    (loading) => loading.relativesRegisteredLastWeek,
);
