import { TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import SectionPaper from 'components/sectionPaper';

const Bio = () => {
    const { control } = useFormContext();

    return (
        <SectionPaper
            title={(
                <FormattedMessage
                    defaultMessage="Bio"
                    id="bio"
                />
            )}
            noMarginTop
        >
            <Controller
                name="bio"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <TextField
                        {...field}
                        variant="standard"
                        multiline
                        fullWidth
                    />
                )}
            />
        </SectionPaper>
    );
};

export default Bio;
