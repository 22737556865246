const styles = () => ({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(124, 80, 107) 0%, rgb(124, 80, 107) 50%, rgb(124, 80, 107) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(124, 80, 107) 0%, rgb(124, 80, 107) 50%, rgb(124, 80, 107) 100%)',
    },
});

export default styles;
