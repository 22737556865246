import clsx from 'clsx';
import { compose } from 'redux';

import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';

import {
    AppBar as MuiAppBar,
    Toolbar,
    IconButton,
    Typography,
    Collapse,
    ClickAwayListener,
    LinearProgress,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { Search, SearchResults } from 'components';
import { withProps } from 'libraries/components';
import {
    drawerOpen,
    openDrawer,
    openUserDrawer,
    searchResultsVisible,
    hideSearchResults,
} from 'models/ui';
import { searchLoading } from 'models/app';

import { RecentlyViewed } from './components';
import styles from './styles';

const AppBar = ({
    classes,
    drawerOpen,
    openDrawer,
    openUserDrawer,
    searchResultsVisible,
    hideSearchResults,
    searchLoading,
}) => (
    <ClickAwayListener onClickAway={() => searchResultsVisible && hideSearchResults()}>
        <MuiAppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: drawerOpen,
            })}
            elevation={searchResultsVisible ? 4 : 0}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => openDrawer()}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: drawerOpen,
                    })}
                    size="large"
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap />
                <div className={classes.spacer} />
                <Search />
                <div className={classes.spacer} />
                <RecentlyViewed />
                <IconButton size="large">
                    <NotificationsIcon className={classes.icon} />
                </IconButton>
                <IconButton onClick={() => openUserDrawer()} size="large">
                    <AccountCircleIcon className={classes.icon} />
                </IconButton>
            </Toolbar>
            <Collapse in={searchLoading}>
                <LinearProgress />
            </Collapse>
            <Collapse in={searchResultsVisible}>
                <SearchResults />
            </Collapse>
        </MuiAppBar>
    </ClickAwayListener>
);

export default compose(
    withStyles(styles),
    withProps({
        drawerOpen,
        openDrawer,
        openUserDrawer,
        searchResultsVisible,
        hideSearchResults,
        searchLoading,
    }),
)(AppBar);
