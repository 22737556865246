import { Table } from 'components/table';

import { withProps } from 'libraries/components';
import {
    relatives,
    totalRelatives,
    loadingRelatives,
    pagination,
    setRelativeItemsPerPage,
    setCurrentRelativesPage,
} from 'models/relatives';

import { users } from 'models/settings';

import { elGR } from '@mui/x-data-grid';

import { useIntl } from 'react-intl';
import { capitalize } from '@mui/material';
import { columns } from './columns';

const ListView = ({
    relatives,
    totalRelatives,
    loadingRelatives,
    pagination,
    setRelativeItemsPerPage,
    setCurrentRelativesPage,
    users,
}) => {
    const intl = useIntl();
    const formattedColumns = columns(users).map((el) => ({
        ...el,
        headerName: intl.formatMessage({
            id: el.headerName,
            defaultMessage: capitalize(el.headerName),
        }),
    }
    ));

    return (
        <Table
            rows={relatives}
            rowCount={totalRelatives}
            columns={formattedColumns}
            loading={loadingRelatives}
            pagination={pagination}
            setItemsPerPage={setRelativeItemsPerPage}
            setCurrentPage={setCurrentRelativesPage}
            {...(intl.locale === 'el' ? { localeText: elGR.components.MuiDataGrid.defaultProps.localeText } : {})}
        />
    );
};

export default withProps({
    relatives,
    totalRelatives,
    loadingRelatives,
    pagination,
    setRelativeItemsPerPage,
    setCurrentRelativesPage,
    users,
})(ListView);
