import { Tooltip } from '@mui/material';
import { CreditCardOff, Done, DoneAll } from '@mui/icons-material';

const HasPayed = ({ plan }) => {
    if (plan?.payedInitial && plan?.payedFull) {
        return <Tooltip title="Payed Full"><DoneAll sx={{ color: 'success.main' }} /></Tooltip>;
    } if (plan?.payedInitial || plan?.payedFull) {
        return <Tooltip title="Payed Initial"><Done sx={{ color: 'warning.main' }} /></Tooltip>;
    }
    return <Tooltip title="Hasn't Payed"><CreditCardOff sx={{ color: 'error.main' }} /></Tooltip>;
};

export default HasPayed;
