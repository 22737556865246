import {
    NEW_CASE,
    MATCHING,
    MATCHED,
    CLOSED,
    CANCELLED,
    STATUSES,
} from 'constants/statuses';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initState = {
    cardsLoading: false,
    board: {
        columns: {
            [NEW_CASE]: {
                id: 1,
                title: STATUSES[NEW_CASE].label,
                cards: [],
            },
            [MATCHING]: {
                id: 2,
                title: STATUSES[MATCHING].label,
                cards: [],
            },
            [MATCHED]: {
                id: 3,
                title: STATUSES[MATCHED].label,
                cards: [],
            },
            [CLOSED]: {
                id: 4,
                title: STATUSES[CLOSED].label,
                cards: [],
            },
            [CANCELLED]: {
                id: 5,
                title: STATUSES[CANCELLED].label,
                cards: [],
            },
        },
    },
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.loadOperations, (state) => {
        state.cardsLoading = true;
    })
    .addCase(actions.loadOperations.succeeded, (state, { payload }) => {
        const { relatives, caregivers } = payload;

        Object.entries(relatives).forEach(([status, relativesInStatus]) => {
            state.board.columns[status].cards = relativesInStatus;
        });

        Object.entries(caregivers).forEach(([status, caregiversInStatus]) => {
            state.board.columns[status].cards.push(...caregiversInStatus);
        });
        state.cardsLoading = false;
    })
    .addCase(actions.loadOperations.failed, (state) => {
        state.cardsLoading = false;
    })
    .addCase(actions.moveCard, (state, { payload }) => {
        const { newStatus, from, to } = payload;
        const { fromPosition, fromColumnId } = from;
        const { toPosition } = to;

        const oldStatus = Object.values(STATUSES)[fromColumnId - 1]?.value;

        const card = state.board.columns[oldStatus].cards[fromPosition];
        state.board.columns[oldStatus].cards.splice(fromPosition, 1);
        state.board.columns[newStatus].cards.splice(toPosition, 0, card);
        state.cardsLoading = true;
    })
    .addCase(actions.moveCard.succeeded, (state) => {
        state.cardsLoading = false;
    })
    .addCase(actions.moveCard.failed, (state) => {
        state.cardsLoading = false;
    }));

export default reducer;
