import { compose } from 'redux';
import { Typography, DialogContent, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useFormContext, Controller } from 'react-hook-form';

import { PhoneInput } from '@grandmama/ui-phone-input';

import { FormattedMessage } from 'react-intl';
import styles from './styles';

const SendLinkTab = ({ classes, isVisible }) => {
    const { control } = useFormContext();

    return (
        <DialogContent>
            <div className={classes.spacer} />
            <Typography>
                <FormattedMessage
                    id="send_link_message"
                    defaultMessage="Please type a number to send a link with the caregiver app."
                />
            </Typography>
            <div className={classes.spacer} />
            <Controller
                name="phoneNumber"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <PhoneInput
                        {...field}
                        label="Phone Number"
                        fullWidth
                        autoFocus
                        error={error?.message}
                        helperText={error?.message}
                        disabled={!isVisible}
                    />
                )}
            />
            <div className={classes.spacer} />
            <Controller
                name="message"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Message"
                        multiline
                        fullWidth
                        maxRows={7}
                        error={error?.message}
                        helperText={error?.message}
                        disabled={!isVisible}
                    />
                )}
            />
        </DialogContent>
    );
};

export default compose(
    withStyles(styles),
)(SendLinkTab);
