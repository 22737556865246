import { STATUSES } from 'constants/statuses';
import { Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { FormattedMessage } from 'react-intl';
import styles from './styles';

const StatusPill = ({ status, ...pillProps }) => (Object.keys(STATUSES).includes(status) ? (
    <Chip
        label={(
            <FormattedMessage
                id={STATUSES[status]?.label}
                defaultMessage={STATUSES[status]?.label}
            />
        )}
        color={STATUSES[status]?.color}
        {...pillProps}
    />
) : null);

export default withStyles(styles)(StatusPill);
