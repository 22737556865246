const drawerWidth = 240;

const styles = (theme) => ({
    bottomBar: {
        top: 'auto',
        bottom: 0,
        paddingLeft: 72,
    },
    bottomBarShift: {
        marginLeft: drawerWidth,
        paddingLeft: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
});

export default styles;
