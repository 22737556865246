const styles = (theme) => ({
    card: {
        minWidth: '220px',
        width: '220px',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    avatar: {
        width: '28px',
        height: '28px',
        marginRight: '2px',
        fontSize: '11px',
        display: 'inline-flex',
    },
});

export default styles;
