import { useEffect } from 'react';
import { compose } from 'redux';
import {
    Accordion,
    AccordionDetails,
    TextField,
    AccordionSummary,
    Typography,
    Button,
    Grid,
    Avatar,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withStyles from '@mui/styles/withStyles';
import { useForm, Controller } from 'react-hook-form';
import { Checkboxes } from 'components';
import { sentenceCase } from 'change-case';

import { withProps } from 'libraries/components';
import { updateUser, permissions, updating } from 'models/settings';

import styles from './styles';

const UserInfo = ({
    classes,
    user,
    updateUser,
    permissions,
    updating,
    key,
}) => {
    const {
        control,
        formState,
        handleSubmit,
        reset,
        setValue,
        getValues,
    } = useForm({
        defaultValues: user,
    });

    useEffect(() => reset(user), [user, permissions]);

    return (
        <Accordion key={key}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>
                    {user?.displayName}
                </Typography>
                <Typography className={classes.secondaryHeading}>
                    {user?.role ?? 'New User'}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Avatar
                            className={classes.avatar}
                            src={user?.photoURL}
                            imgProps={{
                                referrerpolicy: 'no-referrer',
                            }}
                        />
                        <Button
                            className={classes.editButton}
                            size="small"
                        >
                            Edit
                        </Button>
                    </Grid>
                    <Grid item md={9}>
                        <form onSubmit={handleSubmit(updateUser)} noValidate>
                            <Controller
                                name="displayName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        className={classes.field}
                                        label="Full Name"
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="role"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        className={classes.field}
                                        label="Role"
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                            <div>
                                <Checkboxes
                                    control={control}
                                    name="permissions"
                                    label="User Permissions"
                                    setValue={setValue}
                                    initialValues={getValues('permissions')}
                                    data={permissions.map((perm) => ({
                                        label: sentenceCase(perm),
                                        value: perm,
                                    }))}
                                />
                            </div>
                            <Button
                                className={classes.submitButton}
                                disabled={!formState.isDirty || updating}
                                type="submit"
                                color="primary"
                                variant="contained"
                            >
                                Update User
                            </Button>
                        </form>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        updateUser,
        permissions,
        updating,
    }),
)(UserInfo);
