import {
    Bio,
    ProfileProgress,
    GeneralInfo,
    Address,
    Availability,
} from './components';

const ProfileSection = () => (
    <>
        <ProfileProgress />
        <Bio />
        <GeneralInfo />
        <Address />
        <Availability />
    </>
);

export default ProfileSection;
