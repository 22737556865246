import { Grid, CircularProgress, Typography } from '@mui/material';

const LoadingCover = ({ children, isLoading, text = 'Loading ...' }) => (isLoading ? (
    <Grid
        container
        alignItems="center"
        direction="column"
        style={{ flex: 1 }}
    >
        <CircularProgress />
        <Typography style={{ marginTop: '4px' }}>{text}</Typography>
    </Grid>
) : (
    children || null
));

export default LoadingCover;
