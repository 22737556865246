const styles = (theme) => ({
    fab: {
        position: 'fixed',
        bottom: '2em',
        right: '2em',
    },
    raisedFab: {
        bottom: '4em',
        transition: theme.transitions.create(['bottom'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.short,
        }),
    },
});

export default styles;
