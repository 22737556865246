import { CAREGIVERS, RELATIVES } from 'constants/permissions';
import { CLOSED, CANCELLED } from 'constants/statuses';
import {
    combineEpics,
    ofType,
} from 'redux-observable';
import {
    forkJoin,
    from,
} from 'rxjs';
import {
    catchError,
    mergeMap,
    map,
    filter,
} from 'rxjs/operators';
import { groupBy } from 'lodash';

import env from 'env';
import { db as relativesDb, firebase } from 'services/relatives';
import { db as caregiversDb } from 'services/caregivers';

import { sendNotification } from 'models/notifications';
import { loadOperations, moveCard } from './actions';

const getActiveAccounts = (db, collection) => db
    .collection(collection)
    .where('caseStatus', 'not-in', [CLOSED, CANCELLED])
    .get();

const getRecentlyClosedCases = (db, collection) => db
    .collection(collection)
    .where('caseStatus', 'in', [CLOSED, CANCELLED])
    .where('statusChangedOn', '>', firebase.firestore.Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)))
    .get();

const getAccounts = (db, collection) => Promise.all(
    [getActiveAccounts(db, collection), getRecentlyClosedCases(db, collection)],
)
    .then(([active, recentlyClosed]) => [...active?.docs, ...recentlyClosed?.docs])
    .catch((error) => console.log(error));

const fetchOperationsEpic = (action$, state$) => action$.pipe(
    ofType(loadOperations.type, moveCard.failed.type),
    map(() => [
        state$.value.user.info.permissions.includes(RELATIVES),
        state$.value.user.info.permissions.includes(CAREGIVERS),
    ]),
    mergeMap(([hasRelativesAccess, hasCaregiversAccess]) => forkJoin([
        ...(hasRelativesAccess
            ? [getAccounts(relativesDb, env.relativesCollection)]
            : [new Promise((resolve) => resolve([]))]),
        ...(hasCaregiversAccess
            ? [getAccounts(caregiversDb, env.caregiversCollection)]
            : [new Promise((resolve) => resolve([]))]),
    ])),
    map(([relatives, caregivers]) => [
        relatives.map((doc) => ({ id: doc.id, ...(doc.data()), type: RELATIVES })),
        caregivers.map((doc) => ({ id: doc.id, ...(doc.data()), type: CAREGIVERS })),
    ]),
    map(([relatives, caregivers]) => [
        relatives.map((relative) => ({
            ...relative,
            statusChangedOn: relative.statusChangedOn?.toDate(),
        })),
        caregivers.map((caregiver) => ({
            ...caregiver,
            statusChangedOn: caregiver.statusChangedOn?.toDate(),
        })),
    ]),
    map(([relatives, caregivers]) => [
        groupBy(relatives, 'caseStatus'),
        groupBy(caregivers, 'caseStatus'),
    ]),
    map(([relatives, caregivers]) => loadOperations.succeeded({ relatives, caregivers })),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        loadOperations.failed(error),
    ])),
);

const changeStatusEpic = (action$) => action$.pipe(
    ofType(moveCard.type),
    filter(({ payload }) => payload.from?.fromColumnId !== payload.to.toColumnId),
    mergeMap(({ payload }) => {
        const { type, id, newStatus } = payload;
        const db = type === RELATIVES ? relativesDb : caregiversDb;
        const collection = type === RELATIVES ? env.relativesCollection : env.caregiversCollection;

        return db
            .collection(collection)
            .doc(id)
            .update({
                caseStatus: newStatus,
                statusChangedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            });
    }),
    map(() => moveCard.succeeded()),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        sendNotification({
            message: "Couldn't change status, please try again",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'info',
            },
        }),
        moveCard.failed(error),
    ])),
);

export default combineEpics(
    fetchOperationsEpic,
    changeStatusEpic,
);
