import Cookies from 'js-cookie';
import { combineEpics, ofType } from 'redux-observable';
import { ignoreElements, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as actions from './actions';

const readLanguageEpic = () => of([]).pipe(
    map(() => {
        const language = Cookies.get('language');
        return actions.changeLanguage(language ?? 'en');
    }),

);
const persistLanguageEpic = (actions$) => actions$.pipe(
    ofType(actions.changeLanguage.type),
    tap(({ payload }) => {
        Cookies.set('language', payload);
    }),
    ignoreElements(),
);

export default combineEpics(
    readLanguageEpic,
    persistLanguageEpic,
);
