import { useEffect, useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
} from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';

const Checkboxes = ({
    name,
    control,
    setValue,
    label,
    data,
    initialValues,
    className,
}) => {
    const value = useWatch({
        control,
        name,
        defaultValue: initialValues ?? [],
    });
    const [selectedItems, setSelectedItems] = useState(value ?? []);

    useEffect(() => {
        setSelectedItems(value ?? []);
    }, [value]);

    const handleSelect = (value) => {
        const isPresent = selectedItems.indexOf(value);
        if (isPresent !== -1) {
            const remaining = selectedItems.filter((item) => item !== value);
            setSelectedItems(remaining);
        } else {
            setSelectedItems((prevItems) => [...prevItems, value]);
        }
    };

    useEffect(() => {
        if (selectedItems?.length !== value?.length) {
            setValue(name, selectedItems, { shouldDirty: true });
        }
    }, [selectedItems]);

    return (
        <FormControl size="small" variant="outlined" className={className}>
            <FormLabel component="legend">{label}</FormLabel>

            <div>
                {data.map((option) => (
                    <FormControlLabel
                        control={(
                            <Controller
                                name={name}
                                render={() => (
                                    <Checkbox
                                        checked={selectedItems?.includes(option.value)}
                                        onChange={() => handleSelect(option.value)}
                                    />
                                )}
                                control={control}
                            />
                        )}
                        label={option.label}
                        key={option.value}
                    />
                ))}
            </div>
        </FormControl>
    );
};

export default Checkboxes;
