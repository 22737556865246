import { VIP_PLAN_ID } from 'constants/plans';
import withStyles from '@mui/styles/withStyles';
import SectionPaper from 'components/sectionPaper';
import { useFormContext } from 'react-hook-form';

import { FormattedMessage } from 'react-intl';
import { VipPlanInfo, NoPlan } from './components';
import styles from './styles';

// TODO: create plans-service and store plans in database

const plans = {
    vip_plan_2022_08: {
        planId: 'vip_plan_2022_08',
        payedInitial: false,
        initialInvoiceSentOn: '',
        initialPaymentAmount: 50,
        dateOfInitialPayment: '',
        initialInvoiceNumber: '',
        payedFull: false,
        fullInvoiceSentOn: '',
        fullPaymentAmount: 249,
        dateOfFullPayment: '',
        fullInvoiceNumber: '',
    },
};

const getPlanComponent = (id) => ({
    [VIP_PLAN_ID]: VipPlanInfo,
}[id] ?? NoPlan);

const PlanInfo = ({
    classes,
}) => {
    const {
        control,
        watch,
        setValue,
        formState: {
            isDirty,
        },
    } = useFormContext();

    const plan = watch('plan', {}) ?? {};
    const planId = plan?.planId;
    const PlanComponent = getPlanComponent(planId);
    const startPlan = (planId) => setValue('plan', plans[planId], { shouldDirty: true });

    return (
        <SectionPaper className={classes.section} title={<FormattedMessage defaultMessage="Plan Info" id="plan_info" />}>
            <PlanComponent
                startPlan={startPlan}
                control={control}
                plan={plan}
                canSendInvoice={isDirty}
                isDirty={isDirty}
            />
        </SectionPaper>
    );
};

export default withStyles(styles)(PlanInfo);
