const environment = process.env.STAGE ?? 'dev';

const caregiversCollections = {
    dev: 'users_dev',
    prod: 'users',
};

const caregiversAlgoliaIndexes = {
    dev: 'dev_caregivers',
    prod: 'prod_caregivers',
};

const relativesCollections = {
    dev: 'relatives_dev',
    prod: 'relatives',
};

const userActivityCollections = {
    dev: 'user_activity_dev',
    prod: 'user_activity',
};

const relativesAlgoliaIndexes = {
    dev: 'dev_relatives',
    prod: 'prod_relatives',
};

const env = {
    isProd: environment === 'prod',
    stage: environment,
    smsAPIKey: JSON.parse(process.env.REACT_SMS_API_KEY)[environment],
    featureFlagsApiKey: JSON.parse(process.env.FEATURE_FLAGS_API_KEY)[environment],
    algoliaAppId: process.env.ALGOLIA_APP_ID,
    algoliaApiKey: process.env.ALGOLIA_API_KEY,
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,

    circleCIBuildNum: process.env.CIRCLE_BUILD_NUM ?? 'local',
    caregiversCollection: caregiversCollections[environment],
    relativesCollection: relativesCollections[environment],
    userActivityCollection: userActivityCollections[environment],
    caregiversIndex: caregiversAlgoliaIndexes[environment],
    relativesIndex: relativesAlgoliaIndexes[environment],
    datadogApiKey: process.env.DATADOG_API_KEY,
    datadogAppID: process.env.DATADOG_APP_ID,
};

export default env;
