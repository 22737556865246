export const layouts = {
    lg: [
        {
            w: 2,
            h: 1,
            x: 0,
            y: 0,
            i: 'totalCaregivers',
        },
        {
            w: 2,
            h: 1,
            x: 2,
            y: 0,
            i: 'totalRelatives',
        },
        {
            w: 3,
            h: 1,
            x: 4,
            y: 0,
            i: 'availableCaregivers',
        },
        {
            w: 3,
            h: 1,
            x: 7,
            y: 0,
            i: 'notReviewedCaregivers',
        },
        {
            w: 2,
            h: 1,
            x: 10,
            y: 0,
            i: 'relativesInNeed',
        },
        {
            w: 4,
            h: 2,
            x: 0,
            y: 1,
            i: 'caregiversRegistered',
        },
        {
            w: 4,
            h: 2,
            x: 4,
            y: 1,
            i: 'relativesRegistered',
        },
        {
            w: 4,
            h: 2,
            x: 8,
            y: 1,
            i: 'contractDurationChart',
        },
        {
            w: 12,
            h: 2,
            x: 0,
            y: 2,
            i: 'usersPerDay',
        },
    ],
    md: [
        {
            w: 4,
            h: 1,
            x: 0,
            y: 0,
            i: 'totalCaregivers',
        },
        {
            w: 4,
            h: 1,
            x: 4,
            y: 0,
            i: 'totalRelatives',
        },
        {
            w: 4,
            h: 1,
            x: 0,
            y: 1,
            i: 'availableCaregivers',
        },
        {
            w: 4,
            h: 1,
            x: 0,
            y: 2,
            i: 'notReviewedCaregivers',
        },
        {
            w: 2,
            h: 1,
            x: 10,
            y: 0,
            i: 'relativesInNeed',
        },
        {
            w: 5,
            h: 2,
            x: 0,
            y: 3,
            i: 'caregiversRegistered',
        },
        {
            w: 5,
            h: 2,
            x: 6,
            y: 3,
            i: 'relativesRegistered',
        },
        {
            w: 6,
            h: 2,
            x: 4,
            y: 1,
            i: 'contractDurationChart',
        },
        {
            w: 12,
            h: 2,
            x: 0,
            y: 4,
            i: 'usersPerDay',
        },
    ],
    sm: [
        {
            w: 2,
            h: 1,
            x: 0,
            y: 0,
            i: 'totalCaregivers',
        },
        {
            w: 2,
            h: 1,
            x: 2,
            y: 0,
            i: 'totalRelatives',
        },
        {
            w: 2,
            h: 1,
            x: 4,
            y: 0,
            i: 'availableCaregivers',
        },
        {
            w: 3,
            h: 1,
            x: 0,
            y: 2,
            i: 'notReviewedCaregivers',
        },
        {
            w: 3,
            h: 1,
            x: 3,
            y: 2,
            i: 'relativesInNeed',
        },
        {
            w: 3,
            h: 2,
            x: 0,
            y: 3,
            i: 'caregiversRegistered',
        },
        {
            w: 3,
            h: 2,
            x: 3,
            y: 3,
            i: 'relativesRegistered',
        },
        {
            w: 6,
            h: 2,
            x: 4,
            y: 1,
            i: 'contractDurationChart',
        },
        {
            w: 6,
            h: 2,
            x: 0,
            y: 4,
            i: 'usersPerDay',
        },
    ],
    xs: [
        {
            w: 2,
            h: 1,
            x: 0,
            y: 0,
            i: 'totalCaregivers',
        },
        {
            w: 2,
            h: 1,
            x: 2,
            y: 0,
            i: 'totalRelatives',
        },
        {
            w: 2,
            h: 1,
            x: 0,
            y: 1,
            i: 'availableCaregivers',
        },
        {
            w: 2,
            h: 1,
            x: 2,
            y: 1,
            i: 'notReviewedCaregivers',
        },
        {
            w: 4,
            h: 1,
            x: 1,
            y: 2,
            i: 'relativesInNeed',
        },
        {
            w: 4,
            h: 2,
            x: 0,
            y: 3,
            i: 'caregiversRegistered',
        },
        {
            w: 4,
            h: 2,
            x: 0,
            y: 4,
            i: 'relativesRegistered',
        },
        {
            w: 6,
            h: 2,
            x: 4,
            y: 1,
            i: 'contractDurationChart',
        },
        {
            w: 6,
            h: 2,
            x: 0,
            y: 4,
            i: 'usersPerDay',
        },
    ],
};
