const styles = (theme) => ({
    paper: {
        padding: theme.spacing(3),
        margin: '12px 0',
    },
    noMarginTop: {
        marginTop: 0,
    },
});

export default styles;
