import { css } from '@emotion/react';

const styles = ({
    container: css({
        display: 'flex',
        justifyContent: 'end',
        marginBottom: '24px',
    }),
    icon: css({
        width: '40px',
        height: '40px',
    }),
    checkbox: css({
        paddingLeft: 0,
    }),
});

export default styles;
