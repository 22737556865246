export const days = [
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
];

export const times = [
    'early_morning',
    'morning',
    'early_afternoon',
    'afternoon',
    'evening',
    'night',
    'midnight',
];

export const alwaysAvailable = times.reduce((availability, _, time) => {
    const row = days.map((_, day) => `${time + 1}-${day + 1}`);
    return [...availability, ...row];
}, []);
