export const widgets = [
    {
        id: 'totalCaregivers',
        title: 'total_caregivers',
        layout: {
            i: 'caregiversRegistered', x: 0, y: 0, w: 2, h: 1, isDraggable: true,
        },
    },
    {
        id: 'totalRelatives',
        title: 'total_relatives',
        layout: {
            i: 'totalRelatives', x: 2, y: 0, w: 2, h: 1, isDraggable: true,
        },
    },
    {
        id: 'availableCaregivers',
        title: 'available_caregivers',
        layout: {
            i: 'availableCaregivers', x: 4, y: 0, w: 3, h: 1, isDraggable: true,
        },
    },
    {
        id: 'notReviewedCaregivers',
        title: 'not_reviewed_caregivers',
        layout: {
            i: 'notReviewedCaregivers', x: 7, y: 0, w: 3, h: 1, isDraggable: true,
        },
    },
    {
        id: 'relativesInNeed',
        title: 'relatives_in_need',
        layout: {
            i: 'relativesInNeed', x: 10, y: 0, w: 2, h: 1, isDraggable: true,
        },
    },
    {
        id: 'caregiversRegistered',
        title: 'caregivers_registered_today',
        layout: {
            i: 'caregiversRegistered', x: 0, y: 1, w: 3, h: 1, isDraggable: true,
        },
    },
    {
        id: 'relativesRegistered',
        title: 'relatives_registered_today',
        layout: {
            i: 'relativesRegistered', x: 3, y: 1, w: 3, h: 1, isDraggable: true,
        },
    },
    {
        id: 'contractDurationChart',
        title: 'contract_duration',
        layout: {
            i: 'contractDurationChart', x: 8, y: 1, w: 4, h: 2, isDraggable: true,
        },
    },
    {
        id: 'usersPerDay',
        title: 'caregivers_registered_last_week',
        layout: {
            i: 'caregiversPerDay', x: 0, y: 2, w: 12, h: 2, isDraggable: true,
        },
    },
];
