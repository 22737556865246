import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initState = {
    language: 'el',
    drawerOpen: true,
    userDrawerOpen: false,
    searchBarFocus: false,
    searchResultsOpen: false,
    dialog: {
        isOpen: false,
        title: '',
        type: '',
        description: '',
        props: null,
        dialogProps: {},
        showXButton: false,
        onClose: () => {},
    },
    showFab: false,
    fabIcon: undefined,
    fabProps: {},
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.openDrawer, (state) => {
        state.drawerOpen = true;
    })
    .addCase(actions.closeDrawer, (state) => {
        state.drawerOpen = false;
    })
    .addCase(actions.openUserDrawer, (state) => {
        state.userDrawerOpen = true;
    })
    .addCase(actions.closeUserDrawer, (state) => {
        state.userDrawerOpen = false;
    })
    .addCase(actions.setSearchBarFocus, (state, { payload }) => {
        state.searchBarFocus = payload;
        state.searchResultsOpen = true;
    })
    .addCase(actions.openDialog, (state, { payload }) => {
        state.dialog.isOpen = true;
        state.dialog.title = payload.title;
        state.dialog.type = payload.type;
        state.dialog.description = payload.description;
        state.dialog.props = payload.props;
        state.dialog.dialogProps = payload.dialogProps;
        state.dialog.showXButton = payload.showXButton;
        state.dialog.onClose = payload.onClose;
    })
    .addCase(actions.closeDialog, (state) => {
        state.dialog = initState.dialog;
    })
    .addCase(actions.setFabAttributes, (state, { payload }) => {
        state.showFab = payload.showFab;
        state.fabProps = payload.fabProps;
        state.fabIcon = payload.fabIcon;
    })
    .addCase(actions.setShowFab, (state, { payload }) => {
        state.showFab = payload;
    })
    .addCase(actions.showSearchResults, (state) => {
        state.searchResultsOpen = true;
    })
    .addCase(actions.hideSearchResults, (state) => {
        state.searchResultsOpen = false;
    })
    .addCase(actions.changeLanguage, (state, { payload }) => {
        state.language = payload;
    }));

export default reducer;
