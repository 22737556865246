import withStyles from '@mui/styles/withStyles';

import { useFormContext, Controller } from 'react-hook-form';

import SectionPaper from 'components/sectionPaper';
import { FormattedMessage } from 'react-intl';
import { CaregiversList } from './components';
import styles from './styles';

const EmployedCaregiversPane = ({ classes }) => {
    const { control } = useFormContext();

    return (
        <SectionPaper className={classes.section} title={<FormattedMessage defaultMessage="Employed Caregivers" id="employed_caregivers" />}>
            <Controller
                name="employedCaregivers"
                control={control}
                render={({ field }) => (
                    <CaregiversList {...field} />
                )}
            />
        </SectionPaper>
    );
};

export default withStyles(styles)(EmployedCaregiversPane);
