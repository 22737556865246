export const AVAILABLE_SERVICES = [
    'live_in',
    'live_out_full_time',
    'part_time',
    'overnight',
    'company_at_home',
    'company_on_vacation',
];

export const CONTRACT_DURATION_OPTIONS = [
    'permanent',
    'short_term',
];

export const CAREGIVER_SEX_OPTIONS = [
    'female',
    'male',
    'either',
];

export const GENERAL_OPTIONS = [
    'cooperative',
    'diapers',
    'hospital_bed',
    'catheter',
    'crane',
    'using_levin',
    'oxygen',
    'bed_rests_wounds',
];

export const SLEEP_OPTIONS = [
    'sleeps_at_night',
    'wakes_up_1_2_times',
    'wakes_up_over_3_times',
];

export const DEMENTIA_OPTIONS = [
    'memory_loss',
    'difficulty_speaking',
    'difficulty_doing_things',
    'change_of_personality',
    'weird_behaviour',
    'stress_and_depression',
    'can_not_sleep',
    'illusions',
    'other',
];

export const MOVEMENT_OPTIONS = [
    'walking_normally',
    'walking_with_assistance',
    'walking_with_stick',
    'needs_help_getting_up',
    'needs_wheelchair',
    'bedridden',
];

export const PATIENT_NEEDS_OPTIONS = [
    'shower',
    'personal_care',
    'food_preparation',
    'housekeeping',
    'prescription_reminder',
    'external_tasks',
    'exercise',
    'company',
];

export const OTHER_HEALTH_ISSUES_OPTIONS = [
    'parkinson',
    'diabetes',
    'heart_disease',
    'cancer',
    'stroke',
    'respiratory',
    'depression',
    'blood_pressure',
    'arthritis',
    'osteoporosis',
    'vision',
    'other',
];
