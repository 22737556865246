const firebaseConfig = {
    apiKey: 'AIzaSyCTh130Qus4ViZosTp3PI2m9jOwYn96a6U',
    authDomain: 'grandmama-dashboard.firebaseapp.com',
    projectId: 'grandmama-dashboard',
    storageBucket: 'grandmama-dashboard.appspot.com',
    messagingSenderId: '478251074557',
    appId: '1:478251074557:web:2e7fe27234eea5e8048f94',
};

export default firebaseConfig;
