import { Table } from 'components/table';
import { withProps } from 'libraries/components';
import {
    caregivers,
    loadingCaregivers,
    pagination,
    setCurrentPage,
    setItemsPerPage,
    totalCaregivers,
} from 'models/caregivers';
import { useIntl } from 'react-intl';
import { capitalize } from '@mui/material';
import { elGR } from '@mui/x-data-grid';
import { columns } from './columns';

const ListView = ({
    caregivers,
    pagination,
    totalCaregivers,
    loadingCaregivers,
    setItemsPerPage,
    setCurrentPage,
}) => {
    const intl = useIntl();
    const formattedColumns = columns.map((el) => ({
        ...el,
        headerName: intl.formatMessage({
            id: el.headerName,
            defaultMessage: capitalize(el.headerName),
        }),
    }
    ));
    return (
        <Table
            rows={caregivers}
            rowCount={totalCaregivers}
            columns={formattedColumns}
            loading={loadingCaregivers}
            pagination={pagination}
            setItemsPerPage={setItemsPerPage}
            setCurrentPage={setCurrentPage}
            {...(intl.locale === 'el' ? { localeText: elGR.components.MuiDataGrid.defaultProps.localeText } : {})}
        />
    );
};

export default withProps({
    caregivers,
    pagination,
    totalCaregivers,
    loadingCaregivers,
    setItemsPerPage,
    setCurrentPage,
})(ListView);
