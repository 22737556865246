import { createSelector } from '@reduxjs/toolkit';

const caregivers = ({ caregivers }) => caregivers.caregivers;
const view = ({ caregivers }) => caregivers.view;
const totalCaregivers = ({ caregivers }) => caregivers.totalCaregivers;
const loadingCaregivers = ({ caregivers }) => caregivers.loadingCaregivers;
const pagination = ({ caregivers }) => caregivers.pagination;
const expandedCaregiverProfile = ({ caregivers }) => caregivers.expandedCaregiverProfile;
const expandedCaregiverLoading = ({ caregivers }) => caregivers.expandedCaregiverLoading;
const pinnedCaregivers = ({ caregivers }) => caregivers.pinnedCaregivers;
const newCaregiverLoading = ({ caregivers }) => caregivers.newCaregiverLoading;

const totalPages = createSelector(
    totalCaregivers,
    pagination,
    (length, { itemsPerPage }) => (
        length > itemsPerPage
            ? Math.ceil(length / itemsPerPage)
            : 1
    ),
);

const currentPage = createSelector(pagination, ({ currentPage }) => currentPage);

export {
    caregivers,
    view,
    totalCaregivers,
    pagination,
    loadingCaregivers,
    expandedCaregiverProfile,
    expandedCaregiverLoading,
    pinnedCaregivers,
    newCaregiverLoading,

    totalPages,
    currentPage,
};
