import { useEffect, useRef } from 'react';
import { compose } from 'redux';

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';
import { searchBarFocus, setSearchBarFocus } from 'models/ui';
import { searchInputChange, searchInputValue } from 'models/app';
import { SearchKeyIcon } from 'assets';

import { useIntl } from 'react-intl';
import styles from './styles';

const Search = ({
    classes,
    searchBarFocus,
    setSearchBarFocus,
    inputProps,
    searchInputChange,
    searchInputValue,
}) => {
    const ref = useRef(null);
    useEffect(() => {
        if (searchBarFocus) {
            ref.current.focus();
        }
    }, [searchBarFocus]);

    const intl = useIntl();

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder={intl.formatMessage({ id: 'search_or_jump_to', defaultMessage: 'Search or jump to…' })}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search', ref }}
                type="search"
                onFocus={() => setSearchBarFocus(true)}
                onBlur={() => setSearchBarFocus(false)}
                value={searchInputValue}
                onChange={(event) => searchInputChange(event.target.value)}
                {...(!searchBarFocus && { endAdornment: <SearchKeyIcon /> })}
                {...inputProps}
            />
        </div>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        searchBarFocus,
        setSearchBarFocus,
        searchInputChange,
        searchInputValue,
    }),
)(Search);
