import { compose } from 'redux';
import {
    Paper,
    Typography,
    Toolbar,
    List,
    Divider,
} from '@mui/material';

import { withProps } from 'libraries/components';
import { searchResults } from 'models/app';
import { pinRelative } from 'models/relatives';
import { pinCaregiver } from 'models/caregivers';

import { SearchFilters, ResultBox, CaregiverListItem } from './components';
import withStyles from './styles';

const SearchResults = ({
    classes,
    searchResults,
    pinRelative,
    pinCaregiver,
}) => (
    <Paper className={classes.paper} elevation={0}>
        <div className={classes.content}>
            <Toolbar>
                <Typography variant="h4">
                    Search Results
                </Typography>
                <div className={classes.spacer} />
                <SearchFilters />
            </Toolbar>
            <ResultBox title="Caregivers" totalHits={searchResults?.caregivers?.nbHits}>
                <List className={classes.list}>
                    {searchResults
                        .caregivers
                        ?.hits
                        ?.map((caregiver) => (
                            <CaregiverListItem
                                caregiver={caregiver}
                                key={caregiver.id}
                                expandedPathPrefix="caregivers"
                                onBookmarkClick={pinCaregiver}
                            />
                        ))}
                </List>
            </ResultBox>
            <Divider />
            <ResultBox title="Relatives" totalHits={searchResults?.relatives?.nbHits}>
                <List className={classes.list}>
                    {searchResults
                        .relatives
                        ?.hits
                        ?.map((caregiver) => (
                            <CaregiverListItem
                                caregiver={caregiver}
                                key={caregiver.objectID}
                                expandedPathPrefix="relatives"
                                onBookmarkClick={pinRelative}
                            />
                        ))}
                </List>
            </ResultBox>
        </div>
    </Paper>
);

export default compose(
    withStyles,
    withProps({
        searchResults,
        pinRelative,
        pinCaregiver,
    }),
)(SearchResults);
