const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const getStyles = (name, personName) => ({
    fontWeight:
        personName?.indexOf(name) === -1
            ? 'regular'
            : 'medium',
});

export const handleChange = (onChange) => ({ target: { value } }) => onChange(
    typeof value === 'string' ? value.split(',') : value,
);
