import { NEW_RELATIVE } from 'constants/dialog';
import { useEffect } from 'react';

import { withProps } from 'libraries/components';
import { relatives, loadRelatives } from 'models/relatives';
import { openDialog } from 'models/ui';

import { useFab } from 'components';
import { FormattedMessage } from 'react-intl';
import { ListView } from './components';

const Relatives = ({
    relatives,
    loadRelatives,
    openDialog,
}) => {
    useEffect(() => {
        relatives.length === 0 && loadRelatives();
    }, []);

    useFab({
        fabProps: {
            onClick: () => openDialog({
                title: <FormattedMessage defaultMessage="Add relative" id="add_relative" />,
                type: NEW_RELATIVE,
            }),
        },
    });

    return (
        <>
            <ListView />
        </>
    );
};

export default withProps({
    relatives,
    loadRelatives,
    openDialog,
})(Relatives);
