import withStyles from '@mui/styles/withStyles';

const styles = withStyles(({ spacing }) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        height: '56px',
        flex: 1,
        borderBottom: '2px solid #EBEBEB',
        '& > div:last-child': {
            flex: 1,
        },
    },
    child: {
        flex: 5,
        padding: `0 ${spacing(1)}`,
    },
}));

export { styles as withStyles };
