import muiWithStyles from '@mui/styles/withStyles';

const withStyles = muiWithStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'stretch',
        flex: 1,
    },
    titleBox: {
        width: '26%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        borderRight: '1px solid #f0f0f0',
    },
    childrenContainer: {
        width: '74%',
        overflow: 'auto',
    },
}));

export default withStyles;
