import { combineEpics, ofType } from 'redux-observable';

import { from } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
} from 'rxjs/operators';

import { db, Collection$, Document$ } from 'services/firebaseDB';

import {
    loadUsers,
    loadPermissions,
    updateUser,
} from './actions';
import { sendNotification } from '../notifications';
import { authenticateUser } from '../user';

const onLoadEpic = (actions$) => actions$.pipe(
    ofType(authenticateUser.succeeded.type),
    mergeMap(() => [
        loadUsers(),
        loadPermissions(),
    ]),
);

const loadUsersEpic = (actions$) => actions$.pipe(
    ofType(loadUsers.type),
    mergeMap(() => Collection$('users')),
    map((users) => loadUsers.succeeded(users)),
);

const loadSettingsEpic = (actions$) => actions$.pipe(
    ofType(loadPermissions.type),
    mergeMap(() => Document$('settings', 'userPermissions')),
    map(({ id, ...permissions }) => loadPermissions.succeeded(permissions)),
);

const updateUserEpic = (actions$) => actions$.pipe(
    ofType(updateUser.type),
    map(({ payload }) => ({ ...payload, role: payload.role || '' })),
    mergeMap(({ id, ...update }) => db
        .collection('users')
        .doc(id)
        .set(update, { merge: true })),
    map(updateUser.succeeded),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        updateUser.failed(),
    ])),
);

export default combineEpics(
    onLoadEpic,
    loadUsersEpic,
    loadSettingsEpic,
    updateUserEpic,
);
