import { Navigate } from 'react-router-dom';

import { AccessDenied } from './components';

const PrivateRoute = ({
    isAuthenticated,
    component: Component,
    layout: Layout,
    permissions,
    userPermissions,
    isPrivate,
}) => (isPrivate && !isAuthenticated ? (
    <Navigate to="/sign-on" />
) : (
    <Layout>
        {permissions.every((perm) => userPermissions?.includes(perm))
            ? (
                <Component />
            ) : (
                <AccessDenied />
            )}
    </Layout>
));

export default PrivateRoute;
