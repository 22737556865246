import { matchPath, useLocation } from 'react-router-dom';

const withRouteFromPath = (routesList) => (Component) => (props) => {
    const location = useLocation();

    const route = routesList.find(({ path }) => matchPath(
        {
            path,
        },
        location.pathname,
    ));

    return <Component route={route} {...props} />;
};

export default withRouteFromPath;
