import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
} from '@mui/material';
import {
    Controller,
    useFormContext,
    useWatch,
} from 'react-hook-form';

const FormRadioButtons = ({
    control,
    label,
    name,
    getOptionLabel = (x) => x,
    options,
    radioGroupProps,
}) => {
    const { setValue } = useFormContext();

    const value = useWatch({
        control,
        name,
    });

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FormControl component="fieldset">
                    <FormLabel component="legend">{label}</FormLabel>
                    <RadioGroup
                        {...radioGroupProps}
                        value={value}
                        aria-label={label}
                        name={name}
                    >
                        {options.map((option) => (
                            <FormControlLabel
                                key={option}
                                value={option}
                                control={(
                                    <Radio
                                        onClick={({ target }) => {
                                            setValue(
                                                name,
                                                target.value === field.value
                                                    ? '-'
                                                    : target.value,
                                                {
                                                    shouldDirty: true,
                                                },
                                            );
                                        }}
                                    />
                                )}
                                label={getOptionLabel(option)}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            )}
        />
    );
};

export default FormRadioButtons;
