import {
    ADD_ICON,
    SAVE_ICON,
} from 'constants/fab';
import clsx from 'clsx';
import { compose } from 'redux';
import { Fab as MuiFab, Zoom } from '@mui/material';
import {
    Add as AddIcon,
    Save as SaveIcon,
} from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { withProps } from 'libraries/components';

import {
    showFab,
    raisedFab,
    fabProps,
    fabIcon,
} from 'models/ui';

import styles from './styles';

const Fab = ({
    classes,
    showFab,
    raisedFab,
    fabProps,
    fabIcon = ADD_ICON,
}) => {
    const icons = {
        [ADD_ICON]: <AddIcon />,
        [SAVE_ICON]: <SaveIcon />,
    };

    return (
        <Zoom in={showFab}>
            <MuiFab
                className={clsx(classes.fab, {
                    [classes.raisedFab]: raisedFab,
                })}
                color="primary"
                {...fabProps}
            >
                {icons[fabIcon]}
            </MuiFab>
        </Zoom>
    );
};
export default compose(
    withStyles(styles),
    withProps({
        showFab,
        raisedFab,
        fabProps,
        fabIcon,
    }),
)(Fab);
