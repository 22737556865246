import { compose } from 'redux';
import { Grid } from '@mui/material';
import { UserCard } from '@grandmama/ui-user-card';

import withStyles from '@mui/styles/withStyles';

import { withProps } from 'libraries/components';

import { caregivers } from 'models/caregivers';

import { CaregiverActions, CaregiverInfo, Pagination } from './components';
import styles from './styles';

const GridView = ({ classes, caregivers }) => (
    <>
        <Grid
            container
            spacing={6}
            direction="row"
            justifyContent="space-around"
            alignItems="stretch"
        >
            {caregivers.map((caregiver) => (
                <Grid
                    key={caregiver.id}
                    className={classes.gridItem}
                    item
                    md={4}
                >
                    <UserCard
                        name={`${caregiver.firstName} ${caregiver.lastName}`}
                        available={caregiver.available || undefined}
                        photo={caregiver.photo}
                        monogram={caregiver.firstName?.charAt(0)}
                        smallAvatar
                        paperProps={{
                            className: classes.userCard,
                        }}
                    >
                        <CaregiverInfo caregiver={caregiver} />
                        <CaregiverActions caregiver={caregiver} />
                    </UserCard>
                </Grid>
            ))}
        </Grid>
        <Pagination />
    </>
);

export default compose(
    withStyles(styles),
    withProps({ caregivers }),
)(GridView);
