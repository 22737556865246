import { useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import DashboardWidget from '../dashboardWidget';
import { widgets } from '../widgets';
import { layouts } from './layouts';

const defaultWidgets = [...widgets];

const ResponsiveGridLayout = WidthProvider(Responsive);

const Grid = () => {
    const [widgets] = useState(defaultWidgets);

    return (
        <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            isDraggable
            isRearrangeable
            isResizable
            draggableHandle=".grid-item__header"
            breakpoints={{
                lg: 1280,
                md: 992,
                sm: 767,
                xs: 480,
                xxs: 0,
            }}
            cols={{
                lg: 12,
                md: 10,
                sm: 6,
                xs: 4,
                xxs: 2,
            }}
        >
            {widgets.map((x) => (
                <DashboardWidget key={x.id} widget={x} data-grid={x.layout} />
            ))}
        </ResponsiveGridLayout>
    );
};

export default Grid;
