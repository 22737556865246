import { createReducer } from '@reduxjs/toolkit';
import { authenticateUser, getUserDetails } from './actions';

const initState = {
    isAuthenticated: false,
    signInChecked: false,
    info: {},
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(authenticateUser.succeeded, (state, { payload }) => {
        state.isAuthenticated = true;
        state.info = payload;
        state.signInChecked = true;
    })
    .addCase(authenticateUser.failed, (state) => {
        state.isAuthenticated = false;
        state.signInChecked = true;
        state.info = undefined;
    })
    .addCase(getUserDetails.succeeded, (state, { payload }) => {
        state.info = {
            ...state.info,
            ...payload,
        };
    }));

export default reducer;
