import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

const initState = {
    features: {},
    fetchState: {
        isLoading: false,
        isCreating: false,
        isUpdating: false,
        isDeleting: false,
    },
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.getFeatureFlags, (state) => {
        state.fetchState.isLoading = true;
    })
    .addCase(actions.getFeatureFlags.succeeded, (state, { payload }) => {
        state.features = payload;
        state.fetchState.isLoading = false;
    })
    .addCase(actions.updateFeatureFlag, (state) => {
        state.fetchState.isUpdating = true;
    })
    .addCase(actions.updateFeatureFlag.succeeded, (state, { payload }) => {
        state.fetchState.isUpdating = false;
        state.features[payload.id] = payload.data;
    })
    .addCase(actions.createFeatureFlag, (state) => {
        state.fetchState.isCreating = true;
    })
    .addCase(actions.createFeatureFlag.succeeded, (state, { payload }) => {
        state.fetchState.isCreating = false;
        state.features[payload.id] = payload.data;
    })
    .addCase(actions.deleteFeatureFlag, (state) => {
        state.fetchState.isDeleting = true;
    })
    .addCase(actions.deleteFeatureFlag.succeeded, (state, { payload }) => {
        state.fetchState.isDeleting = false;
        delete state.features[payload.id];
    })
    .addCase(actions.updateFetchState, (state, { payload }) => {
        state.fetchState[payload.type] = payload.value;
    }));

export default reducer;
