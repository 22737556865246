import { VIP_PLAN_ID } from 'constants/plans';
import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const NoPlan = ({ startPlan }) => (
    <>
        <Typography variant="h6" gutterBottom>
            <FormattedMessage id="no_plan_selected" defaultMessage="No plan selected" />
        </Typography>
        <Button variant="contained" color="primary" onClick={() => startPlan(VIP_PLAN_ID)}>
            <FormattedMessage id="start_vip_plan" defaultMessage="Start VIP plan" />
        </Button>
    </>
);

export default NoPlan;
