import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
    TextField,
    capitalize,
    Grid,
    InputAdornment,
} from '@mui/material';
import { differenceInYears, subYears } from 'date-fns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { FormRadioButtons, FormSelect } from 'components';

import { FormattedMessage } from 'react-intl';
import styles from './styles';

const PatientDetails = () => {
    const { control, setValue } = useFormContext();
    const dob = useWatch({ control, name: 'dateOfBirth' });

    return (
        <>
            <Controller
                freeSolo
                render={({ field }) => (
                    <FormSelect
                        {...field}
                        options={['father', 'mother', 'spouse', 'brother', 'sister', 'uncle', 'aunt', 'grandfather', 'grandmother', 'themselves', 'other']}
                        label={<FormattedMessage defaultMessage="Finding care for" id="finding_care_for" />}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={<FormattedMessage defaultMessage="Finding care for" id="finding_care_for" />}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                    />
                )}
                name="careFor"
                control={control}
            />
            <div style={styles.spacer} />
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={<FormattedMessage defaultMessage="Patient First Name" id="patient_first_name" />}
                                fullWidth
                                variant="outlined"
                            />
                        )}
                        name="patientFirstName"
                        control={control}
                    />
                </Grid>
                <Grid item md={6}>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={<FormattedMessage defaultMessage="Patient Last Name" id="patient_last_name" />}
                                fullWidth
                                variant="outlined"
                            />
                        )}
                        name="patientLastName"
                        control={control}
                    />
                </Grid>
                <Grid item md={12}>
                    <FormSelect
                        name="livesWith"
                        control={control}
                        label={<FormattedMessage defaultMessage="Lives With" id="lives_with" />}
                        options={['spouse', 'children', 'alone', 'other']}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={4}>
                    <FormRadioButtons
                        control={control}
                        name="sex"
                        label={<FormattedMessage defaultMessage="Sex" id="sex" />}
                        options={['male', 'female']}
                        getOptionLabel={(option) => (
                            <FormattedMessage
                                defaultMessage={capitalize(option)}
                                id={option}
                            />
                        )}
                        radioGroupProps={{
                            style: { flexDirection: 'row' },
                        }}
                    />
                </Grid>
                <Grid item md={4}>
                    <Controller
                        render={({ field: { onChange } }) => (
                            <DesktopDatePicker
                                label={<FormattedMessage defaultMessage="Date Of Birth" id="date_of_birth" />}
                                value={dob}
                                onChange={onChange}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                    />
                                )}
                                openTo="year"
                            />
                        )}
                        name="dateOfBirth"
                        control={control}
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField
                        label={<FormattedMessage defaultMessage="Age" id="age" />}
                        value={dob ? differenceInYears(new Date(), new Date(Number(dob))) : ''}
                        onChange={({ target }) => setValue(
                            'dateOfBirth',
                            target?.value > 0 ? subYears(new Date(), Number(target?.value)) : '',
                            { shouldValidate: false, shouldDirty: true },
                        )}
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {' '}
                                    <FormattedMessage id="years_old" defaultMessage="Years old" />
                                    {' '}
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default PatientDetails;
