import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';
import {
    loadRecent,
    setSearchResult,
    toggleSearchFilters,
} from './actions';

const initState = {
    searchInputValue: '',
    searchFilters: [],
    searchResults: [],
    searchLoading: false,
    recentlyViewed: {
        caregivers: [],
        relatives: [],
    },
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.searchInputChange, (state, { payload }) => {
        state.searchInputValue = payload;
        state.searchLoading = payload.length > 3;
    })
    .addCase(setSearchResult.succeeded, (state, { payload }) => {
        state.searchResults = payload;
        state.searchLoading = false;
    })
    .addCase(toggleSearchFilters, (state, { payload }) => {
        state.searchFilters = payload;
    })
    .addCase(loadRecent.succeeded, (state, { payload }) => {
        const { caregivers = [], relatives = [] } = payload;
        state.recentlyViewed.caregivers = caregivers;
        state.recentlyViewed.relatives = relatives;
    }));

export default reducer;
