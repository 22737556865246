import { STATUSES } from 'constants/statuses';
import { compose } from 'redux';
import {
    Paper,
    Grid,
    Typography,
    FormLabel,
    Chip,
    Tooltip,
    Box,
    Avatar,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    OutlinedInput, capitalize,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
    formatRelative,
} from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';

import { withProps } from 'libraries/components';
import { HasPayed, DaysInStatus } from 'components';
import { users } from 'models/settings';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

const CustomInput = (props) => <OutlinedInput {...props} />;

const StatusPane = ({ classes, users }) => {
    const { control, watch, setValue } = useFormContext();
    const [
        registerDate,
        plan,
        registeredBy,
        caseStatus,
        statusChangedOn,
    ] = watch([
        'registerDate',
        'plan',
        'registeredBy',
        'caseStatus',
        'statusChangedOn',
    ]);

    const renderValue = (id) => {
        const user = users?.find((user) => user.id === id);

        return (
            <span className={classes.inputSpan}>
                <Avatar
                    alt={user.displayName}
                    src={user.photoURL}
                    className={classes.avatar}
                    imgProps={{ referrerpolicy: 'no-referrer' }}
                />
                {user.displayName}
            </span>
        );
    };

    return (
        <Paper className={classes.section}>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <Box>
                        <FormLabel>
                            <FormattedMessage
                                defaultMessage="Registered"
                                id="registered"
                            />
                            <Typography variant="body1" component="span">
                                {` ${formatRelative(new Date(registerDate), new Date())} `}
                            </Typography>
                        </FormLabel>
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px">
                        {registeredBy && (
                            <Tooltip title={`Registered by ${registeredBy?.displayName}`}>
                                <Chip label={registeredBy?.displayName} color="primary" />
                            </Tooltip>
                        )}
                        <HasPayed plan={plan} />
                        <DaysInStatus statusChangedOn={statusChangedOn} status={caseStatus} />
                    </Box>
                </Grid>
                <Grid item md={4}>
                    <Controller
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel id="rep-label">
                                    <FormattedMessage
                                        defaultMessage="Rep"
                                        id="rep"
                                    />
                                </InputLabel>
                                <Select
                                    {...field}
                                    labelId="rep-label"
                                    fullWidth
                                    input={<CustomInput label="Rep" />}
                                    renderValue={renderValue}
                                >
                                    {users?.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            <Avatar
                                                alt={user.displayName}
                                                src={user.photoURL}
                                                className={classes.avatar}
                                                imgProps={{
                                                    referrerpolicy: 'no-referrer',
                                                }}
                                            />
                                            {user.displayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        name="caseRepresentativeId"
                        control={control}
                    />
                </Grid>
                <Grid item md={4}>
                    <Controller
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel id="status-label">
                                    <FormattedMessage defaultMessage="Status" id="status" />
                                </InputLabel>
                                <Select
                                    value={field.value}
                                    onChange={(newValue) => {
                                        field.onChange(newValue);
                                        setValue('statusChangedOn', new Date());
                                    }}
                                    getOptionLabel={(option) => (
                                        <FormattedMessage
                                            defaultMessage={capitalize(option)}
                                            id={option}
                                        />
                                    )}
                                    labelId="status-label"
                                    label="Status"
                                    fullWidth
                                >
                                    {Object.values(STATUSES).map((status) => (
                                        <MenuItem key={status.value} value={status.value}>
                                            <FormattedMessage defaultMessage="Status Label" id={status.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        name="caseStatus"
                        control={control}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default compose(
    withStyles(styles),
    withProps({ users }),
)(StatusPane);
