import { useState } from 'react';
import { compose } from 'redux';
import {
    Button,
    DialogActions,
    Tab,
    Tabs,
    LinearProgress,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SwipeableViews from 'react-swipeable-views';
import { withProps } from 'libraries/components';
import { newCaregiverLoading } from 'models/caregivers';
import { FormattedMessage } from 'react-intl';
import { SendLinkTab, CreateAccountTab } from './components';
import withForm from './withForm';
import styles from './styles';

const NewCaregiver = ({ classes, handleClose, newCaregiverLoading }) => {
    const [value, setValue] = useState(0);

    return (
        <>
            {newCaregiverLoading && <LinearProgress color="primary" />}
            <Tabs
                value={value}
                onChange={(_, newValue) => setValue(newValue)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label={<FormattedMessage defaultMessage="Send Link" id="send_link" />} />
                <Tab label={<FormattedMessage defaultMessage="Create Account" id="create_account" />} />
            </Tabs>
            <SwipeableViews index={value} disabled>
                <SendLinkTab isVisible={value === 0} />
                <CreateAccountTab isVisible={value === 1} />
            </SwipeableViews>
            <DialogActions>
                <Button
                    className={classes.cancelButton}
                    onClick={handleClose}
                    disabled={newCaregiverLoading}
                >
                    <FormattedMessage
                        defaultMessage="CANCEL"
                        id="cancel"
                    />
                </Button>
                <Button
                    color="primary"
                    type="submit"
                    disabled={newCaregiverLoading}
                >
                    {value === 0
                        ? (
                            <FormattedMessage
                                defaultMessage="SEND"
                                id="send"
                            />
                        )
                        : (
                            <FormattedMessage
                                defaultMessage="CREATE"
                                id="create"
                            />
                        )}
                </Button>
            </DialogActions>
        </>
    );
};

export default compose(
    withForm,
    withStyles(styles),
    withProps({ newCaregiverLoading }),
)(NewCaregiver);
