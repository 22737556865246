const styles = (theme) => ({
    list: {
        width: 180,
    },
    listExpanded: {
        width: 480,
    },
    fullList: {
        width: 'auto',
    },
    avatar: {
        width: 100,
        height: 100,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 10,
        fontSize: '3em',
        backgroundColor: theme.palette.primary.light,
    },
    name: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        textAlign: 'center',
    },
    itemIcon: {
        minWidth: 30,
    },
    itemIconRed: {
        minWidth: 30,
        color: theme.palette.error.main,
    },
    buildNum: {
        position: 'absolute',
        bottom: 2,
        right: '40%',
        color: theme.palette.text.hint,
    },
});

export default styles;
