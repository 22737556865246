import { Avatar } from '@grandmama/ui-avatar';
import { styled } from '@mui/material/styles';

const StyledAvatar = styled(
    (props) => <Avatar avatarProps={props} {...props} />,
)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

const avatarRenderer = ({ value, row }) => (
    <StyledAvatar
        name={`${row.firstName} ${row.lastName}`}
        photo={value}
    />
);

export default avatarRenderer;
