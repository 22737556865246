export const NEW_CASE = 'NEW_CASE';
export const MATCHING = 'MATCHING';
export const MATCHED = 'MATCHED';
export const CLOSED = 'CLOSED';
export const CANCELLED = 'CANCELLED';

export const STATUSES = {
    [NEW_CASE]: {
        value: NEW_CASE,
        label: 'new_case',
        color: 'secondary',
    },
    [MATCHING]: {
        value: MATCHING,
        label: 'matching',
        color: 'warning',
    },
    [MATCHED]: {
        value: MATCHED,
        label: 'matched',
        color: 'info',
    },
    [CLOSED]: {
        value: CLOSED,
        label: 'closed',
        closed: true,
        color: 'success',
    },
    [CANCELLED]: {
        value: CANCELLED,
        label: 'cancelled',
        closed: true,
        color: 'error',
    },
};
