import { Observable } from 'rxjs';

const collectionStream = (db) => (collectionPath) => new Observable((observer) => db
    .collection(collectionPath)
    .onSnapshot(
        (querySnapshot) => {
            observer.next(querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            })));
        },
        (error) => observer.error(error),
        () => observer.complete(),
    ));

export default collectionStream;
