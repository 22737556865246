const drawerWidth = 240;

const styles = {
    container: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`,
    },
    drawer: {
        backgroundColor: 'transparent',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
        },
    },
    content: {
        flexGrow: 1,
        p: 3,
    },
};

export default styles;
